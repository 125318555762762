import React from 'react';

const LinkColumn =
    (link?: string, param?: string): any =>
    (params: any) => {
        let href = params.value;
        if (link) {
            href = link;
            if (param) {
                const splits = param.split('.');
                if (splits.length === 1) {
                    href = link + params.row.original[param];
                } else if (splits.length === 2 && params.row.original[splits[0]]) {
                    href = link + params.row.original[splits[0]][splits[1]];
                }
            }
        }
        return (
            <>
                <a href={href} target="_blank" onClick={(e) => e.stopPropagation()}>
                    {href === params.value ? 'Click here' : params.value}
                </a>
            </>
        );
    };

export default LinkColumn;
