import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { UserProfileContext } from '../../../context/userProfileContext';
import ClientInfo from '../clientInfo/ClientInfo';
import ModuleTabs from '../moduleTabs/ModuleTabs';
import { APICore } from '../../../utils/api/apiCore';
import TimeLine from '../timeLine/TimeLine';
import MainData from './MainData';
import { createPension } from '../../../utils/api/pension';
import { useTranslation } from 'react-i18next';

const Pension = () => {
    const api = new APICore();
    // useEffect(() => {
    //     setSelectedTab(profileContextData.selectedModule?.data?.moduleName);
    // }, [profileContextData.selectedModule]);

    const { t } = useTranslation('components');
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;

    const createInvestmentRound = async () => {
        if (!window.confirm(t('Are you sure you want to create an investment round/ without Mislaka?'))) {
            return;
        }

        try {
            await createPension(client._id, true);
            window.location.reload();
        } catch (error) {
            console.error(error);
            alert('Error creating pension');
        }
    };

    const generateSecondButton = () => {
        return (
            <Button onClick={createInvestmentRound} className="create-round btn-primary">
                Create Investment/No Mislaka
            </Button>
        );
    };

    return (
        <div className={`regular-modules ${profileContextData.expandClientData ? 'data-expanded' : ''}`}>
            <ClientInfo />
            <ModuleTabs />
            <div className={'timeline-row-wrapper not-active-module'}>
                <TimeLine SecondButton={generateSecondButton()} />
            </div>
            {profileContextData.currentRound ? <MainData /> : null}
        </div>
    );
};

export default Pension;
