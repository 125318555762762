import { Form } from 'react-bootstrap';
import BorderWithText from '../../../../../components/BorderWithText';
import { Spouse } from '../../../../../interfaces/TaxData';
import { parseDateValue } from '../../../../../utils/parseDateValue';

interface NationalServiceBoxProps {
    t: (s: string) => string;
    prefix: string;
    data: Spouse;
    onChange: (e: any) => void;
}

const SpouseDetailsBox = ({ t, prefix, data, onChange }: NationalServiceBoxProps) => {
    return (
        <BorderWithText label={t('Personal Details')}>
            <Form.Group controlId={`${prefix}SpouseDetails`} className="d-flex flex-column gap-3">
                <div className="d-flex gap-3">
                    <div>
                        <Form.Label>{t('First Name')}</Form.Label>
                        <Form.Control type="text" name={`${prefix}.firstName`} value={data.firstName} onChange={onChange} />
                    </div>
                    <div>
                        <Form.Label>{t('Last Name')}</Form.Label>
                        <Form.Control type="text" name={`${prefix}.lastName`} value={data.lastName} onChange={onChange} />
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div>
                        <Form.Label>{t('Date of Birth')}</Form.Label>
                        <Form.Control type="date" name={`${prefix}.dateOfBirth`} value={parseDateValue(data.dateOfBirth)} onChange={onChange} />
                    </div>
                    <div>
                        <Form.Label>{t('ID Number')}</Form.Label>
                        <Form.Control type="text" name={`${prefix}.idNumber`} value={data.idNumber} onChange={onChange} />
                    </div>
                </div>
            </Form.Group>
        </BorderWithText>
    );
};

export default SpouseDetailsBox;
