import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { withSwal } from 'react-sweetalert2';
// components
import { SignedDocument } from '../interfaces/SignedDocument';
import { sendFileToClient } from '../utils/api/files';
import { useTranslation } from 'react-i18next';
import globalErrorHandler from '../utils/globalErrorHandler';

// main component
const GenerateForm = withSwal((props: any) => {
    const { t } = useTranslation('components');
    const { signedDocument, productTransfer, onSent } = props;

    const [newSignedDocument, setNewSignedDocument] = useState<SignedDocument>(signedDocument);

    useEffect(() => {
        setNewSignedDocument({ ...signedDocument });
    }, [signedDocument]);

    const getDownloadLink = (signedDocument: SignedDocument) => {
        if (!signedDocument) return;

        if (productTransfer.file) {
            return {
                link: productTransfer.file.link,
                text: t('Download'),
            };
        }

        if (signedDocument.file) return { link: signedDocument.file.link, text: t('Download') };

        if (signedDocument.agentSigningLink) return { link: signedDocument.agentSigningLink, text: t('Agent link') };

        if (signedDocument.clientSigningLink) return { link: signedDocument.clientSigningLink, text: t('Client link') };
    };

    const [linkIsLoading, setLinkIsLoading] = useState<boolean>(false);

    const sendToClient = async () => {
        if (!newSignedDocument) return;

        if (!window.confirm(t('Email this document to the client. Are you sure?'))) return;

        try {
            await sendFileToClient(newSignedDocument.file._id);
            newSignedDocument.file.sentToClientDate = new Date().toDateString();
            onSent(newSignedDocument.file);
            setNewSignedDocument(newSignedDocument);
            alert(t('Sent to client'));
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    return (
        <>
            {getDownloadLink(newSignedDocument) && !linkIsLoading ? (
                <div className="link">
                    <Link to={`${getDownloadLink(newSignedDocument)?.link}`} target="_blank" className="text-body fw-semibold">
                        {getDownloadLink(newSignedDocument)?.text}
                    </Link>
                </div>
            ) : (
                ''
            )}

            {newSignedDocument?.file ? (
                <>
                    {newSignedDocument?.file.sentToClientDate ? (
                        <span>
                            <i className="mdi mdi-check"></i> Sent
                        </span>
                    ) : (
                        <a href="#" className="grouped-btn second-btn" onClick={sendToClient}>
                            <i className="mdi mdi-email"></i> {t('Send to client')}
                        </a>
                    )}
                </>
            ) : null}
        </>
    );
});

export default GenerateForm;
