import React, { useState } from 'react';
import './tax-return-complete-form.scss';
import _ from 'lodash';
import TabsContainer from './tabs/TabsContainer';
import { Form } from 'react-bootstrap';
import GeneralDataSection from './general-data';
import PerYearDataSection from './per-year-data';
import LoadingButton from '../../../../components/LoadingButton';
import useFetchTaxData from './hooks/useFetchTaxData';
import { TaxReturn } from '../../../../interfaces/taxReturn';
import useTabs from './hooks/useTabs';
import { createFilesCalculation, createTaxData, editTaxData } from '../../../../utils/api/taxReturn';
import { useTranslation } from 'react-i18next';
import useErrorHandler from './hooks/useErrorHandler';
import globalErrorHandler from '../../../../utils/globalErrorHandler';

interface TaxReturnCompleteFormProps {
    taxReturn: TaxReturn;
}

const TaxReturnCompleteForm = ({ taxReturn }: TaxReturnCompleteFormProps) => {
    const { t } = useTranslation('taxReturn');
    const { taxData, setTaxData } = useFetchTaxData(taxReturn);
    const { tabs, activeTab, indexOfPerYearData, isGeneralTab, handleTabClick, currentYear } = useTabs();
    const [isSaving, setIsSaving] = useState(false);
    const [isCollecting, setIsCollecting] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const { handleErrorCases } = useErrorHandler();

    const onInputChange = (e: any) => {
        if (!taxData) return;
        const { name, value } = e.target;
        const updatedTaxData = _.cloneDeep(taxData);
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (dateRegex.test(value)) {
            _.set(updatedTaxData, name, new Date(value));
        } else {
            _.set(updatedTaxData, name, value);
        }
        setTaxData(updatedTaxData);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            const response = await editTaxData(taxData._id, taxData);
            console.log('hmmm');
            debugger;

            setTaxData(response.taxData);
        } catch (error: any) {
            globalErrorHandler(error);
        } finally {
            setIsSaving(false);
        }
    };

    const SubmitButton = () => (
        <LoadingButton
            variant="success"
            className="submit-button my-3 right"
            type="submit"
            loading={isSaving}
            onClick={handleSubmit}
            loadingText={t('Saving...')}
            loadedText={t('Save')}
        />
    );

    const handleCollectData = async () => {
        try {
            setIsCollecting(true);
            const response = await createTaxData(taxReturn._id);
            setTaxData(response.taxData);

            alert(t('Data collected successfully'));
        } catch (error: any) {
            handleErrorCases(error);
        } finally {
            setIsCollecting(false);
        }
    };

    const handleSendFilesForCalculation = async () => {
        try {
            setIsSending(true);
            await createFilesCalculation(taxData._id);
            alert(t('Files sent successfully'));
        } catch (error: any) {
            handleErrorCases(error);
        } finally {
            setIsSending(false);
        }
    };

    const CollectDataButton = () => (
        <LoadingButton
            className="submit-button my-3 right btn btn-primary active"
            loading={isCollecting}
            onClick={handleCollectData}
            loadingText={t('Collecting...')}
            loadedText={t('Collect Data')}
        />
    );

    const SendFilesForCalculationButton = () => (
        <LoadingButton
            className="submit-button my-3 right btn btn-primary active"
            loading={isSending}
            onClick={handleSendFilesForCalculation}
            loadingText={t('Sending...')}
            loadedText={t('Send Files For Calculation')}
        />
    );

    return (
        <div className="tax-return-complete-form" dir="rtl">
            {taxData && <TabsContainer tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />}
            {taxData && (
                <Form onSubmit={handleSubmit} className="form-container">
                    {isGeneralTab ? (
                        <GeneralDataSection taxData={taxData} onChange={onInputChange} />
                    ) : (
                        <PerYearDataSection taxData={taxData} currentYear={currentYear} onChange={onInputChange} />
                    )}
                </Form>
            )}
            <div className="buttons">
                <div className="d-flex gap-2">
                    <CollectDataButton />
                    {taxData && <SendFilesForCalculationButton />}
                </div>
                {taxData && <SubmitButton />}
            </div>
        </div>
    );
};

export default TaxReturnCompleteForm;
