const formatDate = (d?: Date, dateOnly?: boolean | undefined, hourOnly?: boolean | undefined, country?: string | undefined) => {
    if (!d) return;

    d = new Date(d);
    let options = {};
    if (dateOnly) options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    else if (hourOnly) options = { hour: 'numeric', minute: 'numeric' };
    else
        options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        };

    let str;
    if (country) {
        str = d.toLocaleDateString(country, options);
    } else {
        str = d.toLocaleDateString('en-GB', options);
    }
    if (hourOnly) return str.split(',')[1];
    else return str;
};

const formatDateByTimePassed = (time: Date) => {
    const then = new Date(time);
    const now = new Date();

    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

    if (hoursBetweenDates < 24) {
        return then.toLocaleTimeString('he-IL', {
            hour: '2-digit',
            minute: '2-digit',
        });
    } else if (hoursBetweenDates < 24 * 7) {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[then.getDay()];
    } else {
        return then.toLocaleDateString();
    }
};

export { formatDate, formatDateByTimePassed };
