import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { setUserAsLoggedIn } from '../redux/actions';
import { APICore } from '../utils/api/apiCore';
import TwoColumnLayout from '../layouts/TwoColumn';
import { LayoutTypes } from '../constants';
import HorizontalLayout from '../layouts/Horizontal';
import DetachedLayout from '../layouts/Detached';
import VerticalLayout from '../layouts/Vertical';

interface AppContextProps {
    layout: any;
    isUserLoggedIn: boolean;
    getLayout: () => any;
}

export const AppContext = createContext<AppContextProps>({
    layout: { layoutType: '' },
    isUserLoggedIn: false,
    getLayout: () => {},
});

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
    const dispatch = useDispatch<AppDispatch>();
    const { layout, auth } = useSelector((state: RootState) => ({
        layout: state.Layout,
        auth: state.Auth,
    }));
    const isUserLoggedIn = auth.userLoggedIn;

    useEffect(() => {
        const { user } = new APICore().getLoggedInUser();
        if (user) {
            dispatch(setUserAsLoggedIn());
        }
    }, []);

    const getLayout = () => {
        let layoutCls = TwoColumnLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_VERTICAL:
                layoutCls = VerticalLayout;
                break;
            default:
                layoutCls = TwoColumnLayout;
                break;
        }
        return layoutCls;
    };

    return <AppContext.Provider value={{ layout, isUserLoggedIn, getLayout }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
    const context = React.useContext(AppContext);
    if (!context) {
        throw new Error('useAppContext must be used within a AppProvider');
    }
    return context;
};
