import { Form } from 'react-bootstrap';
import { InsuranceAppendix, policyBranchToAppendixBranchMap, appendixBranchToAppendixBranchSection } from '../../../../interfaces/InsuranceAppendix';
import { useEffect, useState } from 'react';
import { useProductsContext } from '../insuranceProductsContext';
import { InsurancePolicy } from '../../../../interfaces/InsurancePolicy';
import { useTranslation } from 'react-i18next';
import { camelCaseToText } from '../../../../utils/camelCaseToText';
import { getClientFamilyMembers } from '../../../../utils/getClientFamilyMembers';

interface AppendixInputFieldsProps {
    isPolicyCreationPhase: boolean;
    newInsuranceAppendix: InsuranceAppendix;
    setNewInsuranceAppendix: (newInsuranceAppendix: InsuranceAppendix) => void;
    associatedPolicyState?: InsurancePolicy;
}
const AppendixInputFields = ({
    isPolicyCreationPhase,
    newInsuranceAppendix,
    setNewInsuranceAppendix,
    associatedPolicyState,
}: AppendixInputFieldsProps) => {
    const { insurancePolicies, client } = useProductsContext();
    const familyMembers = getClientFamilyMembers(client, true);
    const { t } = useTranslation('insurance');
    associatedPolicyState = associatedPolicyState || insurancePolicies.find((policy) => policy._id === newInsuranceAppendix.policyId);

    const [relevantBranchesBasedOnPolicy, setRelevantBranchesBasedOnPolicy] = useState(policyBranchToAppendixBranchMap(associatedPolicyState));
    const [relevantBranchSections, setRelevantBranchSections] = useState(appendixBranchToAppendixBranchSection(newInsuranceAppendix));

    const handleAppendixInputChange = (e: any) => {
        const name = e.target.name;
        let value = e.target.value;
        setNewInsuranceAppendix({
            ...newInsuranceAppendix,
            [name]: value,
        });
    };

    const handleInsuredPersonChange = (e: any) => {
        const insuredPerson = familyMembers.find((member) => member.idNumber === e.target.value);
        if (insuredPerson) {
            setNewInsuranceAppendix({
                ...newInsuranceAppendix,
                insuredPerson: {
                    name: insuredPerson.firstName + ' ' + insuredPerson.lastName,
                    idNumber: insuredPerson.idNumber || '',
                },
            });
        }
    };

    useEffect(() => {
        // set clientId for request
        setNewInsuranceAppendix({ ...newInsuranceAppendix, clientId: client._id });
    }, [client]);

    useEffect(() => {
        // each time the main branch changes - reselect the secondary branch
        setRelevantBranchesBasedOnPolicy(policyBranchToAppendixBranchMap(associatedPolicyState));
    }, [associatedPolicyState]);

    useEffect(() => {
        // each time the secondary branch changes - reselect the branch section
        setRelevantBranchSections(appendixBranchToAppendixBranchSection(newInsuranceAppendix));
    }, [newInsuranceAppendix]);

    useEffect(() => {
        // each time the main branch changes - resset the branch section
        setRelevantBranchSections(null);
    }, [associatedPolicyState]);

    useEffect(() => {
        // when there is only one option on the select, pick it automatically
        if (relevantBranchSections && Object.keys(relevantBranchSections).length === 1) {
            setNewInsuranceAppendix({
                ...newInsuranceAppendix,
                branchSection: Object.keys(relevantBranchSections)[0],
            });
        }
    }, [relevantBranchSections]);

    return (
        <>
            {!isPolicyCreationPhase && (
                <Form.Group className="mb-3" controlId="policy">
                    <Form.Label>Policy Number</Form.Label>
                    <Form.Select name="policyId" value={newInsuranceAppendix.policyId || ''} onChange={handleAppendixInputChange} disabled>
                        <option value="" disabled>
                            Select a Policy
                        </option>
                        {insurancePolicies.map((policy) => (
                            <option key={policy._id} value={policy._id}>
                                {`${policy.policyNumber} - ${policy.company}`}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="branch">
                <Form.Label>Branch</Form.Label>
                {relevantBranchesBasedOnPolicy ? (
                    <Form.Select name="branch" value={newInsuranceAppendix.branch} onChange={handleAppendixInputChange} required>
                        <option value="" disabled>
                            Select a Branch
                        </option>
                        {Object.keys(relevantBranchesBasedOnPolicy).map((branch) => (
                            <option key={branch} value={branch}>
                                {t(camelCaseToText(branch))}
                            </option>
                        ))}
                    </Form.Select>
                ) : (
                    <div>{t('No available branches found for this policy')}</div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="branchSection">
                <Form.Label>Branch Section</Form.Label>
                {relevantBranchSections ? (
                    <Form.Select name="branchSection" value={newInsuranceAppendix.branchSection ?? ''} onChange={handleAppendixInputChange}>
                        <option value="">No Branch Section</option>
                        {Object.keys(relevantBranchSections).map((branchSection) => (
                            <option key={branchSection} value={branchSection}>
                                {t(camelCaseToText(branchSection))}
                            </option>
                        ))}
                    </Form.Select>
                ) : (
                    <div>{t('No available branch sections found for this policy')}</div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="premium">
                <Form.Label>Premium</Form.Label>
                <Form.Control name="premium" type="number" value={newInsuranceAppendix.premium || ''} onChange={handleAppendixInputChange} required />
            </Form.Group>

            <Form.Group className="mb-3" controlId="insuredPerson">
                <Form.Label>Insured Person</Form.Label>
                <Form.Select name="insuredPerson" value={newInsuranceAppendix.insuredPerson?.idNumber || ''} onChange={handleInsuredPersonChange}>
                    <option value="" disabled>
                        Select an Insured Person
                    </option>
                    {familyMembers.map((member) => (
                        <option key={member.idNumber} value={member.idNumber}>
                            {`${member.firstName} ${member.lastName}`}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
        </>
    );
};
export default AppendixInputFields;
