import { useState, useEffect, useRef, useContext } from 'react';
import { PensionProduct } from '../interfaces/PensionProduct';
import { Client } from '../interfaces/Client';
import { UserProfileContext } from '../context/userProfileContext';
import Modules from '../utils/modules';
import { addComment, deleteComment, getComments } from '../utils/api/comments';
import { Comment } from '../interfaces/Comment';
import { Task } from '../interfaces/Task';
import globalErrorHandler from '../utils/globalErrorHandler';

interface useCommentsProps {
    product?: PensionProduct;
    client?: Client;
    task?: Task;
}
const useComments = ({ product, client, task }: useCommentsProps) => {
    const [newComment, setNewComment] = useState<string>('');
    const [comments, setComments] = useState<Comment[]>([]);
    const [isCopied, setIsCopied] = useState(false);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const topics = Object.values(Modules);
    const [moduleFilter, setModuleFilter] = useState<string>('');
    const [sortBy, setSortBy] = useState<string>(getSortByFromLocalStorage() || 'latest');

    const { profileContextData } = useContext(UserProfileContext);
    const [topic, setTopic] = useState<string>(profileContextData?.selectedModule?.data.moduleName);

    const commentTextChange = (text: string) => {
        setNewComment(text);
        setClientCommentText(text);
    };
    const addCommentClicked = async () => {
        if (!topic) {
            return alert('Please select a topic');
        }

        if (newComment) {
            try {
                const result = await addComment({
                    productId: product?._id,
                    clientId: client?._id,
                    taskId: task?._id,
                    text: newComment,
                    topic,
                });
                comments.push(result.comment);
                setComments(comments);
                commentTextChange('');

                // Reload the comments after adding a new one.
                handleComments();
            } catch (err) {
                globalErrorHandler(err);
            }
        }
    };

    const setClientCommentText = (text: string) => {
        if (client) {
            localStorage.setItem(`commentText_${client._id}`, text);
        }
    };

    const getClientCommentText = (): string => {
        if (client) {
            return localStorage.getItem(`commentText_${client._id}`) || '';
        }
        return '';
    };

    const onDeleteComment = async (commentId: string) => {
        if (!window.confirm(`Are you sure you want to delete this comment?`)) {
            return;
        }

        try {
            await deleteComment(commentId);
            setComments(comments.filter((comment) => comment._id !== commentId));
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const handleComments = async () => {
        const param = {
            productId: product?._id,
            clientId: client?._id,
            taskId: task?._id,
        };
        if (!param.productId && !param.clientId && !param.taskId) {
            return;
        }
        try {
            const result = await getComments(param);

            let filteredComments = result.comments;

            if (moduleFilter) {
                filteredComments = filteredComments.filter((comment: { topic: string }) => comment.topic === moduleFilter);
            }

            if (sortBy === 'latest') {
                filteredComments.sort(
                    (a: { createdAt: string | number | Date }, b: { createdAt: string | number | Date }) =>
                        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
                );
            } else {
                filteredComments.sort(
                    (a: { createdAt: string | number | Date }, b: { createdAt: string | number | Date }) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
                );
            }

            setComments(filteredComments);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const handleModuleFilterChange = (selectedModule: string) => {
        setModuleFilter(selectedModule);
    };

    const handleSortByChange = (selectedSort: string) => {
        setSortBy(selectedSort);
        localStorage.setItem('selectedSortBy', selectedSort);
    };

    useEffect(() => {
        handleComments();
        if (client) {
            const text = getClientCommentText();
            setNewComment(text);
        }
    }, [client, task, product, sortBy, moduleFilter]);

    const onCopyComment = (comment: Comment) => {
        navigator.clipboard.writeText(comment.text);
        setIsCopied(!isCopied);
    };

    const getBadgeClass = (comment: Comment) => {
        if (comment.topic === Modules.onboarding) return 'bg-info';
        else if (comment.topic === Modules.pension) return 'bg-warning';
        else if (comment.topic === Modules.taxReturn) return 'bg-primary';
        else if (comment.topic === Modules.deposit) return 'bg-dark';
        else if (comment.topic === Modules.loans) return 'bg-warning';
        else if (comment.topic === Modules.insurance) return 'bg-success';
        else if (comment.topic === Modules.mortgage) return 'bg-info';
        else if (comment.topic === Modules.bankFees) return 'bg-danger';
        else return 'bg-secondary';
    };

    const onSelected = async (e: any) => {
        setTopic(e.target.value);
    };

    return {
        comments,
        newComment,
        isCopied,
        textAreaRef,
        topics,
        moduleFilter,
        sortBy,
        topic,
        commentTextChange,
        addCommentClicked,
        onDeleteComment,
        handleModuleFilterChange,
        handleSortByChange,
        onCopyComment,
        getBadgeClass,
        onSelected,
    };
};

export default useComments;

const getSortByFromLocalStorage = () => {
    return localStorage.getItem('selectedSortBy') || 'latest';
};
