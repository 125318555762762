import React, { useEffect, useState } from 'react';
import { PersonTaxData } from '../../../../../interfaces/TaxData';
import { useTranslation } from 'react-i18next';
import NationalServiceBox from './NationalServiceBox';
import { Option } from '../../../../../interfaces/Option';
import { isThereDataToPresent } from '../utils';
import ImmigrantStatusBox from './ImmigrantStatusBox';
import DegreeCertificate from './DegreeCertificateBox';
import { useEnumsContext } from '../../../../../context/EnumsContext';

interface GeneralDataFormProps {
    data: PersonTaxData;
    onChange: (e: any) => void;
    prefix: string;
}

const GeneralDataForm = ({ data, onChange, prefix }: GeneralDataFormProps) => {
    const { t } = useTranslation('taxReturn');
    const [serviceTypesOptions, setServiceTypesOptions] = useState<Option>({
        label: '',
        value: '',
    });
    const [immigrationTypesOptions, setImmigrationTypesOptions] = useState<Option>({ label: '', value: '' });
    const [certificateTypesOptions, setCertificateTypesOptions] = useState<Option>({ label: '', value: '' });

    const enums = useEnumsContext();
    useEffect(() => {
        if (enums && enums.ServiceTypes && enums.ImmigrationTypes && enums.CertificateTypes) {
            setServiceTypesOptions(enums.ServiceTypes);
            setImmigrationTypesOptions(enums.ImmigrationTypes);
            setCertificateTypesOptions(enums.CertificateTypes);
        }
    }, [enums]);

    const commonBoxProps = {
        t,
        prefix,
        data,
        onChange,
    };

    const isAnyDataPresent =
        isThereDataToPresent(data.nationalService) || isThereDataToPresent(data.newImmigrant) || isThereDataToPresent(data.degreeCertificate);

    return (
        <div className="data-form">
            {isThereDataToPresent(data.nationalService) && <NationalServiceBox {...commonBoxProps} serviceTypesOptions={serviceTypesOptions} />}
            {isThereDataToPresent(data.newImmigrant) && <ImmigrantStatusBox {...commonBoxProps} immigrationTypesOptions={immigrationTypesOptions} />}
            {isThereDataToPresent({ files: data.degreeCertificate?.files }) && (
                <DegreeCertificate {...commonBoxProps} certificateTypesOptions={certificateTypesOptions} />
            )}
            {!isAnyDataPresent && <p className="text-center">{t('noDataAvailable')}</p>}
        </div>
    );
};

export default GeneralDataForm;
