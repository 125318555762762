import { Deal } from '../../interfaces/Employer';
import { APICore, API_URL } from './apiCore';

const api = new APICore();
// const API_URL = 'http://localhost:5000/api'

const getEmployerDeals = (params: { employerId: string }) => {
    const relativeUrl = '/employer-deals/';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const addEmployerDeal = (params: Deal) => {
    const relativeUrl = '/employer-deals/';
    return api.create(`${API_URL}${relativeUrl}`, params);
};

const editEmployerDeal = (dealId: string, params: Deal) => {
    const relativeUrl = '/employer-deals/' + dealId;
    return api.update(`${API_URL}${relativeUrl}`, params);
};

const deleteEmployerDeal = (dealId: string) => {
    const relativeUrl = '/employer-deals/' + dealId;
    return api.delete(`${API_URL}${relativeUrl}`);
};

export { getEmployerDeals, addEmployerDeal, editEmployerDeal, deleteEmployerDeal };
