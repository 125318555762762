import { useState } from 'react';
import { Client, ClientsChild, ClientsSpouse } from '../../interfaces/Client';
import { Button } from 'react-bootstrap';
import FamilyMembersInputFields from './FamilyMembersInputFields';
import { editClient } from '../../utils/api/clients';
import globalErrorHandler from '../../utils/globalErrorHandler';

interface Props {
    client: Client;
    setClient: (client: Client) => void;
}

const EditClientFamilyMemebrsButton = ({ client, setClient }: Props) => {
    const [children, setChildren] = useState<ClientsChild[]>((client.children ?? []).map((child) => ({ ...child, type: 'child' })));
    const [spouse, setSpouse] = useState<ClientsSpouse>({
        ...client.spouse,
        type: 'spouse',
    });

    const [showEditFamilyMembersModal, setShowEditFamilyMembersModal] = useState(false);

    const updateClientsFamilyMemebrs = async (editedChildren: ClientsChild[], editedSpouse: ClientsSpouse) => {
        setChildren(editedChildren);
        setSpouse(editedSpouse);

        try {
            if (editedSpouse.type === undefined) {
                const clientWithoutSpouse = { ...client };
                delete clientWithoutSpouse.spouse;
                debugger;
                await editClient(client._id, clientWithoutSpouse);
            } else {
                await editClient(client._id, {
                    children: editedChildren,
                    spouse: editedSpouse,
                });
            }
            const updatedClient = {
                ...client,
                children: editedChildren,
                spouse: editedSpouse,
            };
            setClient(updatedClient);
            setShowEditFamilyMembersModal(false);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    return (
        <>
            <Button className="left" variant="primary" onClick={() => setShowEditFamilyMembersModal(true)}>
                Family Members
            </Button>
            <FamilyMembersInputFields
                show={showEditFamilyMembersModal}
                onHide={() => setShowEditFamilyMembersModal(false)}
                children={children}
                spouse={spouse}
                onSubmit={updateClientsFamilyMemebrs}
            />
        </>
    );
};

export default EditClientFamilyMemebrsButton;
