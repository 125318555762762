import { Form } from 'react-bootstrap';
import { InsurancePolicy, InsurancePolicyMainBranch, InsurancePolicyPlanTypes } from '../../../../interfaces/InsurancePolicy';
import { setValueByField } from '../../../../utils/nestedFieldsLogic';
import { useTranslation } from 'react-i18next';
import { camelCaseToText } from '../../../../utils/camelCaseToText';
import { useProductsContext } from '../insuranceProductsContext';
import { FamilyMember } from '../../../../interfaces/Client';
import { useEffect } from 'react';
import Select from 'react-select';

interface PolicyInputFieldsProps {
    newInsurancePolicy: InsurancePolicy;
    setNewInsurancePolicy: (newInsurancePolicy: InsurancePolicy) => void;
    companies: string[];
}

const PolicyInputFields = ({ newInsurancePolicy, setNewInsurancePolicy, companies }: PolicyInputFieldsProps) => {
    const { t } = useTranslation('insurance');
    const { consts } = useProductsContext();
    const familyMembers = consts.familyMembers;

    const handlePolicyInputChange = (e: any) => {
        const name = e.target.name;
        const value = name.toLowerCase().includes('date') ? new Date(e.target.value) : e.target.value;
        const updatedPolicy = setValueByField({ ...newInsurancePolicy }, name, value);
        setNewInsurancePolicy(updatedPolicy);
    };

    const handleSelectPolicyHolder = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIdNumber = event.target.value;
        let policyHolder = { name: '', idNumber: '' };
        if (selectedIdNumber !== 'None') {
            const selectedMember = familyMembers.find((member) => member.idNumber === selectedIdNumber);
            if (selectedMember) {
                policyHolder = {
                    name: `${selectedMember.firstName ?? ''} ${selectedMember.lastName ?? ''}`,
                    idNumber: selectedMember.idNumber ?? '',
                };
            }
        }
        setNewInsurancePolicy({ ...newInsurancePolicy, policyHolder });
    };

    useEffect(() => {
        if (newInsurancePolicy.company === '') {
            setNewInsurancePolicy({
                ...newInsurancePolicy,
                company: companies[0],
            });
        }
    }, [companies, newInsurancePolicy.company, setNewInsurancePolicy]);

    return (
        <>
            {/* Company */}
            <Form.Group className="mb-3" controlId="companyName">
                <Form.Label>Company Name</Form.Label>
                <Form.Select name="company" value={newInsurancePolicy.company} onChange={handlePolicyInputChange}>
                    {companies.map((company) => (
                        <option key={company} value={company}>
                            {company}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            {/* Main Branch */}
            <Form.Group className="mb-3" controlId="InsurancePolicyMainBranch">
                <Form.Label>Main Branch</Form.Label>
                <Form.Select name="branch" value={newInsurancePolicy.branch} onChange={handlePolicyInputChange}>
                    {Object.values(InsurancePolicyMainBranch).map((branch) => (
                        <option key={branch} value={branch}>
                            {t(camelCaseToText(branch))}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="insuredList">
                <Form.Label>Insured List</Form.Label>
                <Select
                    options={familyMembers.map((member) => ({
                        value: member.idNumber,
                        label: `${member.firstName} ${member.lastName}`,
                    }))}
                    isMulti
                    name="insuredList"
                    value={
                        newInsurancePolicy.insuredList?.map((member) => ({
                            value: member.idNumber,
                            label: `${member.name || (member as any).firstName} ${member.idNumber}`,
                        })) || []
                    }
                    onChange={(selectedOptions) => {
                        const selectedIds = selectedOptions.map((option) => option.value);
                        const selectedMembers = familyMembers
                            .filter((member) => selectedIds.includes(member.idNumber))
                            .map((member) => ({
                                name: `${member.firstName} ${member.lastName}`,
                                idNumber: member.idNumber,
                            }));
                        setNewInsurancePolicy({
                            ...newInsurancePolicy,
                            insuredList: selectedMembers,
                        });
                    }}
                />
            </Form.Group>
            {/* Policy Holder Select according to family members */}
            <div className="row mb-3">
                <Form.Group className="col-md-6" controlId="InsurancePolicyHolder">
                    <Form.Label>Policy Holder</Form.Label>
                    <Form.Select name="policyHolder" value={newInsurancePolicy.policyHolder?.idNumber} onChange={handleSelectPolicyHolder}>
                        <option value="None">None</option>
                        {familyMembers.map((member: FamilyMember) => (
                            <option key={member.idNumber} value={member.idNumber}>
                                {member.firstName} {member.lastName}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>

                {/* Policy Plan Type Select according to InsurancePolicyPlanTypes */}
                <Form.Group className="col-md-6" controlId="InsurancePolicyPlanType">
                    <Form.Label>Plan Type</Form.Label>
                    <Form.Select name="planType" value={newInsurancePolicy.planType} onChange={handlePolicyInputChange}>
                        {Object.values(InsurancePolicyPlanTypes).map((planType) => (
                            <option key={planType} value={planType}>
                                {t(camelCaseToText(planType))}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </div>

            {/* Premium fields */}
            <div className="row mb-3">
                <Form.Group className="col-md-4" controlId="totalPremium">
                    <Form.Label>Total Premium</Form.Label>
                    <Form.Control
                        type="number"
                        name="totalPremium"
                        max={10000}
                        min={0}
                        value={newInsurancePolicy.totalPremium || ''}
                        onChange={handlePolicyInputChange}
                    />
                </Form.Group>

                <Form.Group className="col-md-4" controlId="premiumDiscountPercentage">
                    <Form.Label>Discount Percentage</Form.Label>
                    <Form.Control
                        type="number"
                        name="premiumDiscountPercentage"
                        max={100}
                        min={0}
                        value={newInsurancePolicy.premiumDiscountPercentage || ''}
                        onChange={handlePolicyInputChange}
                    />
                </Form.Group>

                <Form.Group className="col-md-4" controlId="premiumTerm">
                    <Form.Label>Premium Term</Form.Label>
                    <Form.Select name="premiumTerm" value={newInsurancePolicy.premiumTerm} onChange={handlePolicyInputChange}>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                    </Form.Select>
                </Form.Group>
            </div>

            {/* Date fields */}
            <div className="row mb-3">
                {/* <Form.Group className="col-md-4" controlId="issueDate">
                    <Form.Label>Issue Date</Form.Label>
                    <Form.Control
                        type="date"
                        name="issueDate"
                        value={newInsurancePolicy.issueDate ? new Date(newInsurancePolicy.issueDate).toISOString().split('T')[0] : ''}
                        onChange={handlePolicyInputChange}
                    />
                </Form.Group> */}

                <Form.Group className="col-md-4" controlId="insuranceStartDate">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                        type="date"
                        name="insuranceStartDate"
                        value={
                            newInsurancePolicy.insuranceStartDate
                                ? new Date(newInsurancePolicy.insuranceStartDate).toISOString().split('T')[0]
                                : new Date(new Date().setMonth(new Date().getMonth() + 1, 1)).toISOString().split('T')[0]
                        }
                        onChange={handlePolicyInputChange}
                    />
                </Form.Group>

                <Form.Group className="col-md-4" controlId="insuranceEndDate">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                        type="date"
                        name="insuranceEndDate"
                        value={newInsurancePolicy.insuranceEndDate ? new Date(newInsurancePolicy.insuranceEndDate).toISOString().split('T')[0] : ''}
                        onChange={handlePolicyInputChange}
                    />
                </Form.Group>
            </div>
        </>
    );
};

export default PolicyInputFields;
