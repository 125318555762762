import { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../components/PageTitle';
import { User } from '../../interfaces/User';
import { APICore } from '../../utils/api/apiCore';
import { getManagerTeam } from '../../utils/api/chat';
import './MyTeam.scss';
import InputWrapper from '../../components/inputWrapper/InputWrapper';
import { Alert, Button } from 'react-bootstrap';
import { assignMessages, removeAssignment } from '../../utils/api/users';
import Loader from '../../components/Loader';
import ArrayColumn from '../../components/ArrayColumn';
import globalErrorHandler from '../../utils/globalErrorHandler';

const MyTeam = () => {
    const navigate = useNavigate();
    const [team, setTeam] = useState<User[]>([]);
    const [teamOptions, setTeamOptions] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [saveMessage, setSaveMessage] = useState<{ text: string; variant: string } | undefined>();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const getTeamList = async () => {
        setLoading(true);

        try {
            const response = await getManagerTeam();

            setTeam(
                response.team.map((member: User) => ({
                    ...member,
                    messageControl: {
                        ...member.messageControl,
                        isActive: member.messageControl?.assignedRep === undefined,
                    },
                })),
            );

            setLoading(false);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    useEffect(() => {
        document.title = 'My Team';

        return () => {
            document.title = 'Better Admin';
        };
    }, []);

    useEffect(() => {
        const { user } = new APICore().getLoggedInUser();
        if (!user.isAdmin && !user.roles.includes('manager')) {
            navigate('/');
            return;
        }
        getTeamList();
    }, [navigate]);

    useEffect(() => {
        // Team options list contains only active members:
        setTeamOptions(team.filter((member: User) => member.messageControl?.isActive));
    }, [team]);

    const getRepName = (repId: string) => {
        return team.find((rep) => rep._id === repId)?.fullName;
    };

    const saveChanges = async (currentMember: User) => {
        setSaveLoading(true);

        try {
            if (currentMember.messageControl?.isActive) {
                updateChanged(currentMember._id, false);
                await removeAssignment(currentMember._id);

                setSaveLoading(false);
                setSaveMessage({
                    text: `${currentMember.fullName} saved as active.`,
                    variant: 'success',
                });

                setTimeout(() => {
                    setSaveMessage(undefined);
                }, 5000);

                return;
            }

            const assignedRepId = currentMember.messageControl?.assignedRep;

            if (assignedRepId) {
                updateChanged(currentMember._id, false);
                await assignMessages(currentMember._id, assignedRepId);

                setSaveMessage({
                    text: `${currentMember.fullName} saved as not active, assigned to ${getRepName(assignedRepId)}`,
                    variant: 'success',
                });

                setTimeout(() => {
                    setSaveMessage(undefined);
                }, 5000);
            } else {
                setSaveMessage({
                    text: `Can't set ${currentMember.fullName} as 'Inactive'. A team member must be assigned.`,
                    variant: 'danger',
                });

                setTimeout(() => {
                    setSaveMessage(undefined);
                }, 5000);
            }
            setSaveLoading(false);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const updateChanged = (memberId: string, changed: boolean) => {
        setTeam(
            team.map((member: User) => {
                if (member._id === memberId) {
                    return { ...member, changed };
                }
                return member;
            }),
        );
    };

    const onRepSelected = (value: string, currentMember: User) => {
        setTeam(
            team.map((member: User) => {
                if (member._id === currentMember._id) {
                    return {
                        ...member,
                        changed: true,
                        messageControl: {
                            ...member.messageControl,
                            assignedRep: value === 'select' ? undefined : value,
                        },
                    };
                }
                return member;
            }),
        );
    };

    const toggleIsActive = (currentMember: User) => {
        setTeam(
            team.map((member: User) => {
                if (member._id === currentMember._id) {
                    return {
                        ...member,
                        changed: true,
                        messageControl: {
                            ...member.messageControl,
                            isActive: !member.messageControl?.isActive,
                        },
                    };
                }
                return member;
            }),
        );
    };

    const IsActiveColumn = (params: any) => {
        return (
            <div className="switch_box box_1">
                <input
                    type="checkbox"
                    checked={params.row.original.messageControl?.isActive}
                    className="switch_1"
                    onChange={() => toggleIsActive(params.row.original)}
                />
            </div>
        );
    };

    const AssignToColumn = (params: any) => {
        const isActive = params.row.original.messageControl?.isActive;
        const assignedRepId = params.row.original.messageControl?.assignedRep;

        return (
            <InputWrapper label="" wrapperClass="input-field select-field">
                <select
                    defaultValue={assignedRepId || 'select'}
                    className="form-select"
                    name="chooseRep"
                    onChange={(e) => {
                        onRepSelected(e.target.value, params.row.original);
                    }}
                    disabled={isActive}
                >
                    <option value={'select'}>&#8618; Assign to</option>
                    {teamOptions.length === 0 && (
                        <option value={'select'} disabled>
                            No active team members.
                        </option>
                    )}

                    {teamOptions.map((member) => (
                        <option key={member._id} value={member._id}>
                            {member.fullName}
                        </option>
                    ))}
                </select>
            </InputWrapper>
        );
    };

    const SaveColumn = (params: any) => {
        return (
            <div className="save-wrapper">
                <Button disabled={!params.row.original.changed} onClick={() => saveChanges(params.row.original)}>
                    {saveLoading ? 'Saving...' : 'Save'}
                </Button>
            </div>
        );
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'fullName',
            sort: true,
        },
        {
            Header: 'Role',
            accessor: 'roles',
            sort: true,
            Cell: ArrayColumn,
        },
        {
            Header: 'Active',
            accessor: 'messageControl.isActive',
            sort: true,
            Cell: IsActiveColumn,
        },
        {
            Header: 'Assign To',
            accessor: 'messageControl.assignedRep',
            sort: false,
            Cell: AssignToColumn,
        },
        {
            Header: 'Save',
            accessor: '',
            sort: false,
            Cell: SaveColumn,
        },
    ];

    return (
        <div className="my-team-wrapper">
            <PageTitle breadCrumbItems={[{ label: 'My Team', path: '/my-team', active: true }]} title={'My Team'} />
            {saveMessage && <Alert variant={saveMessage?.variant}>{saveMessage?.text}</Alert>}
            <div className="my-team-table">
                {loading ? (
                    <Loader />
                ) : team.length > 0 ? (
                    <Table
                        columns={columns}
                        data={team}
                        cellClick={[0, 1, 2, 3]}
                        isSortable={true}
                        pagination={false}
                        isSelectable={false}
                        tableClass=""
                    />
                ) : (
                    <div className="no-team">No team members found.</div>
                )}
            </div>
        </div>
    );
};

export default MyTeam;
