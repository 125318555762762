import React from 'react';

interface BorderWithTextProps {
    children: React.ReactNode;
    label: string;
    containerStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
}

const BorderWithText = ({ children, label, containerStyle = {}, labelStyle = {} }: BorderWithTextProps) => {
    const defaultContainerStyle: React.CSSProperties = {
        position: 'relative',
        border: '2px solid rgba(128, 128, 128, 0.3)', // Default border color and opacity
        borderRadius: '0.15rem', // Default border radius
        padding: '20px',
        ...containerStyle,
    };

    const defaultLabelStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: '10px',
        background: 'white',
        color: 'rgba(128, 128, 128)', // Default label color and opacity
        padding: '0 10px',
        transform: 'translateY(-50%)',
        ...labelStyle,
    };

    return (
        <div style={defaultContainerStyle}>
            <div style={defaultLabelStyle}>{label}</div>
            {children}
        </div>
    );
};

export default BorderWithText;
