import React from 'react';
import { Form } from 'react-bootstrap';
import { LifeInsurance } from '../../../../../interfaces/TaxData';
import BorderWithText from '../../../../../components/BorderWithText';
import { useTranslation } from 'react-i18next';

interface LifeInsuranceFormProps {
    data: LifeInsurance;
    prefix: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const LifeInsuranceForm = ({ data, prefix, onChange }: LifeInsuranceFormProps) => {
    const { t } = useTranslation('taxReturn');

    return (
        <BorderWithText label={t('Life Insurance')}>
            <div className="LifeInsuranceForm">
                <Form.Group controlId="TotalWorkFitnessLossPremium">
                    <Form.Label>{t('Total Work Fitness Loss Premium')}</Form.Label>
                    <Form.Control
                        type="number"
                        name={`${prefix}.totalWorkFitnessLossPremium`}
                        value={data.totalWorkFitnessLossPremium}
                        onChange={onChange}
                    />
                </Form.Group>
                <Form.Group controlId="TotalLifeInsurancePremium">
                    <Form.Label>{t('Total Life Insurance Premium')}</Form.Label>
                    <Form.Control
                        type="number"
                        name={`${prefix}.totalLifeInsurancePremium`}
                        value={data.totalLifeInsurancePremium}
                        onChange={onChange}
                    />
                </Form.Group>
            </div>
        </BorderWithText>
    );
};

export default LifeInsuranceForm;
