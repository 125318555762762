export enum InsurancePolicyStatuses {
    initial = 'initial',
    sentToClient = 'sentToClient',
    signedByClient = 'signedByClient',
    signedByAgent = 'signedByAgent',
    sentToInsuranceCompany = 'sentToInsuranceCompany',
    missingInfo = 'missingInfo',
    rejected = 'rejected',
    done = 'done',
}

export enum InsurancePolicyMainBranch {
    carInsurance = 'carInsurance',
    homeInsurance = 'homeInsurance',
    healthInsurance = 'healthInsurance',
    lifeInsurance = 'lifeInsurance',
    elementaryInsurance = 'elementaryInsurance',
}

export enum InsurancePolicyPlanTypes {
    individual = 'individual',
    group = 'group',
    gropHMO = 'groupHMO',
}

export interface PolicyHolder {
    name: string;
    idNumber: string;
}

export interface InsurancePolicy {
    _id?: string;
    createdAt?: Date;
    client?: string;
    clientId: string;
    status: InsurancePolicyStatuses;
    policyNumber?: string;
    policyHolder: PolicyHolder;
    company: string;
    branch: InsurancePolicyMainBranch;
    planType: InsurancePolicyPlanTypes;
    insuranceId: string;
    isAgentAppointed?: boolean;
    isNewPolicy?: boolean;
    recommendation?: string;
    recommendationReason?: string;
    totalPremium?: number;
    premiumDiscountPercentage?: number;
    premiumTerm?: 'monthly' | 'yearly';
    issueDate?: Date;
    insuranceStartDate?: Date;
    insuranceEndDate?: Date;
    insuredList?: PolicyHolder[];
    cancellationAppendixA?: boolean;
    cancellationAppendixC?: boolean;
    noCancellationRequired?: boolean;
    replacementPolicies?: string[];
    policyItReplaces?: string;
}

/**
 * if main branch is healthInsurance, then the branch of the associated appendix can be either health,
 * criticalIllness, cancedrIllness or personalAccidents.
 * if main branch is lifeInsurance, then the branch of the associated appendix can be either risk, mortageRisk, workDisability or insuranceUmbrella.
 * if the main branch is something else then those two, then the branch of the associated appendix is empty.
 * now for the appendix branchSection field. if appendix branch is health, then the branchSection can be either drugs or surgeries.
 * if the appendix branch is risk, then the branchSection can be either accidentDisability or death.
 * if the appendix branch is mortgageRisk, then the branchSection can be only death.
 * if the appendix branch is workDisability, then the branchSection can be either cancelation or roof.
 * if the appendix branch is insuranceUmbrella, then the branchSection can be either specialProfession or franchise.
 * if the appendix branch is personalAccidents, then the branchSection can be only professionalDissability.
 * if the appendix branch is something else then those, then the branchSection is empty.
 */
