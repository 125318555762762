import React, { useContext, useEffect, useState } from 'react';
import { Button, OverlayTrigger, ProgressBar, Tooltip } from 'react-bootstrap';
import Table from '../../../components/Table';
import { UserProfileContext } from '../../../context/userProfileContext';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import DateColumn from '../../../components/DateColumn';
import './timeline.scss';
import Modules from '../../../utils/modules';
import { deletePension } from '../../../utils/api/pension';
import camelCaseColumn from '../../../components/camelCaseColumn';
import { useTranslation } from 'react-i18next';
import globalErrorHandler from '../../../utils/globalErrorHandler';

interface TimeLineProps {
    createNewRoundEnabled?: boolean;
    SecondButton?: React.ReactElement;
}

const TimeLine = ({ createNewRoundEnabled = true, SecondButton }: TimeLineProps) => {
    const [rounds, setRounds] = useState<any[]>([]);

    const { profileContextData, setProfileContextData, onEditRound, onCreateNewRound, isRoundBeingCreated } = useContext(UserProfileContext);

    const { client, selectedModule } = profileContextData;

    const currentModuleName = profileContextData?.selectedModule?.data?.moduleName;

    const { t } = useTranslation('components');

    useEffect(() => {
        setRounds(selectedModule.data.rounds);
        // console.log(selectedModule.data.rounds[selectedModule.data.rounds.length - 1]);
        console.log(profileContextData);
    }, [profileContextData]);

    const onSelectRound = (round: any) => {
        setProfileContextData((prev: any) => ({
            ...prev,
            currentRound: round,
        }));
    };

    const canCreateNewRound = () => {
        const isRoundNotBeingCreated = !isRoundBeingCreated;
        const isCreateNewRoundEnabled = createNewRoundEnabled;
        const isPermittedToEdit = selectedModule.permittedToEdit;
        const hasNoInProgressRounds = !rounds.find((roundItem: any) => roundItem.progressStatus === 'inProgress');
        const isPensionModule = selectedModule.data.moduleName === Modules.pension;

        return isRoundNotBeingCreated && isCreateNewRoundEnabled && isPermittedToEdit && (hasNoInProgressRounds || isPensionModule);
    };

    const RoundColumn = (params: any) => {
        const currentRowRound = params.row.original;

        return (
            <span className="pointer" onClick={() => onSelectRound(currentRowRound)}>
                {currentRowRound.round}
            </span>
        );
    };

    const ProgressColumn = (params: any) => {
        const currentRowRound = params.row.original;

        const getCss = () => {
            return {
                width: currentRowRound.progressPercentage + '%',
            };
        };

        // create a function that returns the css for the progress bar
        // the progress text should be centered
        // the progress value background should be #4d78cd
        const getChild = () => (
            <div className="progress-container">
                <div className="progress-color" style={getCss()} />
                <div className="progress-value">{currentRowRound.progressPercentage}%</div>
            </div>
        );

        return (
            <div className="progress-bar-wrapper">
                <ProgressBar
                    variant={'info'}
                    now={currentRowRound.progressPercentage}
                    // label={`${ currentRowRound.progressPercentage }%`}
                    // visuallyHidden={true}
                    children={getChild()}
                />
            </div>
        );
    };

    const onDeleteRound = (round: any) => async () => {
        if (selectedModule?.data?.moduleName !== Modules.pension) return;

        try {
            if (!window.confirm(`Are you sure you want to delete ${camelCaseToText(selectedModule?.data?.moduleName)} round ${round.round}?`)) return;

            const result = await deletePension(round._id);

            const tmpRounds = [...rounds];
            const index = tmpRounds.findIndex((roundItem: any) => roundItem._id === round._id);
            tmpRounds.splice(index, 1);
            setRounds(tmpRounds);
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    const ActionColumn = ({ row }: { row: any }) => {
        const round = row.original;
        return (
            <>
                {selectedModule?.data?.moduleName === Modules.pension && round.status === 'initial' && round.round !== 1 && (
                    <span className="action-icon" onClick={onDeleteRound(round)}>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="">Delete {camelCaseToText(selectedModule?.data?.moduleName)}</Tooltip>}>
                            <i className="mdi mdi-delete"></i>
                        </OverlayTrigger>
                    </span>
                )}
            </>
        );
    };

    const columns = [
        {
            Header: 'Round',
            accessor: 'round',
            sort: true,
            Cell: RoundColumn,
        },
        {
            Header: 'Start Date',
            accessor: 'createdAt',
            sort: true,
            Cell: DateColumn,
        },
        {
            Header: 'End Date',
            accessor: 'endTime',
            sort: true,
            Cell: DateColumn,
        },
        {
            Header: 'Status',
            accessor: 'status',
            sort: false,
            Cell: camelCaseColumn,
        },
        {
            Header: 'Progress',
            accessor: 'progressPercentage',
            sort: false,
            Cell: ProgressColumn,
        },
        {
            Header: 'Actions',
            accessor: 'delete',
            sort: false,
            Cell: ActionColumn,
        },
    ];

    const getOverlayText = () => {
        if (!selectedModule.permittedToEdit) return `You are not allowed to create a new ${camelCaseToText(selectedModule?.data?.moduleName)}`;
        else return 'Creating new round is enabled only if there are no round in progress';
    };

    const handleCreateNewRound = () => {
        // if there is already a round running, ask a confirmation question before continuing
        if (selectedModule.data.moduleName === Modules.pension && rounds.find((roundItem: any) => roundItem.progressStatus === 'inProgress')) {
            if (!window.confirm(t('There is already a round in progress. Are you sure you want to create a new round?'))) {
                return;
            }
        }
        onCreateNewRound(client._id, currentModuleName);
    };

    return (
        <div className="timeline-table card-shadow">
            <div className="time-line-header">
                <div className="title">{camelCaseToText(selectedModule?.data?.moduleName)} Timeline</div>
                {client && canCreateNewRound() ? (
                    <div>
                        {SecondButton}{' '}
                        <Button onClick={handleCreateNewRound} className="create-round btn-primary revert">
                            Create {camelCaseToText(selectedModule?.data?.moduleName)}
                        </Button>
                    </div>
                ) : (
                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="">{getOverlayText()}</Tooltip>}>
                        <Button className={`create-round btn-primary revert ${canCreateNewRound() ? '' : 'blocked'}`}>
                            Create {camelCaseToText(selectedModule?.data?.moduleName)} <i className={'fe-alert-circle'}></i>
                        </Button>
                    </OverlayTrigger>
                )}
            </div>

            {!columns || !rounds.length ? (
                <div className="no-data">This list is empty!</div>
            ) : (
                <>
                    <Table
                        columns={columns}
                        data={rounds}
                        onRowClicked={(row) => onSelectRound(row.original)}
                        cellClick={[0, 1, 2, 3]}
                        isSortable={true}
                        pagination={false}
                        isSelectable={false}
                        tableClass=""
                    />
                </>
            )}
        </div>
    );
};

export default TimeLine;
