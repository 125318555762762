import React, { useContext, useEffect, useState } from 'react';
import { chatContext } from '../../context/chatContext';
import { User } from '../../interfaces/User';
import { getManagerTeam } from '../../utils/api/chat';
import './../../components/inputWrapper/InputWrapper.scss';
import AutoCompleteSelect from '../../components/auto-complete-select/AutoCompleteSelect';
import { useTranslation } from 'react-i18next';
import { getUsers } from '../../utils/api/users';
const ManagerDisplayWrapper = (props: any) => {
    const { t } = useTranslation('components');
    const { chatData, setPage, setFilters } = useContext(chatContext);
    const [team, setTeam] = useState<User[]>([]);
    const [repName, setRepName] = useState<string | undefined>('');

    useEffect(() => {
        setTeam(chatData.users);
    }, [chatData]);

    const repChosen = (repId: string | null, event: any) => {
        const name = event.target.text.split('(not active)')[0].trim();
        setRepName(name);
        setPage(0);
        setFilters({ ...chatData.filters, repId });
    };

    return (
        <div className={`display-as ${repName ? 'team-member' : ''}`}>
            <div className="title-wrapper">
                {chatData.systemUser?.isManager ? (
                    <>
                        <h5 className="title mt-1 mb-2 font-15">{repName && <span>{'Display as: ' + repName}</span>}</h5>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {chatData.systemUser?.isManager && (
                <AutoCompleteSelect
                    onSelect={repChosen}
                    toggleText={<span>&#8644; Display chats as:</span>}
                    placeholder={t('search by name...')}
                    options={team}
                    searchKey="fullName"
                    valueKey="_id"
                    renderItem={(item) => (
                        <>
                            {item.fullName} {item.messageControl?.assignedRep !== undefined ? ' (not active)' : ''}
                        </>
                    )}
                />
            )}
        </div>
    );
};

export default ManagerDisplayWrapper;
