import { useEffect, useState } from 'react';
import { PerYearPersonData, TaxData } from '../../../../../interfaces/TaxData';
import DonationsForm from './DonationsForm';
import LifeInsuranceForm from './LifeInsuranceForm';
import PersonYearlyDataForm from './PersonYearlyDataForm';
import TaxDeductionForm from './TaxDeductionForm';
import { useTranslation } from 'react-i18next';

interface PerYearDataSectionProps {
    taxData: TaxData;
    currentYear: number | null;
    onChange: (e: any) => void;
}

const PerYearDataSection = ({ taxData, currentYear, onChange }: PerYearDataSectionProps) => {
    const { t } = useTranslation('taxReturn');

    const [currentTaxDataIndex, setCurrentTaxDataIndex] = useState<number>(-1);
    const [currentTaxDataSpouseIndex, setCurrentTaxDataSpouseIndex] = useState<number>(-1);
    const [currentTaxDataCoupleIndex, setCurrentTaxDataCoupleIndex] = useState<number>(-1);

    useEffect(() => {
        const index1 = taxData.clientTaxData?.perYearData.findIndex((item) => item.year == currentYear);
        setCurrentTaxDataIndex(index1);

        const index2 = taxData.spouseTaxData?.perYearData.findIndex((item) => item.year == currentYear);
        setCurrentTaxDataSpouseIndex(index2);

        const index3 = taxData.perYearCoupleData.findIndex((item) => item.year == currentYear);
        setCurrentTaxDataCoupleIndex(index3);
    }, [taxData, currentYear]);

    return (
        <div className="per-year-data-form">
            <div className="client-spouse-data">
                <h4>{t('Client')}</h4>
                <PersonYearlyDataForm
                    data={taxData.clientTaxData.perYearData[currentTaxDataIndex]}
                    prefix={`clientTaxData.perYearData[${currentTaxDataIndex}]`}
                    onChange={onChange}
                />
            </div>

            <div className="client-spouse-data">
                <h4>{t('Spouse')}</h4>
                <PersonYearlyDataForm
                    data={taxData.spouseTaxData.perYearData[currentTaxDataSpouseIndex]}
                    prefix={`spouseTaxData.perYearData[${currentTaxDataSpouseIndex}]`}
                    onChange={onChange}
                />
            </div>

            <div className="client-spouse-data">
                <h4>{t('Couple')}</h4>
                <div className="data-form">
                    {taxData.perYearCoupleData[currentTaxDataCoupleIndex]?.donations && (
                        <DonationsForm
                            data={taxData.perYearCoupleData[currentTaxDataCoupleIndex].donations}
                            prefix={`perYearCoupleData[${currentTaxDataCoupleIndex}].donations`}
                            onChange={onChange}
                        />
                    )}
                    {taxData.perYearCoupleData[currentTaxDataCoupleIndex]?.lifeInsurance && (
                        <LifeInsuranceForm
                            data={taxData.perYearCoupleData[currentTaxDataCoupleIndex].lifeInsurance}
                            prefix={`perYearCoupleData[${currentTaxDataCoupleIndex}].lifeInsurance`}
                            onChange={onChange}
                        />
                    )}
                    {taxData.perYearCoupleData[currentTaxDataCoupleIndex]?.taxDeduction867Form && (
                        <TaxDeductionForm
                            data={taxData.perYearCoupleData[currentTaxDataCoupleIndex].taxDeduction867Form}
                            prefix={`perYearCoupleData[${currentTaxDataCoupleIndex}].taxDeduction867Form`}
                            onChange={onChange}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default PerYearDataSection;
