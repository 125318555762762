import { useState } from 'react';

const useTabs = () => {
    const tabs = ['General', ...getYearsArray()];
    const [activeTab, setActiveTab] = useState<string | number>('General');
    const [currentYear, setCurrentYear] = useState<number | null>(null);
    const [isGeneralTab, setIsGeneralTab] = useState<boolean>(true);

    const indexOfPerYearData = tabs.indexOf(activeTab) - 1;

    const handleTabClick = (tab: string | number) => {
        setActiveTab(tab);
        setCurrentYear(tab === 'General' ? null : parseInt(tab.toString()));
        setIsGeneralTab(tab === 'General');
    };

    return {
        tabs,
        currentYear,
        activeTab,
        indexOfPerYearData,
        isGeneralTab,
        handleTabClick,
    };
};

export default useTabs;

const getYearsArray = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;
    const startYear = currentMonth >= 4 ? currentYear - 1 : currentYear - 2;

    return Array.from({ length: 6 }, (_, i) => startYear - i);
};
