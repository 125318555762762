import { APICore, API_URL, MORTGAGE_URL } from './apiCore';

const api = new APICore();

const createMortgage = (clientId: string) => {
    return api.create(`${MORTGAGE_URL}/mortgage`, { clientId });
};

const editMortgage = (taxReturnId: string, body: any) => {
    const relativeUrl = `/mortgage/${taxReturnId}`;
    return api.update(`${MORTGAGE_URL}${relativeUrl}`, body);
};

const getMortgageStatuses = () => {
    return api.get(`${MORTGAGE_URL}/mortgage/statuses`);
};

export { getMortgageStatuses, createMortgage, editMortgage };
