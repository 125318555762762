import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

import { ClientFile } from '../../interfaces/ClientFile';
import FileUploader from '../../components/FileUploader';
import { FileType } from '../../interfaces/FileType';
import { uploadFile } from '../../utils/api/files';
import FileGallery from '../files/FileGallery';
import { UserProfileContext } from '../../context/userProfileContext';
import globalErrorHandler from '../../utils/globalErrorHandler';

const ProfileFiles = () => {
    const { t } = useTranslation();

    const { profileContextData, onEditRound } = useContext(UserProfileContext);
    const { selectedModule, currentRound, client } = profileContextData;
    const [newFile, setNewFile] = useState<ClientFile>();

    const onFileUpload = async (file: FileType) => {
        try {
            const params: {
                clientId: string;
                file: FileType;
                pensionId?: string;
                taxReturnId?: string;
                depositId?: string;
                insuranceId?: string;
                loanId?: string;
                mortgageId?: string;
                bankFeesId?: string;
                investmentId?: string;
            } = { clientId: client._id, file };

            if (selectedModule.data.moduleName === 'taxReturn') {
                params.taxReturnId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'pension') {
                params.pensionId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'insurance') {
                params.insuranceId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'deposit') {
                params.depositId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'loan') {
                params.loanId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'mortgage') {
                params.mortgageId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'bankFees') {
                params.bankFeesId = currentRound?._id;
            } else if (selectedModule.data.moduleName === 'investment') {
                params.investmentId = currentRound?._id;
            }

            const result = await uploadFile(params);
            await onEditRound({ files: [...(currentRound?.files || []), result.file._id] }, selectedModule.data.moduleName, currentRound?._id || '');
            setNewFile(result.file);
        } catch (err: any) {
            if (err.status === 402) return alert('Employer data is missing (Jotform 3)');

            if (err.status === 403) return alert('Client Israeli ID does not match the uploaded file');

            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        console.log('client', client);
    }, [client]);

    const onFileChanged = () => {};

    return (
        <Row>
            <Col xl={4} lg={4}>
                <Card>
                    <Card.Body>
                        <h4 className="header-title mb-3">File Upload</h4>

                        <p className="font-13 m-b-30">Drag and drop any files relevant for the user during and after the Zoom call</p>

                        <FileUploader showPreview={true} onFileUpload={onFileUpload} />
                    </Card.Body>
                </Card>
            </Col>
            <Col xl={8} lg={8}>
                {
                    <FileGallery
                        client={client}
                        currentRound={currentRound}
                        onChange={onFileChanged}
                        newfile={newFile}
                        selectedModule={selectedModule}
                    />
                }
            </Col>
        </Row>
    );
};

export default ProfileFiles;
