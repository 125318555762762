import { FutureMessage } from '../../interfaces/FutureMessage';
import { removeTags } from '../../utils/removeTags';
import { Dropdown } from 'react-bootstrap';
import { formatDate } from '../../utils/formatDate';
import React from 'react';
import emailIcon from './email.png';
import whatsappIcon from './whatsapp.png';

interface UserMessageProps {
    id: string;
    message: FutureMessage;
    onCancelFutureMessage: (message: FutureMessage) => void;
    onSendFutureMessageNow: (message: FutureMessage) => void;
}

const FutureMessageItem = ({ message, onCancelFutureMessage, onSendFutureMessageNow, id }: UserMessageProps) => {
    const renderBody = () => {
        if (!message.body) return;

        if (message.communicationMethod === 'email') {
            return removeTags(message.body)
                ?.split('***')
                .map((line, index) => (
                    <span key={index}>
                        {line}
                        <br />
                    </span>
                ));
        } else {
            return message.body.split('\n').map((line, index) => (
                <span key={index}>
                    {line}
                    <br />
                </span>
            ));
        }
    };

    return (
        <li className="clearfix" id={id}>
            <div className="chat-avatar position-relative">
                <span className="rep">BT</span>
            </div>

            <div className="conversation-text">
                <div className="ctext-wrap future">
                    {message.assignedBot ? (
                        <div className="sender-name">
                            Better Bot ({message.assignedBot.fullName} #{message.messageId})
                        </div>
                    ) : message.rep ? (
                        <div className="sender-name">{message.rep.fullName}</div>
                    ) : (
                        <div className="sender-name">Chatbot</div>
                    )}

                    <i className="message-status mdi mdi-lock"></i>

                    {renderBody()}

                    <p>
                        <i>{formatDate(message.date, false, false)}</i>
                    </p>

                    <Dropdown align="start" className="actions">
                        <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                            <i className="mdi mdi-dots-vertical font-16"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => onSendFutureMessageNow(message)}>
                                <span>
                                    Send now
                                    <i className="mdi mdi-send" />
                                </span>
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => onCancelFutureMessage(message)}>
                                <span>
                                    Cancel message
                                    <i className="mdi mdi-cancel" />
                                </span>
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="chat-buttons">
                    {message.buttons?.map((button, index) => (
                        <button key={index} className="btn">
                            {button.text}
                        </button>
                    ))}
                </div>
            </div>
        </li>
    );
};

export default FutureMessageItem;
