import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BorderWithText from '../../../../../components/BorderWithText';

interface GeneralFormBoxRendererProps {
    data: any;
    prefix: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    title: string;
    excludeFields?: string[];
}

const GenericFormBoxRenderer = ({ data, prefix, onChange, title, excludeFields = [] }: GeneralFormBoxRendererProps) => {
    const { t } = useTranslation('taxReturn');

    const fieldIsValid = (field: string) => {
        return data[field] !== undefined && data[field] !== null && data[field] !== '' && data[field] !== 0 && !excludeFields.includes(field);
    };

    const isThereAtLeastOneField = () => {
        return Object.keys(data).some(fieldIsValid);
    };

    return (
        <BorderWithText label={t(title)}>
            <div className="general-form-renderer">
                {isThereAtLeastOneField() ? (
                    <>
                        {Object.entries(data).map(([key, value]) =>
                            fieldIsValid(key) ? (
                                <Form.Group controlId={key} key={key}>
                                    <Form.Label>{t(key)}</Form.Label>
                                    <Form.Control
                                        type={!isNaN(Number(value)) ? 'number' : 'text'}
                                        name={`${prefix}.${key}`}
                                        value={String(value)}
                                        onChange={onChange}
                                    />
                                </Form.Group>
                            ) : null,
                        )}
                    </>
                ) : (
                    <>{t('noDataAvailable')}</>
                )}
            </div>
        </BorderWithText>
    );
};

export default GenericFormBoxRenderer;
