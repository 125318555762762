import { User } from './User';
import { ProductTransfer } from './ProductTransfer';
import { Deposit } from './Deposit';
import { Action } from './Action';
import { Client } from './Client';
import { Pension } from './Pension';
import { Investment } from './Investment';

export enum PensionProductTypes {
    comprehensivePensionFund = 'comprehensivePensionFund',
    oldPensionFund = 'oldPensionFund',
    complementaryPensionFund = 'complementaryPensionFund',
    studyFund = 'studyFund',
    providentFund = 'providentFund',
    investmentFund = 'investmentFund',
    directorsInsurance = 'directorsInsurance',
    risk = 'risk',
    mortgageRisk = 'mortgageRisk',
    collectiveRisk = 'collectiveRisk',
    savingsPolicy = 'savingsPolicy',
    other = 'other',
}

export const pensionsStatuses = [
    'preZoom',
    'noShow',
    'lostPreZoom',
    'postZoom',
    'postZoomMissingCriticalFiles',
    'postZoomMissingFiles',
    'lostPostZoom',
    'maslekaSubmitted',
    'maslekaReceived',
    'formContainBugs',
    'formsReady',
    'noSavingsFound',
    'sentSavingsWhatsapp',
    'lostAfterSavingWhatsapp',
    'agentMeetingScheduled',
    'lostAfterAgentMeetingScheduled',
    'waitingForClientDecision',
    'clientDeclinedOffer',
    'lostAfterAgentCall',
    'clientReceivedForms',
    'lostTransferFormsNotSigned',
    'clientSignedForms',
    'agentSignedForms',
    'sentForProcessing',
    'retention',
    'clientLostToRetention',
    'pensionHandlingCompleted',
    'clientNotInterested',
];

export interface PensionProduct {
    _id: string;
    isShortTermStudyFund?: boolean;
    pension?: Pension | string;
    client: Client;
    name: string;
    company: {
        name: string;
        thumbUrl: string;
    };
    newProductDepositData?: any;
    investmentPolicies: [
        {
            name: string;
            amount: number;
        },
    ];
    policyNumber: string;
    coverageStartDate: Date;
    transferDate: Date;
    establishDate: Date;
    isDeclaredActive: boolean;
    isActiveByDeposits: boolean;
    agent: {
        agentId: number;
        startDate: Date;
        name: string;
    };
    deposits: {
        avgSalary: number;
        list: Deposit[];
        missingDeposits: Deposit[];
        averageMonthlyDeposit: number;
    };
    totalSavings: number;
    depositTypes: {
        employer: number;
        employee: number;
        severance: number;
    };
    savingsAllocation?: {
        capital: number;
        allowance: number;
        nonPayingAllowance: number;
        versatile: number;
    };
    employmentStatus: string;
    totalSavingsEndOfYear: number;
    insurance: {
        startDate: Date;
        premium: number;
        isCollective: boolean;
        death: [
            {
                coverageAmount: number;
                premium: number;
            },
        ];
        workDisability: [
            {
                coverageMonthlyAmount: number;
                premium: number;
            },
        ];
        pension: {
            relevantSalary: number;
            percentages: {
                disability: number;
                widow: number;
                parent: number;
                orphan: number;
            };
            disabilityMonthlyCost: number;
            relativesMonthlyCost: number;
        };
        coverages: [
            {
                premium: number;
                coverageType: string;
            },
        ];
    };
    commissions: {
        percentages: {
            deposit: number;
            savings: number;
            profit: number;
            maxDepositCommission: number;
            depositCommissionDiscount: number;
            savingsCommissionDiscount: number;
            discountEndDate: Date;
        };
        totalPerYear: number;
    };

    productType: PensionProductTypes;
    employers: [
        {
            name: string;
            employerID: number;
            active: boolean;
            address: {
                street: string;
                housenumber: number;
                city: string;
            };
        },
    ];
    actions: Action[];
    retirementSavings: number;
    productTransfer: ProductTransfer;
    isWorkDisabilityInsurance: number;
    sameAgentAndPensionOperator: boolean;
    loans: any[];
    flags: string[];
    isNewProduct: boolean;
}

export interface InventoryProductsFetchParams {
    limit?: number;
    page?: string | number;
    sortBy?: string;
    companyId?: string;
    productTypes?: string;
    isActiveByDeposits?: string;
    employmentStatus?: string;
    reportMonth?: Date | string;
    compareType?: 'new' | 'missing';
    compareFrom?: Date | string;
}
