import React from 'react';
import { Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { formatDate } from '../utils/formatDate';
import 'react-ladda-button/dist/ladda-themeless.min.css';
import { Comment } from '../interfaces/Comment';
import { Dropdown } from 'rsuite';
import i18n from 'i18next';
import './comments.scss';
import { FormInput } from '../components';
import { camelCaseToText } from '../utils/camelCaseToText';

interface CommentsProps {
    comments: Comment[];
    newComment: string;
    isCopied: boolean;
    textAreaRef: any;
    topics: string[];
    moduleFilter: string;
    sortBy: string;
    topic: string;
    commentTextChange: (text: string) => void;
    addCommentClicked: () => void;
    onDeleteComment: (id: string) => void;
    handleModuleFilterChange: (eventKey: any) => void;
    handleSortByChange: (eventKey: any) => void;
    onCopyComment: (comment: Comment) => void;
    getBadgeClass: (comment: Comment) => any;
    onSelected: (value: string) => void;
}

const Comments = ({
    comments,
    newComment,
    isCopied,
    textAreaRef,
    topics,
    moduleFilter,
    sortBy,
    topic,
    commentTextChange,
    addCommentClicked,
    onDeleteComment,
    handleModuleFilterChange,
    handleSortByChange,
    onCopyComment,
    getBadgeClass,
    onSelected,
}: CommentsProps) => {
    return (
        <Card className="comments comments-box">
            <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="header-title mb-3">Comments</h4>
                    <div className="comment-dropdowns">
                        <Dropdown
                            title={
                                <span>
                                    Module: <b>{moduleFilter ? camelCaseToText(moduleFilter) : 'All'}</b>
                                </span>
                            }
                            onSelect={handleModuleFilterChange}
                            style={{ marginRight: '10px' }}
                        >
                            <Dropdown.Item eventKey="">All</Dropdown.Item>
                            {topics.map((topic) => (
                                <Dropdown.Item eventKey={topic}>{camelCaseToText(topic)}</Dropdown.Item>
                            ))}
                        </Dropdown>

                        <Dropdown
                            title={
                                <span>
                                    Sort By: <b>{camelCaseToText(sortBy)}</b>
                                </span>
                            }
                            onSelect={handleSortByChange}
                        >
                            <Dropdown.Item eventKey="latest">Latest</Dropdown.Item>
                            <Dropdown.Item eventKey="earliest">Earliest</Dropdown.Item>
                        </Dropdown>
                    </div>
                </div>

                {comments.length ? (
                    <Row>
                        <Col className="comments-msg-area">
                            <ul className="conversation-list comments-list" style={{ direction: i18n.dir() }}>
                                {/* comments */}
                                {comments.map((comment: Comment, index: number) => (
                                    <li key={comment._id} className="conversation-text">
                                        <div className="ctext-wrap">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="copy">{isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}</Tooltip>}
                                            >
                                                <i className="mdi mdi-content-copy comment-icon copy" onClick={() => onCopyComment(comment)}></i>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="delete">Delete Comment</Tooltip>}>
                                                <i className="mdi mdi-delete comment-icon delete" onClick={() => onDeleteComment(comment._id)}></i>
                                            </OverlayTrigger>
                                            <div>
                                                <b>
                                                    {comment.user?.firstName} {comment.user?.lastName}
                                                </b>{' '}
                                                {comment.topic && (
                                                    <span className={'badge noti-icon-badge ' + getBadgeClass(comment)}>{comment.topic}</span>
                                                )}
                                            </div>
                                            <p>
                                                {comment.text.split('\n').map((line) => (
                                                    <div>{line}</div>
                                                ))}
                                            </p>
                                            <p>
                                                <i>{formatDate(comment.createdAt, false, false)}</i>
                                            </p>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                ) : null}

                {/* add comments */}
                <Row className="mt-2">
                    <Col>
                        <div className="border rounded">
                            <textarea
                                rows={3}
                                className="form-control border-0 resize-none"
                                placeholder="Write a comment here..."
                                value={newComment}
                                ref={textAreaRef}
                                onChange={(e: any) => {
                                    commentTextChange(e.target.value);
                                }}
                            />
                            <div className="p-2 bg-light d-flex justify-content-between align-items-center">
                                <FormInput
                                    label=""
                                    type="select"
                                    name="topic"
                                    value={topic}
                                    placeholder="Status"
                                    options={[{ label: 'Select topic', value: '' }].concat(
                                        topics.map((t) => ({
                                            value: t,
                                            label: camelCaseToText(t),
                                        })),
                                    )}
                                    onSelected={onSelected}
                                    // onInputChange={onInputChange}
                                />
                                <button type="submit" className="btn btn-sm btn-success" onClick={addCommentClicked}>
                                    <i className="mdi mdi-send me-1"></i>Submit
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default Comments;
