import React, { useEffect, useRef, useState } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import Table from '../../components/Table';
import { FileClassificationsHebrew } from '../../interfaces/ClientFile';
import { getFiles } from '../../utils/api/files';
import './files.scss';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { Client } from '../../interfaces/Client';
import { Pension } from '../../interfaces/Pension';
import { Onboarding } from '../../interfaces/Onboarding';
import { TaxReturn } from '../../interfaces/taxReturn';
import { Insurance } from '../../interfaces/Insurance';
import globalErrorHandler from '../../utils/globalErrorHandler';

interface ListItem {
    fileClassification: string;
    exists: boolean;
    total: number;
    count: number;
    modules: string[];
}

interface FilesListProps {
    shortList: boolean;
    client: Client;
    lastChange?: Date;
    round?: number;
    currentRound?: Pension | Onboarding | TaxReturn | Insurance;
    module?: string;
}

const FilesList = ({ shortList, client, lastChange, currentRound }: FilesListProps) => {
    const [list, setList] = useState<ListItem[]>([]);
    const [modules, setModules] = useState<string[]>([]);

    const handleFiles = async () => {
        try {
            const results = await getFiles({
                clientId: client._id,
                requiredFilesOnly: true,
            });
            refreshList(results.requiredFiles);
            // add modules for each item of results.requiredFiles to a single list with no duplicates
            const modules: string[] = results.requiredFiles.reduce((acc: string[], item: any) => {
                return acc.concat(item.modules);
            }, [] as string[]);

            setModules(Array.from(new Set(modules)));
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        handleFiles();
    }, [lastChange]);

    const refreshList = (requiredFiles: ListItem[]) => {
        const newList = new Array<ListItem>();

        requiredFiles.forEach((file) => {
            let item = newList.find((item) => item.fileClassification === file.fileClassification);
            if (!item) {
                item = {
                    fileClassification: file.fileClassification,
                    exists: true,
                    total: 0,
                    count: 0,
                    modules: file.modules,
                };
                newList.push(item);
            }
            item.total++;
            if (file.exists) item.count++;
        });

        if (shortList) setList(newList.filter((item) => item.count < item.total).slice(0, 7));
        else setList(newList);
    };

    const FileClassificationColumn = (params: any) => {
        const hebItem = Object.entries(FileClassificationsHebrew).find((item) => item[0] === params.value);
        return <>{hebItem ? hebItem[1] : ''}</>;
    };

    const StatusColumn = (params: any) => {
        const item = params.data[params.row.index];
        const { count, total } = item;
        return (
            <span className={count === total ? 'success' : count > 0 ? 'warning' : 'danger'}>
                {count}/{total}
            </span>
        );
    };

    const columns = [
        {
            Header: 'File type',
            accessor: 'fileClassification',
            classes: 'table-client',
            Cell: FileClassificationColumn,
        },
        {
            Header: 'Status',
            accessor: 'status',
            classes: 'table-client',
            Cell: StatusColumn,
        },
    ];

    const getModuleMissingFiles = (module: string, items: ListItem[]) => {
        return (
            <Card className="file-list" key={module}>
                <Card.Body>
                    <div className={shortList ? 'box' : ''}>
                        <div>
                            <h4 className="header-title mb-3">
                                {module} Missing Files (total: {items.reduce((total, item) => total + item.total - item.count, 0)})
                                {shortList ? (
                                    <span className="mb-2 font-13 view-all-products float-end">
                                        <Link to={'/clients/profile/files?clientId=' + client._id}>
                                            <span>View all files</span>
                                        </Link>
                                    </span>
                                ) : null}
                            </h4>
                        </div>

                        <Table
                            columns={columns}
                            data={items}
                            pageSize={20}
                            isSortable={true}
                            pagination={false}
                            isSelectable={false}
                            tableClass="table-nowrap table-striped table-FilesList"
                        />
                    </div>
                    ָ
                </Card.Body>
            </Card>
        );
    };
    return (
        <>
            {modules.map((module) => {
                const items = list.filter((item) => item.modules.includes(module));
                return getModuleMissingFiles(module, items);
            })}
            {!modules.length && getModuleMissingFiles('', [])}
        </>
    );
};

export default FilesList;
