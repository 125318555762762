import { Company } from './Company';

export enum InvestmentPolicies {
    default = 'default',
    stocks = 'stocks',
    sp500 = 'sp500',
    foreignGeneral = 'foreignGeneral',
    foreignStocks = 'foreignStocks',
    age50 = 'age50',
    age50to60 = 'age50to60',
    age60 = 'age60',
    religious = 'religious',
    bonds = 'bonds',
    bonds10 = 'bonds10',
    bonds15 = 'bonds15',
    bonds20 = 'bonds20',
    bonds25 = 'bonds25',
    exchangeListedSecurities = 'exchangeListedSecurities',
    flexIndex = 'flexIndex',
    shortTerm = 'shortTerm',
    passiveGeneral = 'passiveGeneral',
    isrGovBonds = 'isrGovBonds',
    govBonds = 'govBonds',
    IRA = 'IRA',
    other = 'other',
}

export interface Fund {
    _id: string;
    name: string;
    parentFund: {
        name: string;
        fundId: number;
    };
    fundId: {
        type: number;
        unique: true;
    };
    fundTypeHebrew: string;
    investmentPolicy: InvestmentPolicies;
    risk: string;
    isConstantYield: boolean;
    company: Company;
}
