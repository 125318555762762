import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormInput } from '../../components';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { User } from '../../interfaces/User';
import Select from 'react-select';
import { GenderOptions } from '../../interfaces/Client';
import { APICore } from '../../utils/api/apiCore';
import Modules from '../../utils/modules';
import FileUploader from '../../components/FileUploader';
import { FileType } from '../../interfaces/FileType';
interface AddUserProps {
    show: boolean;
    onHide?: () => void;
    onAddUser?: (newUser: User, oldUser?: User, file?: FileType) => void;
    user?: User;
}

interface Option {
    value: string;
    label: string;
}

const AddUser = ({ show, onHide, onAddUser, user }: AddUserProps) => {
    const [currentUser, setCurrentUser] = useState<User>();
    const [currentUserImageUrl, setCurrentUserImageUrl] = useState<string>(currentUser?.profileImage?.url || '');
    const [file, setFile] = useState<FileType>();
    const systemUser = new APICore().getLoggedInUser();
    const canEdit = systemUser.user.isAdmin;

    console.log({ currentUser });

    useEffect(() => {
        if (user) {
            setCurrentUser(user);
        } else {
            const activeUser: any = { ...currentUser };
            activeUser.isActive = true;
            setCurrentUser(activeUser);
        }
    }, [user]);

    const onChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;

        console.log({ name, value });

        const tempUser: any = { ...currentUser };

        if (event.target.type === 'checkbox') {
            tempUser[name] = event.target.checked;
        } else {
            tempUser[name] = value;
        }

        setCurrentUser(tempUser);
    };

    function multiSelectChange(modules: any) {
        setCurrentUser((prev: any) => {
            return {
                ...prev,
                agentModules: modules.map((module: { value: string }) => module.value),
            };
        });
    }

    const onSubmit = () => {
        // console.log('newUser', newUser)
        if (!currentUser) {
            return;
        }
        onAddUser?.(currentUser, user, file);
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header className="bg-light" onHide={onHide} closeButton>
                <Modal.Title className="m-0">{user ? 'Edit User' : 'Add New User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-4">
                <FormInput
                    disabled={!canEdit}
                    label="First Name"
                    type="text"
                    name="firstName"
                    defaultValue={currentUser?.firstName}
                    placeholder="Enter first name"
                    containerClass={'mb-3'}
                    onChange={onChange}
                />
                <FormInput
                    disabled={!canEdit}
                    label="Last Name"
                    type="text"
                    name="lastName"
                    defaultValue={currentUser?.lastName}
                    placeholder="Enter last name"
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Gender"
                    type="select"
                    name="gender"
                    value={currentUser?.gender}
                    placeholder="select gender"
                    containerClass={'mb-3'}
                    options={GenderOptions.map((type) => ({
                        label: camelCaseToText(type) || '',
                        value: type,
                    }))}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Email Address"
                    type="email"
                    name="email"
                    defaultValue={currentUser?.email}
                    placeholder="Enter email"
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Phone"
                    type="text"
                    name="phoneNumber"
                    defaultValue={currentUser?.phoneNumber}
                    placeholder="Enter phone number"
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <div className="form-label">Roles</div>
                <Select
                    isDisabled={!canEdit}
                    closeMenuOnSelect={false}
                    className="react-select react-select-container mb-3"
                    classNamePrefix="react-select"
                    value={currentUser?.roles?.map((role) => ({
                        label: role,
                        value: role,
                    }))}
                    isMulti
                    name="role"
                    options={['operator', 'agent', 'interviewer', 'manager', 'analyst', 'support'].map((type) => ({
                        label: type || '',
                        value: type,
                    }))}
                    onChange={(selectedRoles) => {
                        const roles = selectedRoles.map((role: Option) => role.value);
                        setCurrentUser((prev: any) => {
                            return { ...prev, roles };
                        });
                    }}
                />

                {currentUser && currentUser.roles?.includes('agent') && (
                    <FormInput
                        disabled={!canEdit}
                        label="Agent License"
                        type="text"
                        name="agentLicense"
                        defaultValue={currentUser?.agentLicense}
                        placeholder="Enter agent license"
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />
                )}

                {currentUser && currentUser.roles?.some((role) => ['agent', 'operator', 'manager'].includes(role)) && (
                    <>
                        <div className="form-label">Modules</div>
                        <Select
                            isDisabled={!canEdit}
                            closeMenuOnSelect={false}
                            className="react-select react-select-container"
                            classNamePrefix="react-select"
                            value={currentUser?.agentModules?.map((module: any) => ({
                                label: module,
                                value: module,
                            }))}
                            isMulti
                            name="agentModules"
                            options={Object.entries(Modules).map((item) => ({
                                label: camelCaseToText(item[0]),
                                value: item[1],
                            }))}
                            onChange={multiSelectChange}
                        />
                        <br />
                    </>
                )}

                {currentUser?.roles?.some((role) => ['agent', 'interviewer'].includes(role)) && (
                    <FormInput
                        disabled={!canEdit}
                        label="Meetings per day"
                        type="number"
                        name="meetingSlots"
                        defaultValue={currentUser?.meetingSlots}
                        containerClass={'mb-3'}
                        onChange={onChange}
                    />
                )}

                <FormInput
                    disabled={!canEdit}
                    label="Admin"
                    type="checkbox"
                    name="isAdmin"
                    // defaultChecked={currentUser?.isAdmin}
                    checked={currentUser?.isAdmin}
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Notify By WhatsApp"
                    type="checkbox"
                    name="notifyByWhatsapp"
                    // defaultChecked={currentUser?.isAdmin}
                    checked={currentUser?.notifyByWhatsapp}
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Active"
                    type="checkbox"
                    name="isActive"
                    checked={currentUser?.isActive}
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Module Responder"
                    type="checkbox"
                    name="isModuleResponder"
                    checked={currentUser?.isModuleResponder}
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <FormInput
                    disabled={!canEdit}
                    label="Exclude From Fake Onboarder Reassign"
                    type="checkbox"
                    name="excludeFromOnboardingAutoReassign"
                    checked={currentUser?.excludeFromOnboardingAutoReassign}
                    containerClass={'mb-3'}
                    onChange={onChange}
                />

                <label className="mb-1 mt-2">Image</label>
                <FileUploader
                    showPreview={false}
                    onFileUpload={(file: FileType) => {
                        setFile(file);
                        setCurrentUserImageUrl(URL.createObjectURL(file));
                    }}
                    currentImageUrl={currentUserImageUrl || user?.profileImage?.url}
                />
                <div className="text-end">
                    <Button disabled={!canEdit} variant="success" onClick={onSubmit} className="waves-effect waves-light me-1">
                        Save
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AddUser;
