import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Table from '../../components/Table';
import { getDeposits } from '../../utils/api/products';
import DateColumn from '../../components/DateColumn';
import MoneyColumn from '../../components/MoneyColumn';
import { Deposit } from '../../interfaces/Deposit';
import { UserProfileContext } from '../../context/userProfileContext';
import { Pension } from '../../interfaces/Pension';
import { addCommas } from '../../utils/addCommas';
import globalErrorHandler from '../../utils/globalErrorHandler';

const MoneyPercentageColumn = (params: any) => {
    const { salary } = params.row.original;
    if (!params.cell.value) return '-';

    let text = `₪${addCommas(params.cell.value)}`;
    if (salary) text += ` (${Math.round((params.cell.value / salary) * 10000) / 100}%)`;

    return text;
};
const Deposits = () => {
    const { profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const pension: Pension = profileContextData.currentRound as Pension;

    const columnsInitialValue = [
        {
            Header: 'Date',
            accessor: 'date',
            sort: true,
            classes: 'table-client',
            Cell: DateColumn,
        },
        {
            Header: 'Product',
            accessor: 'productName',
            sort: true,
            classes: 'table-client',
        },
        {
            Header: 'Employer',
            accessor: 'amounts.employer',
            sort: true,
            classes: 'table-client',
            Cell: MoneyPercentageColumn,
        },
        {
            Header: 'Employee',
            accessor: 'amounts.employee',
            sort: true,
            classes: 'table-client',
            Cell: MoneyPercentageColumn,
        },
        {
            Header: 'Severance',
            accessor: 'amounts.severance',
            sort: true,
            classes: 'table-client',
            Cell: MoneyPercentageColumn,
        },
        {
            Header: 'Policy Number',
            accessor: 'policyNumber',
            sort: true,
            classes: 'table-client',
        },
        {
            Header: 'Self Employed',
            accessor: 'amounts.selfEmployed',
            sort: true,
            classes: 'table-client',
            Cell: MoneyColumn,
        },
        {
            Header: 'Work Disability',
            accessor: 'amounts.workDisability',
            sort: true,
            classes: 'table-client',
            Cell: MoneyPercentageColumn,
        },
        {
            Header: 'Salary',
            accessor: 'salary',
            sort: true,
            classes: 'table-client',
            Cell: MoneyColumn,
        },
        {
            Header: 'Deposited By',
            accessor: 'depositedBy',
            sort: true,
            classes: 'table-client',
        },
    ];

    const mounted = useRef(false);

    const [deposits, setDeposits] = useState<Deposit[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    useEffect(() => {
        mounted.current = true;
        if (mounted) {
            handleDeposits();
        }

        return () => {
            mounted.current = false;
        };
    }, [pension]);

    const handleDeposits = async () => {
        try {
            const results = await getDeposits({ pensionId: pension._id });
            const newColumns = [...columnsInitialValue];

            if (!results.deposits.filter((d: any) => d.amounts.workDisability).length) {
                const index = newColumns.findIndex((c) => c.accessor === 'amounts.workDisability');
                if (index >= 0) newColumns.splice(index, 1);
            }

            if (!results.deposits.filter((d: any) => d.amounts.selfEmployed).length) {
                const index = newColumns.findIndex((c) => c.accessor === 'amounts.selfEmployed');
                if (index >= 0) newColumns.splice(index, 1);
            }

            setColumns(newColumns);

            setDeposits(results.deposits);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col>
                        <h4 className="header-title mb-3">Deposits</h4>
                    </Col>
                </Row>

                {!!deposits.length && (
                    <Table
                        columns={columns}
                        data={deposits}
                        pageSize={50}
                        isSortable={true}
                        pagination={false}
                        isSelectable={false}
                        tableClass="table-nowrap table-striped table-Deposits"
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default Deposits;
