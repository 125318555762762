import { useContext, useEffect } from 'react';
import InputWrapper from '../../components/inputWrapper/InputWrapper';
import { soryByOptions } from '../../interfaces/Chat';
import { chatContext } from '../../context/chatContext';

const SortWrapper = () => {
    const { sortBy, setSortBy, setPage } = useContext(chatContext);

    const onSortChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;

        setPage(0);
        setSortBy({ ...sortBy, [name]: value });
    };

    return (
        <div className="filter-wrapper">
            <InputWrapper status={{ text: '' }} label="" wrapperClass="input-field select-field filter-message">
                <select defaultValue={'select'} className="form-select" name="sort" onChange={onSortChange}>
                    <option value={'select'}>&#8645; Sort by</option>
                    {soryByOptions.map((type) => (
                        <option key={type} value={type}>
                            Sort by {type}
                        </option>
                    ))}
                </select>
            </InputWrapper>
        </div>
    );
};

export default SortWrapper;
