import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

interface ConfirmModalProps {
    title: string;
    body: string;
    show: boolean;
    noHide: () => void;
    handleConfirm: () => void;
    confirmText?: string;
    cancelText?: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ title, body, show, handleConfirm, noHide, cancelText, confirmText }) => {
    return (
        <div className="confirm-modal">
            <Modal className="confirm-modal" backdropClassName="confirm-backdrop" show={show}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={noHide}>
                        {cancelText || 'Cancel'}
                    </Button>
                    <Button variant="success" onClick={handleConfirm}>
                        {confirmText || 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ConfirmModal;
