import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import ClientInfo from '../clientInfo/ClientInfo';
import ModuleTabs from '../moduleTabs/ModuleTabs';
import { APICore } from '../../../utils/api/apiCore';
import TimeLine from '../timeLine/TimeLine';
import MainData from './MainData';

const Mortgage = () => {
    const api = new APICore();
    // useEffect(() => {
    //     setSelectedTab(profileContextData.selectedModule?.data?.moduleName);
    // }, [profileContextData.selectedModule]);

    const { profileContextData } = useContext(UserProfileContext);

    return (
        <div className={`regular-modules ${profileContextData.expandClientData ? 'data-expanded' : ''}`}>
            <ClientInfo />
            <ModuleTabs />
            <div className={'timeline-row-wrapper not-active-module'}>
                <TimeLine />
            </div>
            {profileContextData.currentRound ? <MainData /> : null}
        </div>
    );
};

export default Mortgage;
