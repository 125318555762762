import React, { useRef, ChangeEvent } from 'react';

interface Props {
    className?: string;
    text: string;
    handleFileUpload: (file: File | undefined) => void;
}

const FileUploadButton = ({ className, text, handleFileUpload }: Props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        fileInputRef.current?.click();
    };

    const onClick = (e: any) => {
        e.stopPropagation();
    };

    const onUpload = (event: ChangeEvent<HTMLInputElement>) => {
        handleFileUpload(event.target.files?.[0]);
        event.target.value = '';
    };

    return (
        <span className={className}>
            <input type="file" ref={fileInputRef} onClick={onClick} style={{ display: 'none' }} onChange={onUpload} />
            <button onClick={handleClick}>
                <i className="mdi mdi-upload font-40"></i>
                {text}
            </button>
        </span>
    );
};

export default FileUploadButton;
