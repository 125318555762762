import { useContext } from 'react';
import InputWrapper from '../../components/inputWrapper/InputWrapper';
import { messageFilterTypes } from '../../interfaces/Chat';
import { chatContext } from '../../context/chatContext';

const FilterWrapper = () => {
    const { setPage, filters, setFilters } = useContext(chatContext);

    const onFilterChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;

        setPage(0);
        setFilters({ ...filters, [name]: value === 'select' ? null : value });
    };

    return (
        <div className="filter-wrapper">
            <InputWrapper status={{ text: '' }} label="" wrapperClass="input-field select-field filter-message">
                <select defaultValue={'select'} className="form-select" name="chatFilter" onChange={onFilterChange}>
                    <option value={'select'}>&#9776; Filter by message type</option>
                    {messageFilterTypes.map((type) => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </InputWrapper>
        </div>
    );
};

export default FilterWrapper;
