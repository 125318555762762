import { useEffect, useState } from 'react';
import { getUsers } from '../utils/api/users';
import { User } from '../interfaces/User';

const useGetUserNameById = () => {
    const [users, setUsers] = useState<User[]>([]);
    useEffect(() => {
        const fetchUsers = async () => {
            const { users } = await getUsers();
            setUsers(users);
        };
        fetchUsers();
    }, []);

    const findUserNameById = (id: string) => {
        const user = users.find((user) => user._id === id);
        return user ? user.firstName + ' ' + user.lastName : '';
    };

    return findUserNameById;
};

export default useGetUserNameById;
