import React from 'react';
import { formatDate } from '../../utils/formatDate';
import { Log } from '../../interfaces/Task';
import { User } from '../../interfaces/User';
import { camelCaseToText } from '../../utils/camelCaseToText';
interface LogHistoryProps {
    changeLog: Log[];
    findUserName: (userId: string | User) => string | undefined;
}
const LogHistory = ({ changeLog, findUserName }: LogHistoryProps) => {
    const parseValue = (value: any, field: string) => {
        if (field === 'dueDate') {
            return formatDate(value);
        }
        if (field === 'user') {
            return findUserName(value);
        }
        return value;
    };

    return (
        <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Field</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                </tr>
            </thead>
            <tbody>
                {changeLog.map((log, index) => (
                    <tr key={index}>
                        <td>{camelCaseToText(log.field)}</td>
                        <td
                            style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {parseValue(log.oldValue, log.field)}
                        </td>
                        <td
                            style={{
                                maxWidth: '200px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {parseValue(log.newValue, log.field)}
                        </td>
                        <td>{findUserName(log.updatedBy)}</td>
                        <td>{formatDate(log.updatedAt)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default LogHistory;
