import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Row, Col, Card, Button, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import './product-transfers.scss';
import { withSwal } from 'react-sweetalert2';
// components

import {
    ProductTransfer,
    ProductTransferMissingDataTypes,
    ProductTransferRegretReasons,
    ProductTransferRejectionReasons,
    TransferStatuses,
} from '../../interfaces/ProductTransfer';
import { PensionProduct, PensionProductTypes } from '../../interfaces/PensionProduct';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../utils/api/ApiError';
import {
    getProductTransfers,
    updateProductTransfer,
    getProductTransferCompanies,
    exportProductTransfers,
    uploadProductTransfers,
    uploadFileToProductTransfer,
    resubmitB2,
} from '../../utils/api/productTransfers';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';
import MoneyColumn from '../../components/MoneyColumn';
import { addCommas } from '../../utils/addCommas';
import { FormInput } from '../../components';
import { APICore } from '../../utils/api/apiCore';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { SignedDocument } from '../../interfaces/SignedDocument';
import GenerateForm from '../../components/generateForm';
import ClientAutocomplete from '../../components/ClientAutocomplete';
import { ClientFile } from '../../interfaces/ClientFile';
import DropDownColumn from '../../components/dropDowncolumn';
import DateColumn from '../../components/DateColumn';
import DatePicker from 'react-datepicker';
import { Pension } from '../../interfaces/Pension';
import { User } from '../../interfaces/User';
import { Company } from '../../interfaces/Company';
import { DateRangePicker } from 'rsuite';
import { DateRange } from 'rsuite/esm/DateRangePicker/types';
import { Fund } from '../../interfaces/Fund';
import Dropzone from 'react-dropzone';
import { FileType } from '../../interfaces/FileType';
import TranslatedColumn from '../../components/TranslatedColumn';
import i18n from 'i18next';
import { getCompanyName } from '../../utils/getCompanyName';
import { formatDate } from '../../utils/formatDate';
import useQueryStringSync from '../../hooks/useQueryStringSync';
import MailingButtonsAndLogic from './MailingButtonsAndLogic';
import ProductTransferFileUpload from './ProductTransferFileUpload';
import { EmploymentStatuses } from '../../interfaces/Client';
import globalErrorHandler from '../../utils/globalErrorHandler';
import AutoSavingInput from '../../components/AutoSavingFormInput';

export interface Item {
    product: PensionProduct;
    signedDocument: SignedDocument;
}

// main component
const ProductTransfers = withSwal((props: any) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [search, setSearch] = useState('');
    const [clientSearch, setClientSearch] = useState(queryParams.get('clientId') || '');
    const { t } = useTranslation('productTransfer');
    const [loading, setLoading] = useState<boolean>(false);
    const [list, setList] = useState<Item[]>([]);
    const [total, setTotal] = useState(0);
    const [totalAgentCommission, setTotalAgentCommission] = useState(0);
    const [totalAnnualDeposit, setTotalAnnualDeposit] = useState(0);
    const [totalSavings, setTotalSavings] = useState(0);
    const [potentialSavings, setPotentialSavings] = useState(0);
    const [totalClients, setTotalClients] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(0);
    const [page, setPage] = useState(0);
    const [companies, setCompanies] = useState([]);
    const [isLoadingExport, setIsLoadingExport] = useState(false);
    const [isLoadingImport, setIsLoadingImport] = useState(false);

    // filters and sorting
    const [status, setStatus] = useState<string>(queryParams.get('status') || '');
    const [toCompany, setToCompany] = useState(queryParams.get('company') || '');
    const [toProduct, setToProduct] = useState(queryParams.get('product') || '');
    const [active, setActive] = useState<string>(queryParams.get('active') || '');
    const [action, setAction] = useState<string>(queryParams.get('action') || '');
    const [lastChangeStartDate, setLastChangeStartDate] = useState<Date | undefined>(
        queryParams.get('lastChangeStartDate') ? new Date(queryParams.get('lastChangeStartDate') as string) : undefined,
    );
    const [lastChangeEndDate, setLastChangeEndDate] = useState<Date | undefined>(
        queryParams.get('lastChangeEndDate') ? new Date(queryParams.get('lastChangeEndDate') as string) : undefined,
    );
    const [submissionStartDate, setSubmissionStartDate] = useState<Date | undefined>(
        queryParams.get('submissionStartDate') ? new Date(queryParams.get('submissionStartDate') as string) : undefined,
    );
    const [agentSignedStartDate, setAgentSignedStartDate] = useState<Date | undefined>(
        queryParams.get('agentSignedStartDate') ? new Date(queryParams.get('agentSignedStartDate') as string) : undefined,
    );
    const [agentSignedEndDate, setAgentSignedEndDate] = useState<Date | undefined>(
        queryParams.get('agentSignedEndDate') ? new Date(queryParams.get('agentSignedEndDate') as string) : undefined,
    );
    const [submissionEndDate, setSubmissionEndDate] = useState<Date | undefined>(
        queryParams.get('submissionEndDate') ? new Date(queryParams.get('submissionEndDate') as string) : undefined,
    );
    const [closeStartDate, setCloseStartDate] = useState<Date | undefined>(
        queryParams.get('closeStartDate') ? new Date(queryParams.get('closeStartDate') as string) : undefined,
    );
    const [closeEndDate, setCloseEndDate] = useState<Date | undefined>(
        queryParams.get('closeEndDate') ? new Date(queryParams.get('closeEndDate') as string) : undefined,
    );
    const [employmentStatus, setEmploymentStatus] = useState<string>(queryParams.get('employmentStatus') || '');
    const [isInvestmentRound, setIsInvestmentRound] = useState<string>(queryParams.get('isInvestmentRound') || '');
    const [sortBy, setSortBy] = useState<string>(queryParams.get('sortBy') || '');
    const [asc, setAsc] = useState<boolean>(queryParams.get('asc') === 'true' || false);

    useQueryStringSync(
        useMemo(
            () => ({
                status,
                toCompany,
                toProduct,
                active,
                action,
                lastChangeStartDate,
                lastChangeEndDate,
                submissionStartDate,
                submissionEndDate,
                agentSignedStartDate,
                agentSignedEndDate,
                closeStartDate,
                closeEndDate,
                employmentStatus,
                isInvestmentRound,
                sortBy,
                asc,
            }),
            [
                status,
                toCompany,
                toProduct,
                active,
                action,
                lastChangeStartDate,
                lastChangeEndDate,
                submissionStartDate,
                submissionEndDate,
                agentSignedStartDate,
                agentSignedEndDate,
                closeStartDate,
                closeEndDate,
                employmentStatus,
                isInvestmentRound,
                sortBy,
                asc,
            ],
        ),
    );

    const { user } = new APICore().getLoggedInUser();

    const statusOptions = Object.entries(TransferStatuses)
        .slice(3)
        .map((item) => ({
            label: t(camelCaseToText(item[0])),
            value: item[1],
        }));

    useEffect(() => {
        document.title = 'Product Transfers';

        return () => {
            document.title = 'Better Admin';
        };
    }, []);

    const onSent = (file: ClientFile) => {
        const tmpList = [...list];
        tmpList.forEach((item: Item) => {
            if (item.signedDocument?.file?._id === file._id) {
                item.signedDocument.file.sentToClientDate = new Date().toDateString();
            }
        });
        setList(tmpList);
    };

    const InvestmentPolicyColumn = ({ row }: { row: any }) => {
        const { product } = row.original;
        const { productTransfer } = product;
        return (
            <>
                {productTransfer.investmentPolicies.map((policy: any, i: number) => (
                    <div>
                        {t(camelCaseToText((policy.fund as Fund).investmentPolicy))}{' '}
                        {policy.percentage !== 1 && `(${(policy.percentage * 100).toFixed(0)}%)`}
                    </div>
                ))}
            </>
        );
    };

    const SigningLinkColumn = ({ row }: { row: any }) => {
        const item = row.original;
        const { productTransfer } = item.product;
        return (
            <div data-test-id="signingLinkColumn">
                <GenerateForm signedDocument={item.signedDocument} productTransfer={productTransfer} onSent={onSent} />
            </div>
        );
    };

    const DepositsColumn = ({ row }: { row: any }) => {
        const { productTransfer } = row.original.product;
        return <div data-test-id="depositsColumn">{productTransfer.commissions.deposit}%</div>;
    };

    const SavingsColumn = ({ row }: { row: any }) => {
        const { productTransfer } = row.original.product;
        return <div data-test-id="savingsColumn">{productTransfer.commissions.savings}%</div>;
    };

    const FromColumn = ({ row }: { row: any }) => {
        const { product } = row.original;
        return (
            <div data-test-id="fromColumn">
                <div>{t(camelCaseToText(product.productType))}</div>
                <div>{getCompanyName(product.company.name)}</div>
                <div>{product.policyNumber}</div>
            </div>
        );
    };

    const ToColumn = ({ row }: { row: any }) => {
        const { product } = row.original;
        const { productTransfer } = product;
        if (productTransfer.action === 'discountOrAgentAssignment') {
            return <div data-test-id="toColumn">---</div>;
        }
        return (
            <div data-test-id="toColumn">
                <div>{t(camelCaseToText(productTransfer.productType))}</div>
                <div>{getCompanyName(productTransfer.company.name)}</div>
                <div>{productTransfer.newPolicyNumber}</div>
            </div>
        );
    };

    const saveProductTransfer = async (productTransfer: ProductTransfer, update: any) => {
        try {
            const result = await updateProductTransfer(productTransfer._id, update);
            const index = list.findIndex((item) => item.product.productTransfer._id === productTransfer._id);
            if (index >= 0) {
                list[index].product.productTransfer = fixDates(result.productTransfer);
                if (result.productTransfer.submissionDate) {
                    list[index].product.productTransfer.submissionDate = new Date(result.productTransfer.submissionDate);
                }

                if (result.productTransfer.closeDate) {
                    list[index].product.productTransfer.closeDate = new Date(result.productTransfer.closeDate);
                }
                setList([...list]);
            }
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    enum YesNo {
        Yes = 'true',
        No = 'false',
    }

    // @ts-ignore
    const DateColumnWithUpdate = ({ productTransfer, list, setList }) => {
        const [isUpdating, setIsUpdating] = useState(false);
        const handleUpdateClick = async () => {
            try {
                setIsUpdating(true);
                await updateProductTransfer(productTransfer._id, {
                    lastChangeDate: true,
                });
                const index = list.findIndex(
                    (item: { product: { productTransfer: { _id: any } } }) => item.product.productTransfer._id === productTransfer._id,
                );
                if (index >= 0) {
                    const newList = [...list];
                    newList[index].product.productTransfer.lastChangeDate = new Date();
                    setList(newList);
                }
                setIsUpdating(false);
            } catch (error) {
                setIsUpdating(false);
                globalErrorHandler(error);
            }
        };

        return (
            <div>
                <div>{formatDate(productTransfer.lastChangeDate, true)}</div>
                <div>
                    <Button onClick={handleUpdateClick} size="sm">
                        {isUpdating ? 'Updating...' : 'Update'}
                    </Button>
                </div>
            </div>
        );
    };

    const ExpandedRow = (props: { row: any }) => {
        const { row } = props;
        const [product, setProduct] = useState<PensionProduct>(row.original.product);
        const [productTransfer, setProductTransfer] = useState<ProductTransfer>(product.productTransfer);
        const [pension, setPension] = useState<Pension | undefined>(product?.pension as Pension);
        const [agent, setAgent] = useState<User | undefined>(pension?.agent as User);
        const [timeout, setTimeout] = useState<number>();
        const [isLoadingSubmitB2, setIsLoadingSubmitB2] = useState(false);
        // const [productTransfer, setProductTransfer] = useState<ProductTransfer>(product.productTransfer);
        const monthlyDepositAmount =
            productTransfer.monthlyDepositAmount || product.newProductDepositData?.monthlyDepositAmount || product.deposits.averageMonthlyDeposit;

        const onDateChange = (date: Date, fieldName: string) => {
            saveProductTransfer({ [fieldName]: date });
        };

        useEffect(() => {
            list[row.index].product.productTransfer = productTransfer;
            setList(list);
        }, [productTransfer]);

        const onResubmitB2 = async () => {
            setIsLoadingSubmitB2(true);
            try {
                const result = await resubmitB2(product._id);
            } catch (err: any) {
                globalErrorHandler(err);
            } finally {
                setIsLoadingSubmitB2(false);
            }
        };

        const saveProductTransfer = async (update: any) => {
            if (!productTransfer) return;

            try {
                setProductTransfer({ ...productTransfer, ...update });

                const result = await updateProductTransfer(productTransfer._id, update);

                const tmpList = [...list];
                const index = tmpList.findIndex((item) => item.product._id === product._id);
                tmpList[index].product.productTransfer.status = result.productTransfer.status;

                // setList(tmpList);

                // setProductTransfer(result.productTransfer);
            } catch (err: any) {
                setProductTransfer(productTransfer);
                globalErrorHandler(err);
            }
        };
        const onTextFieldChanged = (value: string, key: string) => {
            setProductTransfer({ ...productTransfer, [key]: value });
            if (timeout) {
                window.clearTimeout(timeout);
            }
            const tmp = window.setTimeout(() => {
                saveProductTransfer({ [key]: value });
            }, 5000);
            setTimeout(tmp);
        };

        const ProductTransferDropDown = (params: { productTransfer: ProductTransfer; name: string; options: any; placeholder: string }) => {
            const [pt, setPT] = useState<any>(productTransfer);

            useEffect(() => {
                setPT(productTransfer);
            }, [productTransfer]);

            return (
                <Dropdown className="select-action" align={i18n.dir() === 'ltr' ? 'start' : 'end'}>
                    <Dropdown.Toggle variant="light">
                        {pt[params.name] === undefined
                            ? params.placeholder
                            : pt[params.name] === 'true' || pt[params.name] === true
                              ? t('Yes')
                              : pt[params.name] === 'false' || pt[params.name] === false
                                ? t('No')
                                : t(camelCaseToText(pt[params.name]))}
                        <i className="mdi mdi-chevron-down"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu data-test-id="select">
                        {Object.entries(params.options).map(([key, value], i) => (
                            <Dropdown.Item
                                key={key}
                                onClick={() =>
                                    saveProductTransfer({
                                        [params.name]: value === 'true' ? true : value === 'false' ? false : value,
                                    })
                                }
                            >
                                {t(camelCaseToText(key))}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            );
        };

        const getAgentPayments = (): any[] => {
            const oneTime = productTransfer?.commissions?.agentCommissions?.oneTime?.received || [];
            const monthly = productTransfer?.commissions?.agentCommissions?.monthly?.received || [];
            const list = [
                ...oneTime.map((item: any) => ({
                    ...item,
                    type: 'oneTime',
                    date: new Date(item.date),
                })),
                ...monthly.map((item: any) => ({
                    ...item,
                    type: 'monthly',
                    date: new Date(item.date),
                })),
            ];
            list.sort((a, b) => a.date.getTime() - b.date.getTime());
            return list;
        };

        return (
            <>
                <div className="product-transfer-expanded">
                    <div className="data-div">
                        <Row>
                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Monthly deposit')}</span>
                                <div className="category-value">₪{addCommas(monthlyDepositAmount || 0)}</div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Annual deposit')}</span>
                                <div className="category-value">₪{addCommas((monthlyDepositAmount || 0) * 12)}</div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Agent')}</span>
                                <div className="category-value">{agent?.fullName}</div>
                            </Col>

                            <Col xl={3} xs={3} data-test-id="active-col">
                                <span className="category-name">{t('Active')}</span>
                                <div className="category-value">
                                    {t(product.isDeclaredActive ? 'Yes' : 'No')},{' '}
                                    {t(productTransfer.isActive ? 'Pending first deposit' : 'No deposits expected')}
                                </div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Signed on')}</span>
                                <div className="category-value">
                                    {productTransfer.agentSignatureDate ? formatDate(productTransfer.agentSignatureDate) : '-'}
                                </div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Employment status')}</span>
                                <div className="category-value">{t(camelCaseToText(productTransfer.employmentStatus))}</div>
                            </Col>

                            {!!product.client.employer?.name && (
                                <Col xl={3} xs={3}>
                                    <span className="category-name">{t('Employer')}</span>
                                    <div className="category-value">{product.client.employer.name}</div>
                                </Col>
                            )}

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Expiration date')}</span>
                                <div className="category-value">
                                    {productTransfer.expirationDate ? formatDate(productTransfer.expirationDate) : '-'}
                                </div>
                            </Col>

                            {productTransfer.insurance ? (
                                <>
                                    <Col xl={3} xs={3}>
                                        <span className="category-name">{t('Risk insurance')}</span>
                                        <div className="category-value">{productTransfer.insurance.riskPercentage}%</div>
                                    </Col>

                                    <Col xl={3} xs={3}>
                                        <span className="category-name">{t('Give up risk insurance')}</span>
                                        <div className="category-value">{t(product.flags.includes('giveUpSpouseInsurance') ? 'Yes' : 'No')}</div>
                                    </Col>

                                    <Col xl={3} xs={3}>
                                        <span className="category-name">{t('Disability insurance')}</span>
                                        <div className="category-value">{productTransfer.insurance.disabilityPercentage}%</div>
                                    </Col>
                                </>
                            ) : null}

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('New Policy Number')}</span>
                                <div>
                                    <AutoSavingInput
                                        type="text"
                                        containerClass=""
                                        value={productTransfer.newPolicyNumber}
                                        name="productTransfer.newPolicyNumber"
                                        className={'btn btn-light policy-input'}
                                        onSave={(value) => saveProductTransfer({ newPolicyNumber: value })}
                                    />
                                </div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Estimated transfer date')}</span>
                                <DatePicker
                                    name="dueDate"
                                    selected={productTransfer.estimatedTransferDate}
                                    onChange={(date: Date) => onDateChange(date, 'estimatedTransferDate')}
                                    dateFormat={'dd/MM/yyyy'}
                                    placeholderText={t('select date')}
                                    customInput={<input className="btn btn-light date-input" data-test-id="estimatedTransferDateInput" />}
                                />
                                {/*// <span*/}
                                {/*//     className="category-value">*/}
                                {/*//*/}
                                {/*//     {formatDate(productTransfer.estimatedTransferDate)}</span> : null}*/}
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Close date')}</span>
                                <DatePicker
                                    name="dueDate"
                                    className="date"
                                    selected={productTransfer.closeDate}
                                    onChange={(date: Date) => onDateChange(date, 'closeDate')}
                                    dateFormat={'dd/MM/yyyy'}
                                    placeholderText={t('select date')}
                                    customInput={<input className="btn btn-light date-input" />}
                                />
                            </Col>

                            <Col xl={3} xs={3}>
                                <span data-test-id="isRejectedDropDown" className="category-name">
                                    {t('Rejected')}{' '}
                                </span>
                                <ProductTransferDropDown productTransfer={productTransfer} name="isRejected" options={YesNo} placeholder="" />
                            </Col>

                            {productTransfer.isRejected && (
                                <Col xl={3} xs={3}>
                                    <span data-test-id="rejectionReason" className="category-name">
                                        {t('Rejection reason')}
                                    </span>
                                    {/*<div><textarea value={productTransfer.rejectionReason} /></div>*/}
                                    <ProductTransferDropDown
                                        productTransfer={productTransfer}
                                        name="rejectionReason"
                                        options={ProductTransferRejectionReasons}
                                        placeholder={t('Select reason')}
                                    />
                                </Col>
                            )}

                            <Col xl={3} xs={3}>
                                <span data-test-id="missingData" className="category-name">
                                    {t('Missing Data')}
                                </span>
                                <ProductTransferDropDown
                                    productTransfer={productTransfer}
                                    name="missingDataType"
                                    options={ProductTransferMissingDataTypes}
                                    placeholder={t('Select missing data')}
                                />
                            </Col>

                            <Col xl={3} xs={3}>
                                <span data-test-id="missingData" className="category-name">
                                    {t('Missing Data Details')}
                                </span>
                                <div className="missing-data-details">{productTransfer.missingDataDetails}</div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span data-test-id="regret" className="category-name">
                                    {t('Regret')}
                                </span>
                                <ProductTransferDropDown productTransfer={productTransfer} name="isRegret" options={YesNo} placeholder={t('No')} />
                            </Col>

                            {productTransfer.isRegret && (
                                <Col xl={3} xs={3}>
                                    <span data-test-id="regretReason" className="category-name">
                                        {t('Regret reason')}
                                    </span>
                                    <ProductTransferDropDown
                                        productTransfer={productTransfer}
                                        name="regretReason"
                                        options={ProductTransferRegretReasons}
                                        placeholder={t('Select reason')}
                                    />
                                </Col>
                            )}

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Comments')}</span>
                                <div>
                                    <textarea
                                        className="btn btn-light policy-input"
                                        placeholder={t('enter comments')}
                                        value={productTransfer.comments}
                                        onChange={(e: any) => onTextFieldChanged(e.target.value, 'comments')}
                                    />
                                </div>
                            </Col>

                            <Col xl={3} xs={3}>
                                <span className="category-name">{t('Validated fields')}</span>
                                <div>
                                    {!productTransfer.fieldValidation.length && (
                                        <div>
                                            <b>{t('No fields validated')}</b>
                                        </div>
                                    )}
                                    {productTransfer.fieldValidation.map((field: any, i: number) => (
                                        <div>
                                            {t(field.fieldName)}:{' '}
                                            <span className={field.isValid ? 'valid-field' : 'invalid-field'}>
                                                <b>{t(field.isValid ? 'Valid' : 'Invalid')}</b>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </Col>

                            {productTransfer.action === 'discountOrAgentAssignment' && (
                                <Col xl={3} xs={3}>
                                    <span className="category-name">{t('Resend to Mislaka')}</span>
                                    <br />
                                    <LaddaButton
                                        data-test-id="resubmit-b2"
                                        loading={isLoadingSubmitB2}
                                        data-style={EXPAND_LEFT}
                                        className={'ladda-button btn btn-primary grouped-btn negative right '}
                                        dir="ltr"
                                        onClick={() => onResubmitB2()}
                                    >
                                        {!isLoadingSubmitB2 ? <i className="mdi mdi-upload me-1" /> : null}
                                        {t('Send')}
                                    </LaddaButton>
                                </Col>
                            )}

                            {productTransfer.status === TransferStatuses.SignedByAgent && (
                                <Col xl={3} xs={3}>
                                    <ProductTransferFileUpload productTransfer={productTransfer} setList={setList} list={list} />
                                </Col>
                            )}
                        </Row>
                    </div>
                    <div className="table-wrapper">
                        {api.getLoggedInUser().user.isAdmin && (
                            <table className="commissions">
                                <thead>
                                    <tr>
                                        <th>{t('One Time Commission Type')}</th>
                                        <th>{t('Parameter')}</th>
                                        <th>{t('Amount')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productTransfer.commissions.agentCommissions.oneTime.productivity ? (
                                        <tr>
                                            <td>{t('Deposit One Time')}</td>
                                            <td>
                                                {Math.round(productTransfer.commissions.agentCommissions.oneTime.productivityFactor * 10000) / 10000}
                                            </td>
                                            <td>₪{addCommas(productTransfer.commissions.agentCommissions.oneTime.productivity)}</td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>{t('Savings One Time')}</td>
                                        <td>{Math.round(productTransfer.commissions.agentCommissions.oneTime.savingsFactor * 10000) / 10000}</td>
                                        <td>₪{addCommas(productTransfer.commissions.agentCommissions.oneTime.savings)}</td>
                                    </tr>
                                    {productTransfer.commissions.agentCommissions.oneTime.productivity ? (
                                        <tr className="total-onetime">
                                            <td>{t('Total One Time')}</td>
                                            <td></td>
                                            <td>₪{addCommas(productTransfer.commissions.agentCommissions.oneTime.estimatedTotal)}</td>
                                        </tr>
                                    ) : null}
                                    <tr>
                                        <td>{t('Monthly')}</td>
                                        <td>{Math.round(productTransfer.commissions.agentCommissions.monthly.monthlyFactor * 10000) / 10000}</td>
                                        <td>₪{addCommas(productTransfer.commissions.agentCommissions.monthly.estimatedTotal)}</td>
                                    </tr>
                                    <tr>
                                        <td>{t('Expected payment date')}</td>
                                        <td></td>
                                        <td>{formatDate(new Date())}</td>
                                    </tr>
                                    {getAgentPayments().map((payment: any, i: number) => (
                                        <tr>
                                            <td>{formatDate(payment.date)}</td>
                                            {payment.type === 'oneTime' ? <td>₪{addCommas(payment.amount)}</td> : <td></td>}
                                            {payment.type === 'monthly' ? <td>₪{addCommas(payment.amount)}</td> : <td></td>}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const ClientColumn = (params: any) => {
        const { client } = params.row.original.product;
        if (!client) return null;
        return (
            <>
                <span>
                    <span className="client-name" onClick={() => window.open(`/clients/profile?clientId=${client._id}`, '_blank')}>
                        <b>{client?.fullName}</b>
                    </span>
                    <br />
                    <span>{client?.idNumber}</span>
                </span>
                <span className="client-name" onClick={() => window.open(`/product-transfers?clientId=${client._id}`, '_blank')}>
                    {'   '}
                    <i className="mdi mdi-filter me-1" />
                </span>
            </>
        );
    };

    const columns = [
        {
            Header: t('Client'),
            accessor: 'product.client.fullName',
            sort: true,
            classes: 'table-productTransfer',
            Cell: ClientColumn,
        },
        {
            Header: t('Action'),
            accessor: 'product.productTransfer.action',
            sort: true,
            classes: 'table-productTransfer',
            Cell: TranslatedColumn(t),
        },
        {
            Header: t('Status'),
            accessor: 'product.productTransfer.status',
            sort: true,
            classes: 'table-client',
            Cell: DropDownColumn(statusOptions, (status, row) => saveProductTransfer(row.product.productTransfer, { status })),
        },
        {
            Header: t('Last change'),
            accessor: 'product.productTransfer.lastChangeDate',
            sort: true,
            classes: 'table-client',
            // @ts-ignore
            Cell: ({ row }) => <DateColumnWithUpdate productTransfer={row.original.product.productTransfer} list={list} setList={setList} />,
        },
        {
            Header: t('Agent signed date'),
            accessor: 'product.productTransfer.agentSignatureDate',
            sort: true,
            classes: 'table-client',
            Cell: DateColumn,
        },
        {
            Header: t('Submission Date'),
            accessor: 'product.productTransfer.submissionDate',
            sort: true,
            classes: 'table-client',
            Cell: DateColumn,
        },
        {
            Header: t('From'),
            accessor: 'product.productType',
            sort: false,
            classes: 'table-productTransfer',
            Cell: FromColumn,
        },
        {
            Header: t('To'),
            accessor: 'product.productTransfer.productType',
            sort: false,
            classes: 'table-productTransfer',
            Cell: ToColumn,
        },
        {
            Header: t('Deposits'),
            accessor: 'product.productTransfer.commissions.deposit',
            sort: true,
            classes: 'table-productTransfer',
            Cell: DepositsColumn,
        },
        {
            Header: t('Savings'),
            accessor: 'product.productTransfer.commissions.savings',
            sort: true,
            classes: 'table-productTransfer',
            Cell: SavingsColumn,
        },
        {
            Header: t('Total savings'),
            accessor: 'product.totalSavings',
            sort: false,
            classes: 'table-productTransfer',
            Cell: MoneyColumn,
        },
        {
            Header: t('Investment policy'),
            accessor: 'product.productTransfer.investmentPolicies',
            sort: true,
            classes: 'table-productTransfer',
            Cell: InvestmentPolicyColumn,
        },
        {
            Header: t('Estimated transfer date'),
            accessor: 'product.productTransfer.estimatedTransferDate',
            sort: true,
            classes: 'table-client',
            Cell: DateColumn,
        },
        {
            Header: t('Links'),
            accessor: 'product.productTransfer.signEasy',
            sort: false,
            classes: 'table-productTransfer',
            Cell: SigningLinkColumn,
        },
    ];

    const api = new APICore();
    if (!api.canHandleSensitiveData()) {
        columns.splice(columns.length - 1, 1);
    }

    const fixDates = (productTransfer: ProductTransfer) => {
        // debugger;
        if (productTransfer.closeDate) {
            productTransfer.closeDate = new Date(productTransfer.closeDate);
        }
        if (productTransfer.estimatedTransferDate) {
            productTransfer.estimatedTransferDate = new Date(productTransfer.estimatedTransferDate);
        }

        if (productTransfer.submissionDate) {
            console.log('productTransfer.submissionDate', productTransfer.submissionDate);
            productTransfer.submissionDate = new Date(productTransfer.submissionDate);
        }

        if (productTransfer.agentSignatureDate) {
            productTransfer.agentSignatureDate = new Date(productTransfer.agentSignatureDate);
        }
        return productTransfer;
    };

    const handleProductTransfers = async (page: number) => {
        setPage(page);

        const params = {
            page,
            search,
            companyId: toCompany,
            productType: toProduct,
            isActive: active,
            action,
            status: status,
            clientId: clientSearch,
            fromSubmissionDate: submissionStartDate?.toISOString(),
            toSubmissionDate: submissionEndDate?.toISOString(),
            fromLastChangeDate: lastChangeStartDate?.toISOString(),
            toLastChangeDate: lastChangeEndDate?.toISOString(),
            fromAgentSignatureDate: agentSignedStartDate?.toISOString(),
            toAgentSignatureDate: agentSignedEndDate?.toISOString(),
            fromCloseDate: closeStartDate?.toISOString(),
            toCloseDate: closeEndDate?.toISOString(),
            employmentStatus,
            isInvestmentRound,
            sortBy,
            asc,
        };
        if (params.sortBy === 'fullName') {
            params.sortBy = 'client';
        }

        try {
            setLoading(true);
            const { list, total, itemsPerPage, totalAgentCommission, totalAnnualDeposit, potentialSavings, totalSavings, totalClients } =
                await getProductTransfers(params);

            list.forEach((item: any) => fixDates(item.product.productTransfer));

            setList(list);
            setTotal(total || list.length);
            setTotalAgentCommission(totalAgentCommission);
            setTotalAnnualDeposit(totalAnnualDeposit);
            setTotalSavings(totalSavings);
            setPotentialSavings(potentialSavings);
            setTotalClients(totalClients);
            setItemsPerPage(itemsPerPage);

            if (!companies.length) {
                const result = await getProductTransferCompanies();
                setCompanies(result.companies);
            }

            if (queryParams.get('clientId') && !clientSearch) {
                setClientSearch(list[0]?.product?.client?.fullName);
            }

            setLoading(false);
        } catch (err: any) {
            setLoading(false);

            if (err.status === 401) return navigate('/auth/logout');

            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        // navigate('/product-transfers')
        handleProductTransfers(0);
    }, [
        clientSearch,
        status,
        toCompany,
        toProduct,
        search,
        active,
        action,
        submissionStartDate,
        submissionEndDate,
        lastChangeStartDate,
        lastChangeEndDate,
        closeStartDate,
        closeEndDate,
        agentSignedStartDate,
        agentSignedEndDate,
        employmentStatus,
        isInvestmentRound,
        sortBy,
        asc,
    ]);

    const handleFreeSearch = async (search: string) => {
        if (search === '') {
            setClientSearch('');
        }
        setStatus('');
        setSearch(search);
    };

    const onStatusFilterSelected = (e: any) => {
        const status = e.target.value;
        if (status) setStatus(status);
        else setStatus('');
    };

    const onToProductFilterSelected = (e: any) => {
        const toProduct = e.target.value;
        if (toProduct) setToProduct(toProduct);
        else setToProduct('');
    };
    const onToCompanyFilterSelected = (e: any) => {
        const toCompany = e.target.value;
        if (toCompany) setToCompany(toCompany);
        else setToCompany('');
    };

    const onActiveFilterSelected = (e: any) => {
        const isActive = e.target.value;
        setActive(isActive);
    };

    const onActionFilterSelected = (e: any) => {
        const action = e.target.value;
        setAction(action);
    };

    const onAutocompleteSelect = (value: any) => {
        setStatus('');
        setClientSearch(value);
    };

    const onExport = async () => {
        setIsLoadingExport(true);

        try {
            const result = await exportProductTransfers({
                search,
                companyId: toCompany,
                productType: toProduct,
                isActive: active,
                action,
                status,
                clientId: clientSearch,
                fromSubmissionDate: submissionStartDate?.toISOString(),
                toSubmissionDate: submissionEndDate?.toISOString(),
                fromLastChangeDate: lastChangeStartDate?.toISOString(),
                toLastChangeDate: lastChangeEndDate?.toISOString(),
                fromAgentSignatureDate: agentSignedStartDate?.toISOString(),
                toAgentSignatureDate: agentSignedEndDate?.toISOString(),
                fromCloseDate: closeStartDate?.toISOString(),
                toCloseDate: closeEndDate?.toISOString(),
                employmentStatus,
            });
            const blob = await result.blob();
            const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `product-transfers.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
        } catch (err) {
            globalErrorHandler(err);
        } finally {
            setIsLoadingExport(false);
        }
    };

    const onImport = async (files: FileType[]) => {
        setIsLoadingImport(true);

        try {
            const result = await uploadProductTransfers({ file: files[0] });
        } catch (err) {
            globalErrorHandler(err);
        } finally {
            setIsLoadingImport(false);
        }
    };
    const onSortChanged = (sortBy: string) => {
        const ascending = !sortBy.startsWith('-');
        const fieldName = sortBy.split('.').pop();
        if (fieldName) {
            setSortBy(fieldName);
            setAsc(ascending);
        }
        console.log(fieldName);
    };

    const [isUserPickingProducts, setIsUserPickingProducts] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    const onSuccessfulMail = (successfulSentStatuses: string[]) => {
        setIsUserPickingProducts(false);
        const updatedList = [...list];
        updatedList.forEach((item) => {
            if (successfulSentStatuses.includes(item.product.productTransfer._id)) {
                item.product.productTransfer.status = TransferStatuses.ProcessingAtCompany;
            }
        });
        setList(updatedList);
        setSelectedProducts([]);
    };

    const onRowClicked = (row: any) => {
        const { product } = row.original;
        const { productTransfer } = product;
        console.log(productTransfer._id, productTransfer);
        if (!isUserPickingProducts || productTransfer.status !== TransferStatuses.SignedByAgent) {
            return;
        }
        const index = selectedProducts.findIndex((id: string) => id === productTransfer._id);
        if (index >= 0) {
            selectedProducts.splice(index, 1);
        } else {
            selectedProducts.push(productTransfer._id);
        }
        setSelectedProducts([...selectedProducts]);
    };

    const rowStyle = (row: any) => {
        const { product } = row.original;
        const { productTransfer } = product;
        const syles: any = {};
        if (isUserPickingProducts && productTransfer.status === TransferStatuses.SignedByAgent) {
            syles.cursor = 'pointer';
        }
        if (selectedProducts.includes(productTransfer._id)) {
            syles.backgroundColor = '#e6f7ff';
        }
        return syles;
    };

    return (
        <React.Fragment>
            <div className="product-transfers">
                <PageTitle
                    breadCrumbItems={[
                        {
                            label: t('Product Transfers'),
                            path: '/productTransfers',
                            active: true,
                        },
                    ]}
                    title={t('Product Transfers')}
                />

                <Row className={i18n.dir()}>
                    <Col xl={12} lg={8}>
                        <Card>
                            <Card.Body>
                                {loading && <Loader />}

                                <Row className="mb-2">
                                    <Col md="3">
                                        <div className="form-label">{t('Search')}</div>
                                        <div className="search-bar form-inline">
                                            <ClientAutocomplete
                                                handleFreeSearch={handleFreeSearch}
                                                onAutocompleteSelect={onAutocompleteSelect}
                                                // defaultValue={queryParams.get('clientFullName') || ''}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="auto">
                                        <FormInput
                                            label={t('Status')}
                                            type="select"
                                            name="task-status"
                                            placeholder={t('Status')}
                                            containerClass={'mb-3'}
                                            value={status}
                                            options={[{ label: t('All'), value: '' }].concat(statusOptions)}
                                            onSelected={onStatusFilterSelected}
                                            // onInputChange={onInputChange}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <div className="form-label">{t('Last change date')}</div>
                                        <DateRangePicker
                                            placement="auto"
                                            format="dd/MM/yyyy"
                                            placeholder={t('Last change')}
                                            defaultValue={
                                                lastChangeStartDate && lastChangeEndDate ? [lastChangeStartDate, lastChangeEndDate] : undefined
                                            }
                                            onOk={(date: DateRange) => {
                                                setLastChangeStartDate(date[0]);
                                                setLastChangeEndDate(date[1]);
                                            }}
                                            onClean={(event: React.MouseEvent) => {
                                                setLastChangeStartDate(undefined);
                                                setLastChangeEndDate(undefined);
                                            }}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <div className="form-label">{t('Submission date')}</div>
                                        <DateRangePicker
                                            placement="auto"
                                            data-test-id="submission-date-range-picker"
                                            format="dd/MM/yyyy"
                                            placeholder={t('Submission date')}
                                            defaultValue={
                                                submissionStartDate && submissionEndDate ? [submissionStartDate, submissionEndDate] : undefined
                                            }
                                            onOk={(date: DateRange) => {
                                                setSubmissionStartDate(date[0]);
                                                setSubmissionEndDate(date[1]);
                                            }}
                                            onClean={(event: React.MouseEvent) => {
                                                setSubmissionStartDate(undefined);
                                                setSubmissionEndDate(undefined);
                                            }}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <div className="form-label">{t('Agent signed date')}</div>
                                        <DateRangePicker
                                            placement="auto"
                                            data-test-id="client-signed-date-range-picker"
                                            format="dd/MM/yyyy"
                                            placeholder={t('Agent signed date')}
                                            defaultValue={
                                                agentSignedStartDate && agentSignedEndDate ? [agentSignedStartDate, agentSignedEndDate] : undefined
                                            }
                                            onOk={(date: DateRange) => {
                                                setAgentSignedStartDate(date[0]);
                                                setAgentSignedEndDate(date[1]);
                                            }}
                                            onClean={(event: React.MouseEvent) => {
                                                setAgentSignedStartDate(undefined);
                                                setAgentSignedEndDate(undefined);
                                            }}
                                        />
                                    </Col>

                                    <Col md="auto">
                                        <div className="form-label">{t('Close date')}</div>
                                        <DateRangePicker
                                            placement="auto"
                                            data-test-id="close-date-range-picker"
                                            format="dd/MM/yyyy"
                                            placeholder={t('Close date')}
                                            defaultValue={closeStartDate && closeEndDate ? [closeStartDate, closeEndDate] : undefined}
                                            onOk={(date: DateRange) => {
                                                setCloseStartDate(date[0]);
                                                setCloseEndDate(date[1]);
                                            }}
                                            onClean={(event: React.MouseEvent) => {
                                                setCloseStartDate(undefined);
                                                setCloseEndDate(undefined);
                                            }}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <FormInput
                                            data-test-id="search-new-insurance-company"
                                            label={t('To company')}
                                            type="select"
                                            name="task-status"
                                            containerClass={'mb-3'}
                                            options={[{ label: t('All'), value: '' }].concat(
                                                companies.map((c: Company) => ({
                                                    label: c.name,
                                                    value: c._id,
                                                })),
                                            )}
                                            onSelected={onToCompanyFilterSelected}
                                            // onInputChange={onInputChange}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <FormInput
                                            data-test-id="search-new-insurance-product"
                                            label={t('Product type')}
                                            type="select"
                                            name="task-status"
                                            containerClass={'mb-3'}
                                            options={[{ label: t('All'), value: '' }].concat(
                                                Object.entries(PensionProductTypes).map((items: string[]) => ({
                                                    label: t(camelCaseToText(items[0])),
                                                    value: items[1],
                                                })),
                                            )}
                                            onSelected={onToProductFilterSelected}
                                            // onInputChange={onInputChange}
                                        />
                                    </Col>

                                    <Col md="auto">
                                        <FormInput
                                            data-test-id="search-active-by-deposits"
                                            label={t('Action non active')}
                                            type="select"
                                            name="task-status"
                                            placeholder={t('Active')}
                                            containerClass={'mb-3'}
                                            options={[
                                                { label: t('All'), value: '' },
                                                { label: t('Active'), value: 'true' },
                                                { label: t('Inactive'), value: 'false' },
                                            ]}
                                            onSelected={onActiveFilterSelected}
                                        />
                                    </Col>

                                    <Col md="auto">
                                        <FormInput
                                            data-test-id="search-active-by-deposits"
                                            label={t('Action')}
                                            type="select"
                                            name="task-status"
                                            placeholder={t('All')}
                                            containerClass={'mb-3'}
                                            options={[
                                                { label: t('All'), value: '' },
                                                { label: t('transfer'), value: 'transfer' },
                                                { label: t('b2'), value: 'discountOrAgentAssignment' },
                                                { label: t('newProduct'), value: 'newProduct' },
                                            ]}
                                            onSelected={onActionFilterSelected}
                                        />
                                    </Col>

                                    <Col md="auto">
                                        <FormInput
                                            label={t('Employment status')}
                                            type="select"
                                            name="task-status"
                                            containerClass={'mb-3'}
                                            options={[{ label: t('All'), value: '' }].concat(
                                                EmploymentStatuses.map((status) => ({
                                                    label: t(camelCaseToText(status)),
                                                    value: status,
                                                })),
                                            )}
                                            onSelected={(e: any) => setEmploymentStatus(e.target.value)}
                                        />
                                    </Col>

                                    <Col md="auto">
                                        <FormInput
                                            label={t('Pension/Investment')}
                                            type="select"
                                            name="isInvestmentRound"
                                            containerClass={'mb-3'}
                                            options={[
                                                { label: t('All'), value: '' },
                                                { label: t('Investments'), value: 'true' },
                                                { label: t('Pension'), value: 'false' },
                                            ]}
                                            onSelected={(e: any) => setIsInvestmentRound(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col>
                                        <Dropzone
                                            maxFiles={1}
                                            // onFileUpload={onFileUpload}
                                            onDrop={(acceptedFiles) => onImport(acceptedFiles)}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div className={'upload-btn end'}>
                                                    <div className="dz-message" {...getRootProps()}>
                                                        <LaddaButton
                                                            data-test-id="import-product-transfers"
                                                            loading={isLoadingImport}
                                                            data-style={EXPAND_LEFT}
                                                            className="btn btn-primary grouped-btn negative right"
                                                            data-spinner-color="#4d78cd"
                                                            dir="ltr"
                                                        >
                                                            {!isLoadingImport ? <i className="mdi mdi-cloud-upload-outline me-1" /> : null}
                                                            {t('Import')}
                                                        </LaddaButton>
                                                    </div>
                                                    <div className="needsclick" {...getRootProps()}>
                                                        <input {...getInputProps()} />
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>

                                        <LaddaButton
                                            data-test-id="export-product-transfers"
                                            loading={isLoadingExport}
                                            data-style={EXPAND_LEFT}
                                            className={'btn btn-primary grouped-btn end'}
                                            dir="ltr"
                                            onClick={() => onExport()}
                                        >
                                            {!isLoadingExport ? <i className="mdi mdi-download me-1" /> : null}
                                            {t('Export')}
                                        </LaddaButton>

                                        <MailingButtonsAndLogic
                                            selectedProducts={selectedProducts}
                                            setSelectedProducts={setSelectedProducts}
                                            setIsUserPickingProducts={setIsUserPickingProducts}
                                            isUserPickingProducts={isUserPickingProducts}
                                            setLoading={setLoading}
                                            setStatus={setStatus}
                                            allowedStatus={TransferStatuses.SignedByAgent}
                                            onSuccessfulMail={onSuccessfulMail}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {t('Total products')}: {total}
                                    </Col>
                                    <Col>
                                        {t('Total clients')}: {totalClients}
                                    </Col>

                                    <Col>
                                        {t('Potential savings')}: ₪{addCommas(potentialSavings)}
                                    </Col>
                                    <Col>
                                        {t('Total savings')}: ₪{addCommas(totalSavings)}
                                    </Col>
                                    <Col>
                                        {t('Total annual deposit')}: ₪{addCommas(totalAnnualDeposit)}
                                    </Col>

                                    {user.isAdmin && (
                                        <Col>
                                            {t('Total agent commission')}: ₪{addCommas(totalAgentCommission)}
                                        </Col>
                                    )}
                                </Row>
                                {itemsPerPage ? (
                                    <Table
                                        columns={columns}
                                        paginationProps={{
                                            onPageSelected: handleProductTransfers,
                                            totalItems: total,
                                            numberOfPages: Math.ceil(total / itemsPerPage),
                                        }}
                                        isExpandable={true}
                                        // onRowClicked={(row) => oneEditProductTransfer(row.original.productTransfer)}
                                        data={list}
                                        onRowClicked={onRowClicked}
                                        rowStyle={rowStyle}
                                        pageSize={itemsPerPage}
                                        isSortable={true}
                                        ExpandedRow={ExpandedRow}
                                        pagination={true}
                                        isSelectable={false}
                                        tableClass="table-nowrap table-productTransfers"
                                        onSortChanged={onSortChanged}
                                        rowEqualityFn={(idOfCurrent: string, otherRow: any) => idOfCurrent === otherRow.product?.productTransfer._id}
                                    />
                                ) : null}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* add customer modal */}
                {/*<AddProductTransfer show={showAddProductTransfer} onHide={onCloseAddProductTransferModal} onAddProductTransfer={onAddProductTransfer} productTransfer={selectedProductTransfer}/>*/}
            </div>
        </React.Fragment>
    );
});

export default ProductTransfers;
