import { APICore, INSURANCE_URL } from './apiCore';
import { InsuranceAppendix } from '../../interfaces/InsuranceAppendix';

const api = new APICore();

export const getInsuranceAppendices = (params: { clientId?: string }) => {
    const relativeUrl = '/appendix';
    return api.get(`${INSURANCE_URL}${relativeUrl}`, params);
};

export const createInsuranceAppendix = (newInsuranceAppendix: InsuranceAppendix) => {
    const relativeUrl = '/appendix';
    return api.create(`${INSURANCE_URL}${relativeUrl}`, newInsuranceAppendix);
};

export const editInsuranceAppendix = (id: string, appendix: any) => {
    const relativeUrl = '/appendix/' + id;
    return api.update(`${INSURANCE_URL}${relativeUrl}`, appendix);
};

export const deleteInsuranceAppendix = (id: string) => {
    const relativeUrl = '/appendix/' + id;
    return api.delete(`${INSURANCE_URL}${relativeUrl}`);
};

export const getAppendicesFile = (params: {
    clientId?: string;
    policyId?: string;
    firstBranch?: string;
    secondBranch?: string;
    recommendation?: string;
}) => {
    const relativeUrl = '/appendix/download';
    return api.getFile(`${INSURANCE_URL}${relativeUrl}`, params);
};
