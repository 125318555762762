export interface BankDeposit {
    client: string;
    createdAt: string;
    files: string[];
    isActive: boolean;
    progressPercentage: number;
    rep: string;
    status: DepositStatuses;
    updatedAt: string;
    __v: number;
    _id: string;
    depositCloseReason: DepositCloseReasons;
    depositCloseDate: Date;
    maturityDate: Date;
    interest: number;
    depositAmount: number;
    depositType: string;
    endTime: Date;
    lastStatusChangeDate: Date | string;
}

export enum DepositStatuses {
    initial = 'initial',
    offerSent = 'offerSent',
    clientIsInterestedInCheckingWithHisBank = 'clientIsInterestedInCheckingWithHisBank',
    clientDidntRespondToTheFirstOffer = 'clientDidntRespondToTheFirstOffer',
    clientDidntRespondToAmountQuestion = 'clientDidntRespondToAmountQuestion',
    clientDidntRespondToSecondOffer = 'clientDidntRespondToSecondOffer',
    clientDidntRespondToNegotiationOffer = 'clientDidntRespondToNegotiationOffer',
    clientWantsToThinkAboutIt = 'clientWantsToThinkAboutIt',
    closeLost = 'closeLost',
    closeWon = 'closeWon',
}
export enum DepositCloseReasons {
    noResponseToTheOffer = 'noResponseToTheOffer',
    clientNotInterestedInOffer = 'clientNotInterestedInOffer',
    clientPrefersInvestments = 'clientPrefersInvestments',
    clientClosedDepositThroughHisBank = 'clientClosedDepositThroughHisBank',
}
