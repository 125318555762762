import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import useCreateAndEditTask from '../tasks/hooks/useCreateAndEditTask';
import { getTasks, updateTask } from '../../utils/api/tasks';
import { Task } from '../../interfaces/Task';
import Table from '../../components/Table';
import Loader from '../../components/Loader';
import DropDownColumn from '../../components/dropDowncolumn';
import UncamelCaseColumn from '../../components/UncamelCaseColumn';
import DateTimeColumn from '../../components/DateTimeColumn';
import LinkColumn from '../../components/LinkColumn';
import { FormInput } from '../../components';
import { UserProfileContext } from '../../context/userProfileContext';
import CreateTask from '../tasks/CreateTask';
import globalErrorHandler from '../../utils/globalErrorHandler';

// Task List
const Tasks = () => {
    const { users, profileContextData } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const [tasks, setTasks] = useState<Task[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [status, setStatus] = useState('open');
    const [showFutureTasks, setShowFutureTasks] = useState<boolean>(false);
    const { showCreateAndEditTaskModal, onTaskClick, onCreateTaskClick, onCloseCreateAndEditTaskModal, onSubmit, selectedTask } =
        useCreateAndEditTask({ tasks, setTasks, users });

    const handleTasks = async (page: number) => {
        try {
            setLoading(true);
            const { tasks } = await getTasks({
                page,
                clientId: client._id,
                status,
                showFutureTasks,
            });
            setTasks(tasks);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        handleTasks(0);
    }, [status, showFutureTasks]);

    const onTaskStatusSelected = async (value: string, task: Task) => {
        const newTask = { ...task };
        newTask.status = value;
        try {
            await updateTask({ taskId: task._id, status: value });
            const index = tasks.findIndex((t: Task) => t._id === task._id);
            const newTasks = [...tasks];
            newTasks[index] = newTask;
            setTasks(newTasks);
        } catch (err) {
            globalErrorHandler(err);
        }
    };

    const columns = [
        {
            Header: 'Task',
            accessor: 'text',
            sort: true,
            classes: 'table-client',
            Cell: UncamelCaseColumn,
        },
        {
            Header: 'User',
            accessor: 'user.fullName',
            sort: true,
            classes: 'table-client',
        },
        {
            Header: 'Module',
            accessor: 'module',
            sort: true,
            classes: 'table-client',
        },
        {
            Header: 'Created',
            accessor: 'createdAt',
            sort: true,
            Cell: DateTimeColumn,
        },
        {
            Header: 'Due Date',
            accessor: 'dueDate',
            sort: true,
            Cell: DateTimeColumn,
        },
        {
            Header: 'Link',
            accessor: 'link',
            sort: true,
            Cell: LinkColumn(),
            className: 'table-link',
        },
        {
            Header: 'Status',
            accessor: 'status',
            sort: true,
            classes: 'table-client',
            Cell: DropDownColumn(
                [
                    { value: 'open', label: 'open' },
                    {
                        value: 'completed',
                        label: 'completed',
                    },
                ],
                onTaskStatusSelected,
            ),
        },
    ];

    const onStatusSelected = (e: any) => {
        const status = e.target.value;
        if (status) setStatus(status);
        else setStatus('');
    };

    const onShowFutureTasksChanged = (e: any) => {
        setShowFutureTasks(e.target.checked);
    };

    const onRowClicked = (row: any) => {
        onTaskClick(row.original);
    };

    return (
        <div className="tasks">
            <CreateTask
                show={showCreateAndEditTaskModal}
                onHide={onCloseCreateAndEditTaskModal}
                onSubmit={onSubmit}
                task={selectedTask}
                changeLog={selectedTask?.changeLog}
                users={users}
            />
            <Row>
                <Col xl={2}>
                    <h4>Tasks</h4>
                </Col>
            </Row>
            <Row className="actions-and-filters">
                <Col xl={2}>
                    <FormInput
                        label="Status"
                        type="select"
                        name="task-status"
                        placeholder="Status"
                        containerClass={'mb-3'}
                        options={[
                            { label: 'Open', value: 'open' },
                            {
                                label: 'Completed',
                                value: 'completed',
                            },
                            { label: 'All', value: '' },
                        ]}
                        onSelected={onStatusSelected}
                        // onInputChange={onInputChange}
                    />
                </Col>
                <Col xl={3}>
                    <FormInput
                        label="Show future tasks"
                        type="checkbox"
                        name="task-type"
                        placeholder="Type"
                        containerClass={'mb-3'}
                        onClick={onShowFutureTasksChanged}
                    />
                </Col>
                <Col xl={3}>
                    <Button variant="primary " className="waves-effect waves-light new-task" onClick={onCreateTaskClick}>
                        <i className="mdi mdi-plus-circle me-1"></i> Create task
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col xl={12}>
                    <div className="tasks-table">
                        {loading && <Loader />}

                        {tasks.length ? (
                            <Table
                                columns={columns}
                                data={tasks}
                                isSelectable={false}
                                pageSize={100}
                                isSortable={true}
                                pagination={true}
                                tableClass="table-nowrap"
                                onRowClicked={onRowClicked}
                            />
                        ) : (
                            <div>No tasks found</div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Tasks;
