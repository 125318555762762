import React from 'react';
import { APICore } from './api/apiCore';
import globalErrorHandler from './globalErrorHandler';

const fileDownload = async (url: string, isAws: boolean, fileName: string, mimeType: string) => {
    const ApiCore = new APICore();

    let blob;
    if (!isAws) {
        try {
            const result = await ApiCore.getFile(url);
            blob = await result.blob();
            url = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));
        } catch (err) {
            globalErrorHandler(err);
            return;
        }
    }

    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', fileName || `${Date.now()}`);
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.parentNode?.removeChild(link);
};

export { fileDownload };
