import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorData } from '../../../../../interfaces/ErrorData';
import globalErrorHandler from '../../../../../utils/globalErrorHandler';

const useErrorHandler = () => {
    const { t } = useTranslation('taxReturn');
    const navigate = useNavigate();
    const location = useLocation();

    const navigateToFileViewPage = (errorData: ErrorData[]) => {
        const { fileId } = errorData[0];
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('fileId', fileId);
        const newUrl = `${location.pathname}?${searchParams.toString()}`;
        navigate(newUrl, { state: errorData });
        window.location.href = newUrl.toString();
    };

    const handleErrorCases = (error: any) => {
        if (error?.status === 411 || error?.status === 410) {
            let errorDetails = error?.extraData;
            if (!Array.isArray(errorDetails) && errorDetails?.files?.length) {
                errorDetails = errorDetails.files.map((fileId: string) => ({ fileId }));
            }
            if (errorDetails?.length) {
                const userResponse = window.confirm(t('There are some problematic files. Would you want to view them?'));
                if (userResponse) {
                    navigateToFileViewPage(errorDetails);
                }
                return;
            } else {
                globalErrorHandler(error);
            }
        } else {
            globalErrorHandler(error);
        }
    };

    return { handleErrorCases };
};

export default useErrorHandler;

const isErrorDataValid = (errorData: ErrorData[]) => {
    debugger;
    return errorData && errorData.length > 0 && errorData.some((error) => error.fields?.length && error.fileId) && errorData[0].fileId;
};
