import React from 'react';

const ArrayColumn = (params: any) => {
    if (!params.value) return <></>;
    return (
        <>
            {params.value.map((line: any, index: number) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </>
    );
};

export default ArrayColumn;
