import React, { useState, InputHTMLAttributes, useEffect, ChangeEvent } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';

import { FieldErrors, Control } from 'react-hook-form';
import { Option } from '../interfaces/Option';
import FormInput from './FormInput';

interface AutoSavingInputProps {
    label?: string;
    name: string;
    type: string;
    placeholder?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
    className?: string;
    value: string | number;
    containerClass: string;
    min?: number;
    max?: number;
    style?: React.CSSProperties;
    direction?: 'rtl' | 'ltr';
    onSave: (value: string) => Promise<void>;
}

/* Password Input */
const AutoSavingInput = ({
    type,
    name,
    containerClass,
    style,
    value,
    placeholder,
    onChange,
    className,
    direction = 'rtl',
    onSave,
    ...otherProps
}: AutoSavingInputProps) => {
    const [inputValue, setInputValue] = useState<string | number>(value);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const MIN_LOADING_TIME = 500;

    const defaultWrapperStyle: React.CSSProperties = {
        position: 'relative',
    };

    const defaultSpinnerStyle: React.CSSProperties = {
        position: 'absolute',
        bottom: '8px',
        right: '10px',
        width: '20px',
        height: '20px',
    };

    useEffect(() => {
        // Clear the timeout when the component unmounts to prevent memory leaks
        return () => {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
        };
    }, [typingTimeout]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setInputValue(value);

        // Clear the previous timeout if the user types again
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        // Set a new timeout to call onSave after 5 seconds
        const timeout = setTimeout(async () => {
            const t1 = new Date().getTime();
            setIsSaving(true);

            await onSave(value);
            const delta = new Date().getTime() - t1;
            const extraTime = delta < MIN_LOADING_TIME ? MIN_LOADING_TIME - delta : 0;
            setTimeout(() => setIsSaving(false), extraTime);
        }, 2500);

        setTypingTimeout(timeout);
    };

    return (
        <div className="wrapper" style={defaultWrapperStyle}>
            <FormInput
                type={type}
                name={name}
                containerClass={containerClass}
                placeholder={placeholder}
                onChange={handleInputChange}
                className={className}
                value={inputValue}
                style={style}
                {...otherProps}
            />
            {isSaving && <div className="spinner" style={defaultSpinnerStyle} />}
        </div>
    );
};

export default AutoSavingInput;
