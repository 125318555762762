import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Donations } from '../../../../../interfaces/TaxData';
import BorderWithText from '../../../../../components/BorderWithText';

interface DonationsFormProps {
    data: Donations;
    prefix: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DonationsForm = ({ data, prefix, onChange }: DonationsFormProps) => {
    const { t } = useTranslation('taxReturn');

    return (
        <BorderWithText label={t('Donations')}>
            <div className="DonationsForm">
                <Form.Group controlId="TotalDonationAmount">
                    <Form.Label>{t('Total Donation Amount')}</Form.Label>
                    <Form.Control type="number" name={`${prefix}.totalSum`} value={data.totalSum} onChange={onChange} />
                </Form.Group>
            </div>
        </BorderWithText>
    );
};

export default DonationsForm;
