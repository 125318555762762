import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { Follower } from '../../interfaces/Follower';
import { Client } from '../../interfaces/Client';
import globalErrorHandler from '../../utils/globalErrorHandler';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FollowersDropDownProps {
    followers: Follower[];
    setFollowers: (followers: Follower[]) => void;
    client: Client;
    followClient: (action: string, clientId: string, followerId: string) => void;
    pokeUser: (userId: string) => any;
    isFollowerPokable: (follower: Follower) => boolean;
    canUserPoke: boolean;
}

const FollowersDropDown = ({ followers, setFollowers, client, followClient, pokeUser, isFollowerPokable, canUserPoke }: FollowersDropDownProps) => {
    const { t } = useTranslation('chat');
    const [searchTerm, setSearchTerm] = useState('');
    const [pokedUsers, setPokedUsers] = useState<string[]>([]);
    const [loadingUser, setLoadingUser] = useState<string | null>(null);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handlePoke = async (e: any, userId: string) => {
        e.stopPropagation();
        setLoadingUser(userId);
        const result = await pokeUser(userId);
        setLoadingUser(null);
        if (result && result.success) {
            setPokedUsers([...pokedUsers, userId]);
        }
    };

    const getDropdownItem = (follower: Follower, key: number) => {
        const getBadge = () => {
            if (follower.isActiveRep)
                return (
                    <span className="badge bg-info" key={key}>
                        {t('Active')}
                    </span>
                );
            else if (follower.isInvitee)
                return (
                    <span className="badge bg-success" key={key}>
                        {t('Invited')}
                    </span>
                );
            else if (follower.isWatcher)
                return (
                    <span className="badge bg-success" key={key}>
                        {t('Following')}
                    </span>
                );
            else
                return (
                    <span className="badge bg-add" key={key}>
                        {t('Add')}
                    </span>
                );
        };

        const getButton = () => {
            if (pokedUsers.includes(follower.user._id)) {
                return (
                    <Tooltip title={t('Poked')}>
                        <i className="mdi mdi-check-circle"></i>
                    </Tooltip>
                );
            } else if (loadingUser === follower.user._id) {
                return (
                    <Tooltip title={t('Poking...')}>
                        <i className="mdi mdi-loading mdi-spin"></i>
                    </Tooltip>
                );
            } else if (isFollowerPokable(follower)) {
                return (
                    <Tooltip title={t('Poke')}>
                        <i className="mdi mdi-hand-pointing-right" onClick={(e) => handlePoke(e, follower.user._id)}></i>
                    </Tooltip>
                );
            }
        };

        return (
            <Dropdown.Item
                key={follower.user._id}
                onClick={() => followUnFollow(follower)}
                className="d-flex justify-content-between align-items-center"
            >
                <span>
                    {getBadge()}
                    {t(follower.user.fullName || '')}{' '}
                </span>
                {canUserPoke && getButton()}
            </Dropdown.Item>
        );
    };

    const followUnFollow = async (follower: Follower) => {
        setSearchTerm('');
        let follow;
        if (follower.isActiveRep) return;
        else if (follower.isInvitee) follow = false;
        else if (follower.isWatcher) follow = false;
        else follow = true;

        if (!follow) {
            if (follower.isInvitee) follower.isInvitee = false;
            if (follower.isWatcher) follower.isWatcher = false;
        } else {
            follower.isInvitee = true;
        }

        const list = [...followers];
        list[followers.indexOf(follower)] = follower;
        setFollowers(list);

        try {
            await followClient(follow ? 'follow' : 'unFollow', client._id, follower.user._id);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const getNumOfFollowers = () => followers.filter((f) => f.isActiveRep || f.isInvitee || f.isWatcher).length;

    const filteredFollowers = followers.filter((follower) => follower.user.fullName?.toLowerCase().includes(searchTerm.toLowerCase()));

    return (
        <div className="followers">
            <Dropdown className="select-action" align={'end'}>
                <Dropdown.Toggle as="a" className="btn-sm card-drop cursor-pointer">
                    <span>
                        {getNumOfFollowers()} {getNumOfFollowers() > 1 ? t('Followers') : t('Follower')}
                        <i className="mdi mdi-chevron-down font-40"></i>
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <TextField
                        label={t('Search Followers')}
                        onChange={handleSearchChange}
                        value={searchTerm}
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                    <div style={{ maxHeight: '600px', overflowY: 'auto' }}>
                        {filteredFollowers.map((follower, index) => getDropdownItem(follower, index))}
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default FollowersDropDown;
