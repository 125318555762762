import React, { useEffect } from 'react';
import { useForm, Resolver, SubmitHandler, FieldValues } from 'react-hook-form';

interface VerticalFormProps<TFormValues extends FieldValues> {
    defaultValues?: Partial<TFormValues>;
    resolver?: Resolver<TFormValues>;
    children?: React.ReactNode;
    onSubmit: SubmitHandler<TFormValues>;
    onChange?: (name: string, e: any) => any;
    formClass?: string;
}

const VerticalForm = <TFormValues extends FieldValues = FieldValues>({
    defaultValues,
    resolver,
    children,
    onSubmit,
    onChange,
    formClass,
}: VerticalFormProps<TFormValues>) => {
    const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

    useEffect(() => {
        forceUpdate();
    }, []);

    /*
     * form methods
     */
    const methods = useForm<TFormValues>({
        defaultValues: defaultValues as any,
        resolver,
    });
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
    } = methods;

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={formClass} noValidate>
            {Array.isArray(children)
                ? children.map((child) => {
                      if (!child) return;

                      return child.props && child.props.name
                          ? React.createElement(child.type, {
                                ...{
                                    ...child.props,
                                    register,
                                    key: child.props.name,
                                    errors,
                                    onChange: (e: any) => (onChange ? onChange(child.props.name, e) : null),
                                    control,
                                },
                            })
                          : child;
                  })
                : children}
        </form>
    );
};

export default VerticalForm;
