import React from 'react';
import { APICore } from '../utils/api/apiCore';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

interface PrivateRouteProps {
    children: JSX.Element;
    roles?: string[];
}

const PrivateRoute = ({ children, roles }: PrivateRouteProps) => {
    const api = new APICore();
    const location = useLocation();
    const loggedInUser = api.getLoggedInUser();
    const isUserRolesMatch = roles ? loggedInUser?.user?.roles.some((r) => roles.includes(r)) : true;
    const isUserAuthorized = isUserRolesMatch || loggedInUser.user?.isAdmin;

    if (!api.isUserAuthenticated()) {
        // Redirect to login
        return <Navigate to="/auth/login" state={{ from: location.pathname }} replace={true} />;
    }

    if (!isUserAuthorized) {
        // Redirect if role not authorised
        return <Navigate to="/" state={{ from: location.pathname }} replace={true} />;
    }

    return children;
};

export default PrivateRoute;
