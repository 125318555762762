import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { withSwal } from 'react-sweetalert2';
// components

import './business-dashboard.scss';

import { useTranslation } from 'react-i18next';
import { ApiError } from '../../utils/api/ApiError';
import PageTitle from '../../components/PageTitle';
import 'rsuite/dist/rsuite.css';
import DashboardGraph from './DashboardGraph';
import { getUsers } from '../../utils/api/users';
import { User } from '../../interfaces/User';
import globalErrorHandler from '../../utils/globalErrorHandler';

// main component
const BusinessDashboard = withSwal((props: any) => {
    useEffect(() => {
        document.title = 'Business Dashboard';

        return () => {
            document.title = 'Better Admin';
        };
    }, []);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const mounted = useRef(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<User[]>([]);

    const handleData = async () => {
        try {
            setLoading(true);
            const usersResult = await getUsers();
            setUsers(usersResult.users);
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
            if (err.status === 401) {
                return navigate('/auth/logout');
            }
            globalErrorHandler(err);
            // alert("Error getting data");
        }
    };

    useEffect(() => {
        mounted.current = true;
        if (mounted) {
            handleData();
        }

        return () => {
            mounted.current = false;
        };
    }, []);

    const getStartDate = () => {
        const date = new Date();
        if (date.getDate() < 7) {
            date.setDate(date.getDate() - 7);
        } else {
            date.setDate(1);
        }
        return date;
    };

    return (
        <React.Fragment>
            <PageTitle
                breadCrumbItems={
                    [
                        // {label: 'Insurance Companies', path: '/apps/crm/customers', active: true},
                    ]
                }
                title={'Business Dashboard'}
            />

            <Row>
                <Col xl={6} lg={6} className="d-flex flex-column gap-5">
                    <DashboardGraph title={'Dynamic Graph'} users={users} dynamicGraph={true} />

                    <DashboardGraph
                        groupBy={'daily'}
                        title={'Signups (pre zoom)'}
                        startStatus="onboarding_preZoom"
                        endStatus="onboarding_preZoom"
                        startDate={getStartDate()}
                        endDate={new Date()}
                    />

                    <DashboardGraph
                        groupBy={'daily'}
                        title={'Onboarding Show (onboarding date to onboarding show)'}
                        startStatus="onboarding_onboardingDate"
                        endStatus="onboarding_onboardingCallShow"
                        startDate={getStartDate()}
                        endDate={new Date()}
                    />

                    <DashboardGraph
                        groupBy={'daily'}
                        title={'Agent Meeting Success (pension call show to client signed)'}
                        startDate={getStartDate()}
                        startStatus="pension_pensionAgentCallShow"
                        endStatus="pension_clientSignedForms"
                        endDate={new Date()}
                        filterBySource={true}
                    />
                </Col>
                <Col xl={6} lg={6} className="d-flex flex-column gap-5">
                    <DashboardGraph
                        groupBy={'daily'}
                        title={'Onboarding Success (onboarding show to post zoom)'}
                        startDate={getStartDate()}
                        startStatus="onboarding_onboardingCallShow"
                        endStatus="onboarding_postZoom"
                        endDate={new Date()}
                        filterBySource={true}
                    />

                    <DashboardGraph
                        groupBy={'daily'}
                        title={'Agent Call Scheduled (agent meeting scheduled to agent meeting  show)'}
                        startStatus="pension_agentMeetingScheduled"
                        endStatus="pension_pensionAgentCallDate"
                        startDate={getStartDate()}
                        endDate={new Date()}
                    />

                    <DashboardGraph
                        groupBy={'daily'}
                        title={'Agent meeting show Success (call date to call show)'}
                        startDate={getStartDate()}
                        startStatus="pension_pensionAgentCallDate"
                        endStatus="pension_pensionAgentCallShow"
                        endDate={new Date()}
                        filterBySource={true}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
});
export default BusinessDashboard;
