import { useCallback, useMemo, useState } from 'react';
import { Employer } from '../../../interfaces/Employer';
import AddEmployer from './../AddEmployer';

interface EmployerActionsProps {
    onAddEmployer?: (employer: Employer) => void;
    onEditEmployer?: (employer: Employer) => void;
}

const useEmployerEdit = ({ onAddEmployer, onEditEmployer }: EmployerActionsProps) => {
    const [showAddEmployer, setShowAddEmployer] = useState<boolean>(false);
    const [selectedEmployer, setSelectedEmployer] = useState<Employer | undefined>();

    const onCloseAddEmployerModal = useCallback(() => setShowAddEmployer(false), []);

    const AddEmployerModal = useCallback(() => {
        return AddEmployer({
            show: showAddEmployer,
            onHide: onCloseAddEmployerModal,
            employer: selectedEmployer,
            onAddEmployer,
            onEditEmployer,
        });
    }, [showAddEmployer, selectedEmployer, onAddEmployer, onEditEmployer, onCloseAddEmployerModal]);

    return {
        showAddEmployer,
        setShowAddEmployer,
        selectedEmployer,
        setSelectedEmployer,
        AddEmployerModal,
        onCloseAddEmployerModal,
    };
};

export default useEmployerEdit;
