import React from 'react';
import { Form } from 'react-bootstrap';
import BorderWithText from '../../../../../components/BorderWithText';
import { Child } from '../../../../../interfaces/TaxData';
import { parseDateValue } from '../../../../../utils/parseDateValue';

interface ChildDetailsBoxProps {
    t: (s: string) => string;
    prefix: string;
    child: Child;
    index: number;
    onChange: (e: any) => void;
}

const ChildDetailsBox = ({ t, prefix, child, index, onChange }: ChildDetailsBoxProps) => {
    return (
        <BorderWithText label={t('Personal Details')}>
            <Form.Group controlId={`${prefix}Child-${index}`} className="d-flex flex-column gap-3">
                <div className="d-flex gap-3">
                    <div>
                        <Form.Label>{t('First Name')}</Form.Label>
                        <Form.Control type="text" name={`${prefix}.children[${index}].firstName`} value={child.firstName} onChange={onChange} />
                    </div>
                    <div>
                        <Form.Label>{t('Last Name')}</Form.Label>
                        <Form.Control type="text" name={`${prefix}.children[${index}].lastName`} value={child.lastName} onChange={onChange} />
                    </div>
                </div>
                <div className="d-flex gap-3">
                    <div>
                        <Form.Label>{t('Date of Birth')}</Form.Label>
                        <Form.Control
                            type="date"
                            name={`${prefix}.children[${index}].dateOfBirth`}
                            value={parseDateValue(child.dateOfBirth)}
                            onChange={onChange}
                        />
                    </div>
                    <div>
                        <Form.Label>{t('ID Number')}</Form.Label>
                        <Form.Control type="text" name={`${prefix}.children[${index}].idNumber`} value={child.idNumber} onChange={onChange} />
                    </div>
                </div>
            </Form.Group>
        </BorderWithText>
    );
};

export default ChildDetailsBox;
