export const getValueByField = (obj: any, field: string): any => {
    const keys = field.split('.');
    return keys.reduce((value, key) => (value ? value[key] : undefined), obj);
};

export const setValueByField = (obj: any, field: string, value: any): any => {
    const keys = field.split('.');
    const lastKey = keys.pop();
    if (!lastKey) {
        return obj;
    }
    const target = keys.reduce((value, key) => (value ? value[key] : undefined), obj);
    target[lastKey] = value;
    return obj;
};
