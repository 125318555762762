import { APICore, API_URL } from './apiCore';

const api = new APICore();
// const API_URL = 'http://localhost:5000/api'

const getComments = (params: { clientId?: string; taskId?: string; productId?: string }) => {
    const relativeUrl = '/comments';
    return api.get(`${API_URL}${relativeUrl}`, params);
};

const addComment = (params: { clientId?: string; taskId?: string; productId?: string; text: string; topic: string }) => {
    const relativeUrl = `/comments`;
    return api.create(`${API_URL}${relativeUrl}`, params);
};

const editComment = (commentId: string, text: string) => {
    const relativeUrl = `/comments/${commentId}`;
    return api.update(`${API_URL}${relativeUrl}`, { text });
};

const deleteComment = (commentId: string) => {
    const relativeUrl = `/comments/${commentId}`;
    return api.delete(`${API_URL}${relativeUrl}`);
};

export { addComment, getComments, editComment, deleteComment };
