import React, { useCallback, useEffect, useState } from 'react';
import { Task } from '../../../interfaces/Task';
import CreateTask from './../CreateTask';
import { createTask, updateTask } from '../../../utils/api/tasks';
import { User } from '../../../interfaces/User';
import globalErrorHandler from '../../../utils/globalErrorHandler';

interface TaskActionsProps {
    users: User[];
    tasks: Task[];
    setTasks: (tasks: Task[]) => void;
}

const useCreateAndEditTask = ({ users, tasks, setTasks }: TaskActionsProps) => {
    const [showCreateAndEditTaskModal, setShowCreateAndEditTaskModal] = useState<boolean>(false);
    const [selectedTask, setSelectedTask] = useState<Task | undefined>();

    const onCloseCreateAndEditTaskModal = () => {
        setShowCreateAndEditTaskModal(false);
        setSelectedTask(undefined);
    };

    const onTaskClick = (task: Task) => {
        task.dueDate = task.dueDate ? new Date(task.dueDate) : undefined;
        setSelectedTask(task);
    };

    const onCreateTaskClick = () => {
        setSelectedTask(undefined);
        setShowCreateAndEditTaskModal(true);
    };

    useEffect(() => {
        if (selectedTask) {
            setShowCreateAndEditTaskModal(true);
        }
    }, [selectedTask]);

    const onSubmit = async (newTask: Task, oldTask?: Task) => {
        try {
            if (oldTask) {
                const params = {
                    taskId: oldTask._id,
                    userId: newTask.user._id,
                    status: newTask.status,
                    text: newTask.text,
                    dueDate: newTask.dueDate,
                };
                await updateTask(params);
                const updatedTasks = tasks.map((task) => {
                    if (task._id === oldTask._id) {
                        const updatedTask = { ...task };
                        if (params.userId && params.userId !== updatedTask.user._id) {
                            updatedTask.user = users.find((user) => user._id === params.userId)!;
                        }
                        if (params.status && params.status !== updatedTask.status) {
                            updatedTask.status = params.status;
                        }
                        if (params.text && params.text !== updatedTask.text) {
                            updatedTask.text = params.text;
                        }
                        if (params.dueDate && params.dueDate !== updatedTask.dueDate) {
                            updatedTask.dueDate = params.dueDate;
                        }
                        return updatedTask;
                    }
                    return task;
                });
                setTasks(updatedTasks);
            } else {
                const result = await createTask(newTask);
                const returnedTask = result.task;
                returnedTask.user = users.find((user) => user._id === returnedTask.user)!;
                setTasks([...tasks, returnedTask]);
            }
        } catch (error) {
            globalErrorHandler(error);
        } finally {
            setShowCreateAndEditTaskModal(false);
        }
    };

    // const CreateAndEditTaskModal = useCallback(() => {
    //     return CreateTask({
    //         show: showCreateAndEditTaskModal,
    //         onHide: onCloseCreateAndEditTaskModal,
    //         task: selectedTask,
    //         onSubmit,
    //         users,
    //     });
    // }, [showCreateAndEditTaskModal, selectedTask, onSubmit, users, onCloseCreateAndEditTaskModal]);

    return {
        showCreateAndEditTaskModal,
        onTaskClick,
        onCreateTaskClick,
        onCloseCreateAndEditTaskModal,
        onSubmit,
        selectedTask,
    };
};

export default useCreateAndEditTask;
