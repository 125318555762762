import './../clientProfile.scss';
import { Button } from 'react-bootstrap';
import { getAppendicesFile } from '../../../utils/api/insuranceAppendix';
import { useContext, useEffect, useState } from 'react';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';
import { downloadFileFromAPI } from '../../../utils/downloadFileFromAPI';
import DualSigningButton from '../../../components/dualSigningButton/DualSigningButton';
import { archiveSignature, getSignatures } from '../../../utils/api/signatures';
import { SignedDocument } from '../../../interfaces/SignedDocument';
import { generateAgentAppointmentForms } from '../../../utils/api/insurancePolicy';
import { UserProfileContext } from '../../../context/userProfileContext';
import { useTranslation } from 'react-i18next';
import globalErrorHandler from '../../../utils/globalErrorHandler';

interface InsuranceProductsMenuHeaderProps {
    setShowNewProductCreationModal: (show: boolean) => void;
    clientId: string;
    clientFullName: string;
    getClientsPolicies: () => void;
    showCreateClientAggentAppointment: boolean;
    showCreateSpouseAggentAppointment: boolean;
}

const InsuranceProductsMenuHeader = ({
    setShowNewProductCreationModal,
    clientId,
    clientFullName,
    getClientsPolicies,
    showCreateClientAggentAppointment,
    showCreateSpouseAggentAppointment,
}: InsuranceProductsMenuHeaderProps) => {
    const { profileContextData } = useContext(UserProfileContext);
    const { client, currentRound } = profileContextData;
    const { t } = useTranslation('insurance');

    const [isDownloading, setIsDownloading] = useState(false);
    const [clientAgentAssignmentSignedDocument, setClientAgentAssignmentSignedDocument] = useState<SignedDocument>();
    const [spouseAgentAssignmentSignedDocument, setSpouseAgentAssignmentSignedDocument] = useState<SignedDocument>();

    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            const response = await getAppendicesFile({ clientId });
            const fileName = clientFullName + '_appendices_' + new Date().toLocaleDateString();
            downloadFileFromAPI(response, fileName, 'xlsx');
        } finally {
            setIsDownloading(false);
        }
    };

    const generateAgentAssignmentForm = async (isClient: boolean, preview: boolean) => {
        if (!currentRound) {
            return;
        }

        try {
            const result = await generateAgentAppointmentForms({
                insuranceId: currentRound._id,
                isSpouseForms: !isClient,
                preview,
            });
            if (preview) return result;

            if (isClient) setClientAgentAssignmentSignedDocument(result.signedDocument);
            else setSpouseAgentAssignmentSignedDocument(result.signedDocument);
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    const archiveSigningDocument = async (signedDoc: SignedDocument) => {
        if (!window.confirm('Are you sure you want to archive this document?')) return;

        try {
            const result = await archiveSignature(signedDoc._id);
            if (signedDoc === clientAgentAssignmentSignedDocument) setClientAgentAssignmentSignedDocument(undefined);
            else setSpouseAgentAssignmentSignedDocument(undefined);

            await getClientsPolicies();
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        const getSignedDocs = async () => {
            try {
                const { signedDocuments: signedDocumentsClient } = await getSignatures({
                    clientId: client._id,
                    documentType: 'agentAppointmentInsurance',
                    insuranceId: currentRound?._id,
                });

                const { signedDocuments: signedDocumentsSpouse } = await getSignatures({
                    clientId: client._id,
                    documentType: 'agentAppointmentInsurance',
                    insuranceId: currentRound?._id,
                });

                if (signedDocumentsClient.length) setClientAgentAssignmentSignedDocument(signedDocumentsClient[0]);
                if (signedDocumentsSpouse.length) setSpouseAgentAssignmentSignedDocument(signedDocumentsSpouse[0]);
            } catch (error) {
                globalErrorHandler(error);
            }
        };
        getSignedDocs();
    }, [client, currentRound]);

    return (
        <div className="d-flex flex-row-reverse">
            <div className="p-2">
                <Button onClick={() => setShowNewProductCreationModal(true)}>New Policy</Button>
            </div>
            <div className="p-2">
                <LaddaButton
                    loading={isDownloading}
                    onClick={handleDownload}
                    data-color="#eee"
                    data-style={EXPAND_LEFT}
                    data-spinner-size={30}
                    data-spinner-color="#ddd"
                    data-spinner-lines={12}
                    className="btn btn-primary"
                >
                    <i className="mdi mdi-download"></i> Download
                </LaddaButton>
            </div>
            {showCreateClientAggentAppointment && (
                <div className="p-2">
                    <DualSigningButton
                        onArchive={
                            clientAgentAssignmentSignedDocument ? () => archiveSigningDocument(clientAgentAssignmentSignedDocument) : undefined
                        }
                        text={t('Client agent appointment')}
                        signedDocument={clientAgentAssignmentSignedDocument}
                        isEnabled={true}
                        generateSigningLink={(preview: boolean) => generateAgentAssignmentForm(true, preview)}
                        onError={(err: any) => alert("Can't generate client agent appointment form")}
                    />
                </div>
            )}
            {showCreateSpouseAggentAppointment && (
                <div className="p-2">
                    <DualSigningButton
                        onArchive={
                            spouseAgentAssignmentSignedDocument ? () => archiveSigningDocument(spouseAgentAssignmentSignedDocument) : undefined
                        }
                        text={t('Spouse agent appointment')}
                        signedDocument={spouseAgentAssignmentSignedDocument}
                        isEnabled={true}
                        generateSigningLink={(preview: boolean) => generateAgentAssignmentForm(false, preview)}
                        onError={(err: any) => alert("Can't generate Spouse agent appointment form")}
                    />
                </div>
            )}
        </div>
    );
};

export default InsuranceProductsMenuHeader;
