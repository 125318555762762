import React, { useContext } from 'react';
import { chatContext } from '../../context/chatContext';
import Comments from '../../components/Comments';
import ProfileBox from '../profile/ProfileBox';
import ChatArea from '../../components/chat-area/ChatArea';
import useComments from '../../hooks/useComments';

const ChatUserWrapper = () => {
    const { chatData } = useContext(chatContext);
    const commentProps = useComments({ client: chatData.selectedChat?.client });

    return (
        <section className="chat-user-wrapper">
            {chatData.selectedChat ? (
                <>
                    <div className="user-chat-area">
                        <ChatArea
                            client={chatData.selectedChat.client}
                            enableSocket={true}
                            sendMessageEnabled={true}
                            showJotformLinks={false}
                            chatTitle={chatData.selectedChat.client.fullName}
                            unreadCount={chatData.selectedUnreadCount}
                            polling={false}
                            chatId={chatData.selectedChat._id}
                        />
                    </div>
                    <div className="user-profile-area">
                        <ProfileBox isOnboarding={false} client={chatData.selectedChat.client} />
                        <Comments {...commentProps} />
                    </div>
                </>
            ) : (
                <div className="no-chat-selected">
                    <i className="mdi mdi-whatsapp"></i>
                    No chat was selected
                </div>
            )}
        </section>
    );
};

export default ChatUserWrapper;
