import { useTranslation } from 'react-i18next';
import { TaxData } from '../../../../../interfaces/TaxData';
import GeneralDataForm from './GeneralDataForm';
import SpouseDetailsBox from './SpouseDetailsBox';
import ChildDetailsBox from './ChildDetailBox';

interface GeneralDataSectionProps {
    taxData: TaxData;
    onChange: (e: any) => void;
}

const GeneralDataSection = ({ taxData, onChange }: GeneralDataSectionProps) => {
    const { t } = useTranslation('taxReturn');

    const renderClientSection = () => (
        <div className="client-spouse-data">
            <h4>{t('Client')}</h4>
            <GeneralDataForm data={taxData.clientTaxData} prefix="clientTaxData" onChange={onChange} />
        </div>
    );

    const renderSpouseSection = () => (
        <div className="client-spouse-data">
            <h4>{t('Spouse')}</h4>
            {taxData.family?.spouse && <SpouseDetailsBox t={t} prefix="family.spouse" data={taxData.family.spouse} onChange={onChange} />}
            <GeneralDataForm data={taxData.spouseTaxData} prefix="spouseTaxData" onChange={onChange} />
        </div>
    );

    const renderChildrenSection = () => (
        <div className="client-spouse-data">
            <h4>{t('Children')}</h4>
            {taxData.family.children.map((child, index) => (
                <ChildDetailsBox key={index} t={t} prefix="family" child={child} index={index} onChange={onChange} />
            ))}
        </div>
    );

    return (
        <div className="general-data-form">
            {taxData.clientTaxData && renderClientSection()}
            {(taxData.spouseTaxData || taxData.family?.spouse) && renderSpouseSection()}
            {taxData.family?.children && taxData.family.children.length > 0 && renderChildrenSection()}
        </div>
    );
};

export default GeneralDataSection;
