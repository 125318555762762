import { ApiError } from './api/ApiError';
import Swal from 'sweetalert2';
import gifUrl from '../assets/images/error.gif';

const createHtmlContent = (error: ApiError, message: string) => `
    <div style="text-align: left;">
        <p class="fw-bold" style="font-size: 20px;">Error: ${error.message}</p>
        ${message
            .split('\n')
            .slice(1)
            .map((line) => `<div style="font-size: 14px;">${line}</div>`)
            .join('')}
    </div>
    <button id="copy-button" class="swal2-styled swal2-confirm" style="margin-top: 20px;">
        <i class="fa fa-copy"></i> Copy error data
    </button>
`;

const copyToClipboard = (message: string) => {
    navigator.clipboard
        .writeText(message)
        .then(() => {
            const copyButton: any = document.getElementById('copy-button');
            if (copyButton) {
                copyButton.innerHTML = '<i class="fa fa-check"></i> Copied!';
                copyButton.disabled = true;
            }
        })
        .catch((err) => {
            console.error('Could not copy text: ', err);
        });
};

const getErrorMessage = (error: any) => {
    if (error instanceof ApiError) {
        debugger;
        const parts = [
            `Error: ${error.message}`,
            error.status && `Status: ${error.status}`,
            error.errorCode && `Error Code: ${error.errorCode}`,
            error.reqId && `Request ID: ${error.reqId}`,
            error.serviceName && `Service Name: ${error.serviceName}`,
            error.extraData && `Extra Data: ${JSON.stringify(error.extraData)}`,
        ].filter(Boolean);
        return parts.join('\n');
    } else {
        return `Error: ${JSON.stringify(error) === '{}' ? error : JSON.stringify(error)}`;
    }
};

const getErrorContent = (error: any, message: string) => {
    if (error instanceof ApiError) {
        return createHtmlContent(error, message);
    } else {
        return `
            <div style="text-align: left;">
                <p class="fw-bold" style="font-size: 20px;">Error</p>
                <div style="font-size: 14px;">${message}</div>
            </div>
            <button id="copy-button" class="swal2-styled swal2-confirm" style="margin-top: 20px;">
                <i class="fa fa-copy"></i> Copy error data
            </button>
        `;
    }
};

const globalErrorHandler = (error: any) => {
    console.trace('Error', error);
    const message = getErrorMessage(error);
    const content = getErrorContent(error, message);
    if (error.status === 401) {
        return;
    }

    Swal.fire({
        html: content,
        imageUrl: gifUrl,
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: 'Error image',
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
            const copyButton = document.getElementById('copy-button');
            if (copyButton) {
                copyButton.addEventListener('click', () => {
                    copyToClipboard(message);
                });
            }
        },
        showCloseButton: true,
        closeButtonHtml: '&times;',
    });
};

export default globalErrorHandler;
