import { InsuranceAppendix } from '../../../../interfaces/InsuranceAppendix';
import { InsurancePolicy } from '../../../../interfaces/InsurancePolicy';
import { createInsuranceAppendix, editInsuranceAppendix } from '../../../../utils/api/insuranceAppendix';
import { createInsurancePolicy } from '../../../../utils/api/insurancePolicy';
import globalErrorHandler from '../../../../utils/globalErrorHandler';

export const createNewPolicy = async (policy: InsurancePolicy, originalPolicyId?: string) => {
    try {
        const response = await createInsurancePolicy(policy, originalPolicyId);
        const newPolicy = response.policy;
        return newPolicy;
    } catch (error) {
        globalErrorHandler(error);
    }
};

export const createNewAppendix = async (appendix: InsuranceAppendix) => {
    try {
        appendix.isNew = true;
        const response = await createInsuranceAppendix(appendix);
        const newAppendix = response.appendix;
        return newAppendix;
    } catch (error) {
        globalErrorHandler(error);
    }
};

export const editExistingAppendix = async (appendix: InsuranceAppendix, id?: string) => {
    let appendixId;
    if (id) {
        appendixId = id;
    } else {
        appendixId = appendix._id;
    }
    try {
        const response = await editInsuranceAppendix(appendixId, appendix);
        const editedAppendix = response.appendix;
        return editedAppendix;
    } catch (error) {
        globalErrorHandler(error);
    }
};

export const addAdditionalInfoToPolicy = (policy: InsurancePolicy, clientId: string, insuranceId: string) => {
    const policyCopy = { ...policy };
    policyCopy.clientId = clientId;
    policyCopy.insuranceId = insuranceId;
    return policyCopy;
};

export const addAdditionalInfoToAppendx = (appendix: InsuranceAppendix, clientId: string) => {
    const appendixCopy = { ...appendix };
    appendixCopy.clientId = clientId;
    appendixCopy.insuredList.forEach((insured) => {
        if (!insured.idNumber) {
            insured.idNumber = `Can't find the ID of: ${insured.name}`;
        }
    });
    return appendixCopy;
};
