import React, { createContext, useState, useEffect } from 'react';
import { getUnreadCount } from '../utils/api/chat';
import { getTaskCount } from '../utils/api/tasks';
import { useAppContext } from './AppContext';
import { set } from 'date-fns';

interface ChatAndTaskCountContextProps {
    unreadCountNotPinned: number;
    unreadCountPinned: number;
    starredCount: number;
    openTaskCount: number;
    lastMessageTime: number;
    getChatAndTaskCounts: () => Promise<void>;
    setLastMessageTime: React.Dispatch<React.SetStateAction<number>>;
}

export const ChatAndTaskCountContext = createContext<ChatAndTaskCountContextProps>({
    unreadCountNotPinned: 0,
    unreadCountPinned: 0,
    starredCount: 0,
    openTaskCount: 0,
    lastMessageTime: 0,
    getChatAndTaskCounts: async () => {},
    setLastMessageTime: () => {},
});

export const ChatAndTaskCountProvider = ({ children }: { children: React.ReactNode }) => {
    const { isUserLoggedIn } = useAppContext();
    const [unreadCountNotPinned, setUnreadCountNotPinned] = useState<number>(0);
    const [unreadCountPinned, setUnreadCountPinned] = useState<number>(0);
    const [starredCount, setStarredCount] = useState<number>(0);
    const [openTaskCount, setOpenTaskCount] = useState<number>(0);
    const [lastMessageTime, setLastMessageTime] = useState<number>(0);

    const getChatAndTaskCounts = async () => {
        const result = await Promise.allSettled([getUnreadCount(), getTaskCount()]);

        if (result[0].status === 'fulfilled') {
            // debugger;
            setUnreadCountNotPinned(result[0].value.unreadCountNotPinned);
            setUnreadCountPinned(result[0].value.unreadCountPinned);
            setStarredCount(result[0].value.starredCount);
        }

        if (result[1].status === 'fulfilled') {
            setOpenTaskCount(result[1].value.count);
        }
    };

    useEffect(() => {
        if (isUserLoggedIn) {
            getChatAndTaskCounts();
        }
    }, [isUserLoggedIn]);

    return (
        <ChatAndTaskCountContext.Provider
            value={{
                unreadCountNotPinned,
                unreadCountPinned,
                starredCount,
                openTaskCount,
                lastMessageTime,
                getChatAndTaskCounts,
                setLastMessageTime,
            }}
        >
            {children}
        </ChatAndTaskCountContext.Provider>
    );
};

export const useChatAndTaskCountContext = () => {
    const context = React.useContext(ChatAndTaskCountContext);
    if (!context) {
        throw new Error('useChatAndTaskCountContext must be used within a ChatAndTaskCountProvider');
    }
    return context;
};
