import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import profile from './pages/profile/translation.json';
import productTransfer from './pages/product-transfers/translation.json';
import product from './pages/product/translation.json';
import cubes from './pages/profile/cubes/translation.json';
// import profile from './pages/profile/clientProfile/translation.json';
import components from './components/translation.json';
import clients from './pages/clients/translation.json';
import insurance from './pages/profile/insurance/translation.json';
import employers from './pages/employers/translation.json';
import files from './pages/files/translation.json';
import taxReturn from './pages/profile/taxReturn/translation.json';
import emails from './pages/emails/translation.json';
import slaScreen from './pages/sla-screen/translation.json';
import layouts from './layouts/translation.json';
import chat from './components/chat-area/translation.json';

//translations
const resources = {
    he: {
        chat,
        profile,
        productTransfer,
        components,
        cubes,
        product,
        insurance,
        clients,
        employers,
        files,
        taxReturn,
        emails,
        slaScreen,
        layouts
    },
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'he',
        fallbackLng: 'he', // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
