export const downloadFileFromAPI = async (resFromAPIGetFile: Response, fileName: string, fileExtension: string) => {
    if (resFromAPIGetFile.ok) {
        const blob = await resFromAPIGetFile.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${fileName}.${fileExtension}`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    } else {
        console.error('Download request failed.');
    }
};
