import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from '../../../context/userProfileContext';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { User } from '../../../interfaces/User';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import { BankDeposit, DepositStatuses, DepositCloseReasons } from '../../../interfaces/BankDeposit';
import { formatDate } from '../../../utils/formatDate';
import { DebounceInput } from 'react-debounce-input';
import { displayNumericInputFieldsValue } from '../../../utils/displayNumericValues';

const Details = () => {
    const { profileContextData, onEditRound, users } = useContext(UserProfileContext);
    const { client } = profileContextData;
    const selectedModule = profileContextData.selectedModule;
    const selectedModuleName = selectedModule?.data.moduleName;
    const repType = 'rep';

    const [repAgents, setRepAgents] = useState<User[]>([]);
    const [currentRound, setCurrentRound] = useState<BankDeposit>(profileContextData.currentRound as BankDeposit);

    let debounceTimers = {};

    useEffect(() => {
        setRepAgents(users.filter((rep: User) => rep.roles.includes('agent') && rep.agentModules?.includes('deposit')));
    }, [users]);

    // useEffect(() => {
    //     debugger;
    //     if (debounceTimer) {
    //         clearTimeout(debounceTimer);
    //     }

    //     debounceTimer = setTimeout(() => {
    //         if (validateFields()) {
    //             onEditRound(currentRound, selectedModuleName, currentRound._id);
    //         }
    //     }, 1500);

    //     return () => {
    //         if (debounceTimer) {
    //             clearTimeout(debounceTimer);
    //         }
    //     };
    // }, [currentRound]);

    const handleDepositChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const round: any = {
            ...currentRound,
            [event.target.name]: event.target.value,
        };
        onEditRound({ [event.target.name]: event.target.value }, selectedModuleName, currentRound._id, () => setCurrentRound(round));
    };

    const handleFormFieldsChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setCurrentRound({
            ...currentRound,
            [event.target.name]: event.target.value,
        });
    };

    const validateFields = () => {
        const { depositAmount, interest } = currentRound;
        if (!depositAmount) {
            return false;
        }
        if (depositAmount < 0) {
            alert('Depost amount can not be a negative number');
            return false;
        }
        if (!interest) {
            return false;
        }
        if (interest < 0 || interest > 100) {
            alert('Interest should be a number between 0 and 100');
            return false;
        }
        return true;
    };

    if (!client || !currentRound) {
        return <div>Sorry, no data yet.</div>;
    }
    const lastChangeDateString = currentRound?.lastStatusChangeDate
        ? `Status (Last change: ${formatDate(new Date(currentRound.lastStatusChangeDate))})`
        : 'Status';

    return (
        <div className="detailed-tab">
            <div className="select-area">
                <InputWrapper status={{ text: '' }} label={lastChangeDateString} wrapperClass="input-field select-field">
                    <select value={currentRound.status} className="form-select" name="status" onChange={handleDepositChange}>
                        <option disabled value={''}>
                            Select status
                        </option>

                        {Object.values(DepositStatuses).map((option: any, index: number) => (
                            <option key={index} value={option}>
                                {camelCaseToText(option)}
                            </option>
                        ))}
                    </select>
                </InputWrapper>

                {currentRound.status === 'closeLost' && (
                    <InputWrapper status={{ text: '' }} label="Close Reason" wrapperClass="input-field select-field">
                        <select
                            value={currentRound.depositCloseReason}
                            className="form-select"
                            name="depositCloseReason"
                            onChange={handleDepositChange}
                        >
                            <option disabled value={''}>
                                Select status
                            </option>

                            {Object.values(DepositCloseReasons).map((option: any, index: number) => (
                                <option key={index} value={option}>
                                    {camelCaseToText(option)}
                                </option>
                            ))}
                        </select>
                    </InputWrapper>
                )}

                {repAgents && repAgents.length > 0 ? (
                    <InputWrapper status={{ text: '' }} label={'Agent / Rep'} wrapperClass="input-field select-field">
                        <select value={currentRound.rep} className="form-select" name="rep" onChange={handleDepositChange}>
                            <option disabled value="">
                                Select {repType}{' '}
                            </option>

                            {repAgents.map((option: any, index: number) => (
                                <option key={index} value={option._id}>
                                    {option.fullName}
                                </option>
                            ))}
                        </select>
                    </InputWrapper>
                ) : (
                    <div className="pension-status">No {repType} found.</div>
                )}

                <InputWrapper status={{ text: '' }} label="Deposit Type" wrapperClass="input-field input-field">
                    <DebounceInput
                        value={currentRound.depositType}
                        className="form-control"
                        debounceTimeout={1500}
                        name="depositType"
                        onChange={handleDepositChange}
                    />
                </InputWrapper>

                <InputWrapper status={{ text: '' }} label="Deposit Amount" wrapperClass="input-field input-field">
                    <DebounceInput
                        value={displayNumericInputFieldsValue(currentRound.depositAmount)}
                        className="form-control"
                        debounceTimeout={1500}
                        name="depositAmount"
                        onChange={handleDepositChange}
                    />
                </InputWrapper>

                <InputWrapper status={{ text: '' }} label="Interest rate" wrapperClass="input-field input-field">
                    <DebounceInput
                        value={displayNumericInputFieldsValue(currentRound.interest)}
                        className="form-control"
                        name="interest"
                        type="number"
                        min={0}
                        max={100}
                        debounceTimeout={1500}
                        onChange={handleDepositChange}
                    />
                </InputWrapper>

                <InputWrapper status={{ text: '' }} label="Close Date" wrapperClass="input-field input-field">
                    <input
                        value={formatDate(currentRound.depositCloseDate, true, false, 'en-CA')}
                        className="form-control"
                        name="depositCloseDate"
                        type="date"
                        onChange={handleFormFieldsChange}
                    />
                </InputWrapper>

                <InputWrapper status={{ text: '' }} label="Maturity Date" wrapperClass="input-field input-field">
                    <input
                        value={formatDate(currentRound.maturityDate, true, false, 'en-CA')}
                        className="form-control"
                        name="maturityDate"
                        type="date"
                        onChange={handleFormFieldsChange}
                    />
                </InputWrapper>
            </div>
        </div>
    );
};

export default Details;
