import { APICore, INSURANCE_URL } from './apiCore';
import { InsurancePolicy } from '../../interfaces/InsurancePolicy';

const api = new APICore();

export const getInsurancePolicies = (params: { clientId?: string; insuranceId?: string }) => {
    const relativeUrl = '/policy';
    return api.get(`${INSURANCE_URL}${relativeUrl}`, params);
};

export const generateAgentAppointmentForms = (params: { insuranceId: string; isSpouseForms: boolean; preview: boolean }) => {
    const relativeUrl = '/insurance/agent-appointment-forms';

    if (params.preview) return api.getFile(`${INSURANCE_URL}${relativeUrl}`, params);
    else return api.get(`${INSURANCE_URL}${relativeUrl}`, params);
};

export const createInsurancePolicy = (newInsurancePolicy: InsurancePolicy, originalPolicyId: string | undefined) => {
    const relativeUrl = '/policy';
    const body = originalPolicyId ? { ...newInsurancePolicy, originalPolicyId } : newInsurancePolicy;
    return api.create(`${INSURANCE_URL}${relativeUrl}`, body);
};

export const editInsurancePolicy = (id: string, policy: any) => {
    const relativeUrl = '/policy/' + id;
    return api.update(`${INSURANCE_URL}${relativeUrl}`, policy);
};

export const deleteInsurancePolicy = (id: string) => {
    const relativeUrl = '/policy/' + id;
    return api.delete(`${INSURANCE_URL}${relativeUrl}`);
};
