import React, { createContext, useState, useEffect, useContext } from 'react';
import { getEnums } from '../utils/api/clientsBySearch';

interface EnumsContextType {
    [key: string]: any;
}

const EnumsContext = createContext<EnumsContextType | null>(null);

export const useEnumsContext = () => useContext(EnumsContext);

export const EnumsProvider = ({ children }: { children: React.ReactNode }) => {
    const [enums, setEnums] = useState(null);

    useEffect(() => {
        const fetchEnums = async () => {
            try {
                const response = await getEnums();
                setEnums(response);
            } catch (error) {
                console.error('Error fetching enums:', error);
            }
        };

        fetchEnums();
    }, []);

    return <EnumsContext.Provider value={enums}>{children}</EnumsContext.Provider>;
};
