import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormInput } from '../../components';
import { User } from '../../interfaces/User';
import { APICore } from '../../utils/api/apiCore';
import { Log, Task } from '../../interfaces/Task';
import Modules from '../../utils/modules';
import DatePicker from 'react-datepicker';
import { camelCaseToText } from '../../utils/camelCaseToText';
import userOptionsForSelect from '../../utils/userOptionsForSelect';
import { UserProfileContext } from '../../context/userProfileContext';
import AutoCompleteSelect from '../../components/auto-complete-select/AutoCompleteSelect';
import { Footer } from 'rsuite';
import { useTranslation } from 'react-i18next';

import LogHistory from './LogHistory';

interface CreateTaskProps {
    show: boolean;
    onHide: () => void;
    onSubmit: (newUser: Task, oldUser?: Task) => void;
    task?: Task;
    changeLog?: Log[];
    users: User[];
}

const CreateTask = ({ show, onHide, onSubmit, task, changeLog, users }: CreateTaskProps) => {
    const { t } = useTranslation();
    const { client } = useContext(UserProfileContext).profileContextData;
    const api = new APICore();
    const isEditingOfExistingTask = task ? true : false;
    const [showHistory, setShowHistory] = useState<boolean>(false);
    const [currentTask, setCurrentTask] = useState<Task>(
        task || {
            _id: '',
            text: '',
            module: Modules.onboarding,
            user: api.getLoggedInUser().user,
            status: 'open',
            createdAt: new Date(),
            link: '',
            dueDate: new Date(),
            client: client,
        },
    );

    useEffect(() => {
        if (task) {
            setCurrentTask(task);
        }
    }, [task]);

    const onDateChange = (date: Date) => {
        setCurrentTask({ ...currentTask, dueDate: date });
    };

    const onChange = (event: any) => {
        const name = event.target.name;
        let value = event.target.value;
        console.log({ name, value });
        const tempTask: any = { ...currentTask };
        if (name === 'user') {
            value = users.find((user) => user._id === value);
        }
        tempTask[name] = value;
        setCurrentTask(tempTask);
    };

    const onUserSelect = (userId: string | null) => {
        const user = users.find((u) => u._id === userId)!;
        setCurrentTask({ ...currentTask, user });
    };

    const handleSaveClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (!currentTask) {
            return;
        }
        if (!currentTask.text) return alert('Task description is mandatory');
        onSubmit(currentTask, task);
    };

    const handleShowHistoryClick = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setShowHistory((p) => !p);
    };

    const findUserName = (userId: string | User) => {
        if (typeof userId !== 'string') {
            userId = userId._id;
        }
        const user = users.find((user) => user._id === userId);
        return user ? user.fullName : '';
    };

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size={showHistory && isEditingOfExistingTask ? 'xl' : undefined}
            >
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">{isEditingOfExistingTask ? 'Task Description' : 'Create task'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-3">
                    <div className="task-edit-form">
                        <FormInput
                            label="Task description"
                            type="textarea"
                            name="text"
                            defaultValue={currentTask?.text}
                            placeholder="Enter task description"
                            containerClass={'mb-3'}
                            onChange={onChange}
                        />
                        <AutoCompleteSelect
                            onSelect={onUserSelect}
                            toggleText={users.find((u) => u._id === currentTask?.user?._id)?.fullName || 'User'}
                            label="User"
                            placeholder={t('search by name...')}
                            options={userOptionsForSelect({ users, me: true, all: true })}
                            searchKey="label"
                            valueKey="value"
                            additionalClasses="mb-3"
                        />
                        {!isEditingOfExistingTask && (
                            <FormInput
                                label="Module"
                                type="select"
                                name="module"
                                value={currentTask?.module}
                                placeholder="select module"
                                containerClass={'mb-3'}
                                options={Object.entries(Modules).map((item) => ({
                                    label: camelCaseToText(item[0]),
                                    value: item[1],
                                }))}
                                onChange={onChange}
                            />
                        )}
                        {!isEditingOfExistingTask && (
                            <FormInput
                                label="Link"
                                type="text"
                                name="link"
                                value={currentTask?.link}
                                placeholder="select status"
                                containerClass={'mb-3'}
                                onChange={onChange}
                            />
                        )}
                        <div>Due Date</div>
                        <DatePicker
                            // showIcon
                            name="dueDate"
                            className="form-control date"
                            selected={currentTask?.dueDate}
                            onChange={onDateChange}
                        />
                    </div>

                    {changeLog && changeLog.length > 0 && showHistory && (
                        <div className="mb-3 mt-5">
                            <LogHistory changeLog={changeLog} findUserName={findUserName} />
                        </div>
                    )}
                </Modal.Body>
                <Footer>
                    <div className="text-end p-2 pe-3">
                        {changeLog && changeLog.length > 0 && (
                            <Button variant="primary" onClick={handleShowHistoryClick} className="waves-effect waves-light me-1">
                                {showHistory ? 'Hide' : 'Show'} History
                            </Button>
                        )}
                        <Button variant="success" onClick={handleSaveClick} className="waves-effect waves-light ">
                            Save
                        </Button>
                    </div>
                </Footer>
            </Modal>
        </>
    );
};
export default CreateTask;
