import { cancel } from 'redux-saga/effects';
import { InsurancePolicy } from './InsurancePolicy';

export enum InsuranceAppendixPremiumTerm {
    monthly = 'monthly',
    yearly = 'yearly',
}

export enum InsuranceAppendixRecommendation {
    noChange = 'noChange',
    change = 'change',
    cancel = 'cancel',
}

export enum healthInsuranceAppendixBranches {
    health = 'health',
    hardIllness = 'hardIllness',
    cancerIllness = 'cancerIllness',
    personalAccidents = 'personalAccidents',
}
export enum healthInsuranceAppendixBranchSections {
    transplants = 'transplants',
    medicines = 'medicines',
    medicines29th = 'medicines29th',
    surgeriesInIsraelRishonShekel = 'surgeriesInIsraelRishonShekel',
    surgeriesInIsraelSupplementingShaban = 'surgeriesInIsraelSupplementingShaban',
    surgeriesAbroad = 'surgeriesAbroad',
    ambulatory = 'ambulatory',
    ambulatoryExpansionForAChild = 'ambulatoryExpansionForAChild',
    rapidDiagnosis = 'rapidDiagnosis',
    personalAccompanyingDoctor = 'personalAccompanyingDoctor',
    complementaryMedicine = 'complementaryMedicine',
    onlineConsultationAndPremiumTreatments = 'onlineConsultationAndPremiumTreatments',
}
export enum lifeInsuranceAppendixBranches {
    risk = 'risk',
    mortgageRisk = 'mortgageRisk',
    workDisability = 'workDisability',
    insuranceUmbrella = 'insuranceUmbrella',
}
export enum riskAppendixBranchSections {
    disabilityFromAnAccident = 'disabilityFromAnAccident',
    releaseFromLossOfWorkingCapacity = 'releaseFromLossOfWorkingCapacity',
}
export enum mortgageRiskAppendixBranchSections {
    releaseFromLossOfWorkingCapacity = 'releaseFromLossOfWorkingCapacity',
}
export enum workDisabilityAppendixBranchSections {
    specificOccupation = 'specificOccupation',
    franchise = 'franchise',
    shorteningPeriodToThirtyDays = 'shorteningPeriodToThirtyDays',
    cancelingOffset = 'cancelingOffset',
    increasingUnderwriterCeiling = 'increasingUnderwriterCeiling',
    developingDisability = 'developingDisability',
    nursing = 'nursing',
}
export enum insuranceUmbrellaAppendixBranchSections {
    specificOccupation = 'specificOccupation',
    cancelingOffset = 'cancelingOffset',
    franchise = 'franchise',
    cancellationOfCertificationPeriod = 'cancellationOfCertificationPeriod',
    developingDisability = 'developingDisability',
    nursing = 'nursing',
}
export enum personalAccidentsAppendixBranchSections {
    occupationalDisability = 'occupationalDisability',
}

export function policyBranchToAppendixBranchMap(policy: InsurancePolicy | undefined) {
    if (!policy) return null;
    switch (policy.branch) {
        case 'healthInsurance':
            return healthInsuranceAppendixBranches;
        case 'lifeInsurance':
            return lifeInsuranceAppendixBranches;
        default:
            return null;
    }
}

export function appendixBranchToAppendixBranchSection(appendix: InsuranceAppendix | undefined) {
    if (!appendix) return null;
    switch (appendix.branch) {
        case 'health':
            return healthInsuranceAppendixBranchSections;
        case 'risk':
            return riskAppendixBranchSections;
        case 'mortgageRisk':
            return mortgageRiskAppendixBranchSections;
        case 'workDisability':
            return workDisabilityAppendixBranchSections;
        case 'insuranceUmbrella':
            return insuranceUmbrellaAppendixBranchSections;
        case 'personalAccidents':
            return personalAccidentsAppendixBranchSections;
        default:
            return '';
    }
}

export interface InsuredPerson {
    name: string;
    idNumber?: string;
    clientId?: string;
}
export interface discountData {
    premiumBeforeDiscount: number;
    discountTerm: string;
    currentDiscountPercent: number;
    discountExplanation: string;
    premiumAfterDiscount: number;
}
export interface InsuranceAppendix {
    _id: string;
    isNew: boolean;
    client?: string;
    clientId: string;
    insuredPerson?: {
        name: string;
        idNumber: string;
    };
    type: string;
    replaceAppendix?: string; //res
    replaceAppendixId?: string; //req
    recommendation: InsuranceAppendixRecommendation;
    policyId: string; //id for req
    policy?: string; //id  for res
    insuredList: InsuredPerson[];
    branch: string;
    branchSection?: string;
    premium: number;
    premiumDiscountData: discountData;
    premiumTerm: InsuranceAppendixPremiumTerm;
    insuranceStartDate: string;
    insuranceEndDate?: string;
    issueDate: string;
}
