import React, { useState } from 'react';
import i18n from 'i18next';
import { Option } from '../interfaces/Option';
import { Dropdown } from 'react-bootstrap';

const DropDownColumn = (options: Option[], onSelected: (e: any, item: any) => any) => {
    // return (params: any) => <FormInput
    //             label=""
    //             defaultValue={params.cell.value}
    //             type="select"
    //             name="task-status"
    //             placeholder="Status"
    //             containerClass={'mb-3'}
    //             options={options}
    //             onSelected={(e: any) => {e.nativeEvent.stopImmediatePropagation();e.preventDefault(); e.stopPropagation(); onSelected(e.target.value, params.row.original)}}
    //         />

    return (params: any) => {
        const onClick = (e: any, value: string | number) => {
            e.nativeEvent.stopImmediatePropagation();
            e.preventDefault();
            e.stopPropagation();
            // setValue(value);
            onSelected(value, params.row.original);
        };

        const getSelected = () => {
            return options.find((item: Option) => item.value === params.cell.value)?.label;
        };

        return (
            <Dropdown className="select-action" align={i18n.dir() === 'ltr' ? 'start' : 'end'} onClick={(e) => e.stopPropagation()}>
                <Dropdown.Toggle variant="light">
                    {getSelected()}
                    <i className="mdi mdi-chevron-down"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {options.map((item, i) => (
                        <Dropdown.Item key={item.label} onClick={(e: any) => onClick(e, item.value)}>
                            {item.label}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    };
};

export default DropDownColumn;
