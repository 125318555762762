import { APICore, API_URL, INVESTMENT_URL } from './apiCore';
//INVESTMENT_URL is on port 4018
const api = new APICore();

const createInvestment = (clientId: string) => {
    return api.create(`${INVESTMENT_URL}/investments`, { clientId });
};

const editInvestment = (investmentId: string, body: any) => {
    const relativeUrl = `/investments/${investmentId}`;
    return api.update(`${INVESTMENT_URL}${relativeUrl}`, body);
};

const getInvestmentStatuses = () => {
    return api.get(`${INVESTMENT_URL}/investments/statuses`);
};

export { getInvestmentStatuses, createInvestment, editInvestment };
