import { ClientFile } from '../interfaces/ClientFile';

const getFileType = (file: ClientFile) => {
    let fileType;
    if (file.mimeType.includes('.')) {
        const splits = file.mimeType.split('.');
        fileType = splits[splits.length - 1];
    }
    if (file.mimeType.includes('/')) {
        const splits = file.mimeType.split('/');
        fileType = splits[splits.length - 1];
    }
    fileType = file.mimeType.substring(file.mimeType.length - 4);
    return fileType;
};

export { getFileType };
