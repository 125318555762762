export class ApiError extends Error {
    status: number;
    errorCode: string;
    message: string;
    reqId: string;
    serviceName: string;
    extraData?: any;

    constructor(error: any) {
        super(error.message ? error.message : error.errorCode);
        this.status = error.status;
        this.errorCode = error.errorCode;
        this.message = error.message || 'No message provided';
        this.reqId = error.reqId;
        this.serviceName = error.serviceName;
        this.extraData = error.extraData;
    }
}
