import React, { useRef, useEffect, forwardRef, useState } from 'react';
import { useTable, useSortBy, usePagination, useRowSelect, useGlobalFilter, useAsyncDebounce, useExpanded, Row } from 'react-table';
import classNames from 'classnames';

// components
import Pagination from './Pagination';
import i18n from 'i18next';

interface GlobalFilterProps {
    preGlobalFilteredRows: any;
    globalFilter: any;
    setGlobalFilter: any;
    searchBoxClass: any;
}

// Define a default UI for filtering
const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter, searchBoxClass }: GlobalFilterProps) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = useState<any>(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <div className={classNames(searchBoxClass)}>
            <span className="d-flex align-items-center">
                Search :{' '}
                <input
                    type="search"
                    value={value || ''}
                    onChange={(e: any) => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} records...`}
                    className="form-control w-auto ms-1"
                />
            </span>
        </div>
    );
};

interface IndeterminateCheckboxProps {
    indeterminate: any;
    children?: React.ReactNode;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, IndeterminateCheckboxProps>(({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef: any = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <>
            <div className="form-check">
                <input type="checkbox" className="form-check-input" ref={resolvedRef} {...rest} />
                <label htmlFor="form-check-input" className="form-check-label"></label>
            </div>
        </>
    );
});

interface TableColumn {
    Header: string;
    accessor: string;
    sort?: boolean;
    Cell?: any;
    className?: string;
}
interface TableProps {
    isSearchable?: boolean;
    isSortable?: boolean;
    pagination?: boolean;
    paginationProps?: {
        onPageSelected: (page: number) => void;
        totalItems: number;
        numberOfPages: number;
        startPage?: number;
    };
    ExpandedRow?: React.ComponentType<any>;
    isSelectable?: boolean;
    isExpandable?: boolean;
    sizePerPageList?: {
        text: string;
        value: number;
    }[];
    columns: TableColumn[];
    rowNumbering?: boolean | ((row: any) => string | number);
    data: any[];
    pageSize?: number;
    searchBoxClass?: string;
    tableClass?: string;
    theadClass?: string;
    onRowClicked?: (params: any) => void;
    cellClick?: number[];
    onSortChanged?: (params?: any) => void;
    onPageSelected?: (page: number) => void;
    rowClass?: (row: any) => string;
    rowStyle?: (row: any, rowIndex: number, isExpanded?: boolean) => any;
    total?: number;
    currentSort?: string;
    initiallyExpandedRows?: any[];
    rowEqualityFn?: (rowA: any, rowB: any) => boolean;
    translationFunction?: (key: string) => string;
    paginationDirection?: 'ltr' | 'rtl';
    expandArrowDirection?: 'left' | 'right';
}

const Table = (props: TableProps) => {
    const [selectedRow, setSelectedRow] = useState<Row<any>>();
    const [expandedRows, setExpandedRows] = useState<Set<any>>(new Set());
    const [sortBy, setSortBy] = useState<string>('');
    const isSearchable = props['isSearchable'] || false;
    const isSortable = props['isSortable'] || false;
    const pagination = props['pagination'] || false;
    const isSelectable = props['isSelectable'] || false;
    const isExpandable = props['isExpandable'] || false;
    const sizePerPageList = props['sizePerPageList'] || [];
    const onRowClicked = (row: any) => {
        if (props.onRowClicked) props.onRowClicked(row);

        setSelectedRow(row);
    };
    const paginationProps = props.paginationProps;

    let otherProps: any = {};

    if (isSearchable) {
        otherProps['useGlobalFilter'] = useGlobalFilter;
    }
    if (isSortable) {
        otherProps['useSortBy'] = useSortBy;
    }
    if (isExpandable) {
        otherProps['useExpanded'] = useExpanded;
    }
    if (pagination) {
        otherProps['usePagination'] = usePagination;
    }
    if (isSelectable) {
        otherProps['useRowSelect'] = useRowSelect;
    }

    const dataTable = useTable(
        {
            columns: props['columns'],
            data: props['data'],
            initialState: { pageSize: props['pageSize'] || 10 },
        },
        otherProps.hasOwnProperty('useGlobalFilter') && otherProps['useGlobalFilter'],
        otherProps.hasOwnProperty('useSortBy') && otherProps['useSortBy'],
        otherProps.hasOwnProperty('useExpanded') && otherProps['useExpanded'],
        otherProps.hasOwnProperty('usePagination') && otherProps['usePagination'],
        otherProps.hasOwnProperty('useRowSelect') && otherProps['useRowSelect'],
        (hooks) => {
            isSelectable &&
                hooks.visibleColumns.push((columns: any) => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }: any) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }: any) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        ),
                    },
                    ...columns,
                ]);

            props.rowNumbering &&
                hooks.visibleColumns.push((columns: any) => [
                    {
                        id: 'rowNumbering',
                        Header: '#',
                        Cell: ({ row }) => {
                            if (typeof props.rowNumbering === 'function') {
                                const index = props.rowNumbering(row);
                                return <span>{index}</span>;
                            }
                            return row.index + 1;
                        },
                    },
                    ...columns,
                ]);

            isExpandable &&
                hooks.visibleColumns.push((columns: any) => [
                    // Let's make a column for selection
                    {
                        // Build our expander column
                        id: 'expander', // Make sure it has an ID
                        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => <span {...getToggleAllRowsExpandedProps()}></span>,
                        Cell: ({ row }) => {
                            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                            // to build the toggle for expanding a row
                            const handleRowExpanded = () => {
                                const isExpanded = row.isExpanded;
                                if (isExpanded) {
                                    const newExpandedRows = new Set(expandedRows);
                                    newExpandedRows.delete(row.id);
                                    setExpandedRows(newExpandedRows);
                                } else {
                                    const newExpandedRows = new Set(expandedRows);
                                    newExpandedRows.add(row.id);
                                    setExpandedRows(newExpandedRows);
                                }
                                row.toggleRowExpanded();
                            };
                            return (
                                <span
                                    {...row.getToggleRowExpandedProps({
                                        style: {
                                            // We can even use the row.depth property
                                            // and paddingLeft to indicate the depth
                                            // of the row
                                            paddingLeft: `${row.depth * 2}rem`,
                                        },
                                    })}
                                    onClick={handleRowExpanded}
                                >
                                    {row.isExpanded ? (
                                        <i className="mdi mdi-arrow-down" />
                                    ) : props.expandArrowDirection && props.expandArrowDirection === 'right' ? (
                                        <i className="mdi mdi-arrow-right" />
                                    ) : (
                                        <i className="mdi mdi-arrow-left" />
                                    )}
                                </span>
                            );
                        },
                    },
                    ...columns,
                ]);
        },
    );

    useEffect(() => {
        if (props.initiallyExpandedRows && props.initiallyExpandedRows.length > 0) {
            const newExpandedRows = new Set(expandedRows);
            props.initiallyExpandedRows.forEach((rowId) => {
                newExpandedRows.add(rowId);
            });
            setExpandedRows(newExpandedRows);
        }
    }, [props.initiallyExpandedRows]);

    useEffect(() => {
        if (dataTable && dataTable.rows && dataTable.rows.length > 0 && expandedRows.size > 0) {
            dataTable.rows.forEach((row) => {
                const isExpanded = expandedRows.has(row.id) && !row.isExpanded;
                if (isExpanded && row.toggleRowExpanded) {
                    row.toggleRowExpanded();
                }
            });
        }
    }, [expandedRows, dataTable.rows]);

    let rows = pagination ? dataTable.page : dataTable.rows;
    // setSelectedRow(rows[0]);
    const getRowClass = (row: any, isSelected: boolean): string => {
        // debugger;
        let rowClass = isSelected ? 'selected ' : '';
        if (props.rowClass) rowClass += props.rowClass(row);
        return rowClass;
    };

    const changeSortBy = (e: MouseEvent, column: any) => {
        // column.getHeaderProps(column.sort && column.getSortByToggleProps()).onClick(e);
        if (column.sort === false) {
            return;
        }
        let newSortBy;
        if (sortBy == column.id) newSortBy = '-' + column.id;
        else if (sortBy == '-' + column.id) newSortBy = column.id;
        else newSortBy = column.id;

        console.log(column.id, newSortBy);
        setSortBy(newSortBy);

        if (props.onSortChanged) props.onSortChanged(newSortBy);
    };

    const getHeaderClass = (column: any): string => {
        let headerClass = `column-name-${column.id.replace('.', '-')}`;

        if (column.headerClassName) headerClass += ' ' + column.headerClassName;

        if (column.sort !== false) {
            if (column.canSort) headerClass += ' sortable';

            if (sortBy === '-' + column.id) headerClass += ' sorting_desc';
            else if (sortBy === column.id) headerClass += ' sorting_asc';
        }

        return headerClass;
    };

    return (
        <>
            {isSearchable && (
                <GlobalFilter
                    preGlobalFilteredRows={dataTable.preGlobalFilteredRows}
                    globalFilter={dataTable.state.globalFilter}
                    setGlobalFilter={dataTable.setGlobalFilter}
                    searchBoxClass={props['searchBoxClass']}
                />
            )}

            <div className="table-responsive">
                <table {...dataTable.getTableProps()} className={classNames('table table-centered react-table', props['tableClass'])}>
                    <thead className={props['theadClass']}>
                        {(dataTable.headerGroups || []).map((headerGroup: any) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {(headerGroup.headers || []).map((column: any) => {
                                    return (
                                        <th
                                            {...column.getHeaderProps(column.sort && column.getSortByToggleProps())}
                                            className={getHeaderClass(column)}
                                            // className={classNames({
                                            //     sorting_desc: column.isSortedDesc === true,
                                            //     sorting_asc: column.isSortedDesc === false,
                                            //     sortable: column.sort === true,
                                            // })}
                                            onClick={(e: MouseEvent) => changeSortBy(e, column)}
                                        >
                                            {props.translationFunction ? props.translationFunction(column.render('Header')) : column.render('Header')}
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...dataTable.getTableBodyProps()}>
                        {(rows || []).map((row: any, i: number) => {
                            dataTable.prepareRow(row);
                            const isSelected =
                                !!props.onRowClicked && ((selectedRow && selectedRow.index == row.index) || (!selectedRow && row.index == 0));

                            return (
                                <React.Fragment key={i}>
                                    <tr
                                        {...row.getRowProps()}
                                        onClick={() => (!props.cellClick ? onRowClicked(row) : null)}
                                        className={getRowClass(row, isSelected)}
                                        style={props.rowStyle ? props.rowStyle(row, i, row.isExpanded) : {}}
                                    >
                                        {(row.cells || []).map((cell: any, index: number) => {
                                            return (
                                                <td
                                                    onClick={() => (props.cellClick && props.cellClick.includes(index) ? onRowClicked(row) : null)}
                                                    {...cell.getCellProps([
                                                        {
                                                            className: cell.column.className,
                                                        },
                                                    ])}
                                                >
                                                    {cell.render('Cell')}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                    {row.isExpanded && props.ExpandedRow ? (
                                        <tr>
                                            <td colSpan={row.cells?.length || 7}>
                                                <props.ExpandedRow row={row} />
                                            </td>
                                        </tr>
                                    ) : null}
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {paginationProps && !!props.data.length && (
                <Pagination
                    tableProps={dataTable}
                    {...paginationProps}
                    sizePerPageList={sizePerPageList}
                    paginationDirection={props.paginationDirection ?? 'ltr'}
                />
            )}
        </>
    );
};

export default Table;
