import { Genders, MaritalStatuses } from '../pages/business-dashboard/consts';
import { camelCaseToText } from '../utils/camelCaseToText';

/**
 *
 */

/** Consts and Types */
export const allowedFileClassifications = [
    'donationReceipt',
    'newIdentificationCardFront',
    'newIdentificationCardFrontSpouse',
    'oldIdentificationCard',
    'oldIdentificationCardSpouse',
    'newIdentificationCardBack',
    'newIdentificationCardBackSpouse',
    'identificationCardAttachment',
    'identificationCardAttachmentSpouse',
    'form106',
    'form106Spouse',
    'academicDegreeApproval',
    'form867',
    'annualLifeInsuranceReport',
    'academicDegreeApprovalSpouse',
    'nationalInsuranceBenefit',
    'nationalInsuranceBenefitSpouse',
    'newOrReturningImmigrant',
    'newOrReturningImmigrantSpouse',
    'militaryDischarge',
    'militaryDischargeSpouse',
] as const;

type AllowedFileClassifications = (typeof allowedFileClassifications)[number];

export const CertificateTypes = {
    FIRST: 'first',
    SECOND: 'second',
    THIRD: 'third',
    CERTIFICATION_STUDIES: 'certification-studies',
} as const;

export const ImmigrationTypes = {
    newImmigrant: 'newImmigrant',
    returningResident: 'returningResident',
} as const;

export const ServiceTypes = {
    military: 'military',
    civil: 'civil',
} as const;

export const BenefitTypes = {
    unemployment: 'unemployment',
    motherhood: 'motherhood',
    reserves: 'reserves',
    workAccident: 'workAccident',
} as const;

type CertificateType = (typeof CertificateTypes)[keyof typeof CertificateTypes];
type ImmigrationType = (typeof ImmigrationTypes)[keyof typeof ImmigrationTypes];
type ServiceType = (typeof ServiceTypes)[keyof typeof ServiceTypes];
type BenefitType = (typeof BenefitTypes)[keyof typeof BenefitTypes];

/** Interfaces */
export interface donationReceiptData {
    fileId: string;
    institutionId: number;
    institutionName: string;
    receiptNumber: string;
    amount: number;
    date: Date;
    isValid: boolean;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

export interface form106Data {
    fileId: string;
    employerTaxId: number;
    year: number;
    idNumber: string;
    firstName: string;
    lastName: string;
    amountByEmployerCompletionOfCompensationObligation: number;
    amountDeductedFromSalaryOrLifeInsurance: number;
    amountDeductedFromSalaryToPensionFundSelfEmployed180135: number;
    amountDeductedFromSalaryToPensionFundSelfEmployed269268: number;
    amountOfEmployerPaymentToProvidentFund: number;
    amountOfPaymentForPensionInsurance: number;
    annuityAmountBeforeCapitalDiscounting: number;
    basisForLossOfWorkFitness: number;
    basisForStudyFund: number;
    compensationTotal258272: number;
    contributionsToApprovedInstitutions: number;
    deathBenefitAmount: number;
    deathTaxableIncome: number;
    deductionA47030089: number;
    employerContributionForLossOfWorkFitness: number;
    employerName: string;
    exemptCapital: number;
    exemptGrants: number;
    exemptPaymentsIncludingExemptAllowanceAndComp: number;
    incomeExemptFromTaxSection9: number;
    insuredIncome: number;
    maxCeilingClause11: number;
    membershipFeeEmployeeOrganization: number;
    salaryAndPayments158172: number;
    salaryThatRequiresInsurancePremiums: number;
    salaryWagesInIndustrialPlant: number;
    salaryWomenLaborLaw218219: number;
    salaryWomenLaborLaw244245: number;
    proceedsFromSellingSecuritiesBefore2003: number;
    proceedsFromSellingSecuritiesToWorkerWorkIncome: number;
    proceedsFromSellingSecuritiesToWorkersAfter2003: number;
    profitFromSellingSecuritiesBefore2003: number;
    profitFromSellingSecuritiesToWorkersAfter2003: number;
    specialSalarySoldier197198: number;
    temporarySalaryReduction012011: number;
    totalTaxDeduction042: number;
    workerContributionsToAnnuity: number;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

export interface newIdentificationCardFrontData {
    fileId: string;
    idNumber: string;
    firstName: string;
    lastName: string;
    issueDate: Date;
    birthDate: Date;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

export interface oldIdentificationCardData {
    fileId: string;
    idNumber: string;
    firstName: string;
    lastName: string;
    issueDate: Date;
    birthDate: Date;
    gender: (typeof Genders)[keyof typeof Genders];
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

export interface identificationCardAttachmentData {
    fileId: string;
    idNumber: string;
    firstName: string;
    lastName: string;
    issueDate: Date;
    maritalStatus: (typeof MaritalStatuses)[keyof typeof MaritalStatuses];
    gender: (typeof Genders)[keyof typeof Genders];
    spouse: {
        firstName: string;
        lastName: string;
        idNumber: string;
    };
    children: [
        {
            firstName: string;
            lastName: string;
            idNumber: string;
            birthDate: Date;
            gender: (typeof Genders)[keyof typeof Genders];
        },
    ];
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

export interface academicDegreeApprovalData {
    fileId: string;
    certificateType: CertificateType;
    entitlementDate: Date;
    isValid: boolean;
    rawData: string;
    autolabeled: {
        [key: string]: any;
    }[];
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

interface form867Data {
    year: number;
    grossIncome: number;
    totalProfit: number;
    deductableLoses: number;
    taxPaid25Percent: number;
    taxPaid15Percent: number;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

interface annualLifeInsuranceReportData {
    year: number;
    lifeInsurancePremium: number;
    workDisabilityPremium: number;
    name: string;
    idNumber: string;
    isValid: boolean;
    rawData: string;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

interface nationalInsuranceBenefitData {
    benefitType: BenefitType;
    year: number;
    taxDeductionAmount: number;
    benefitAmount: number;
    isValid: boolean;
    rawData: string;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

interface newOrReturningImmigrantData {
    fileId: string;
    date: Date;
    immigrationStatus: ImmigrationType;
    idNumber: string;
    isValid: boolean;
    rawData: string;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

interface militaryDischargeData {
    fileId: string;
    period: {
        to: Date;
        from: Date;
    }[];
    serviceType: ServiceType;
    name: string;
    idNumber: string;
    isValid: boolean;
    rawData: string;
    autolabeled: {
        [key: string]: any;
    };
    approvedByUser: string;
    fileClassification: AllowedFileClassifications;
}

/** Templates */
const donationReceiptFormInfo = {
    institutionId: {
        type: 'number',
        label: 'Institution ID',
    },
    institutionName: {
        type: 'text',
        label: 'Institution Name',
    },
    receiptNumber: {
        type: 'text',
        label: 'Receipt Number',
    },
    amount: {
        type: 'number',
        label: 'Amount',
    },
    date: {
        type: 'date',
        label: 'Date',
    },
    isValid: {
        type: 'checkbox',
        label: 'Approved donation',
    },
};

const newIdentificationCardFrontFormInfo = {
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    firstName: {
        type: 'text',
        label: 'First Name',
    },
    lastName: {
        type: 'text',
        label: 'Last Name',
    },
    issueDate: {
        type: 'date',
        label: 'Issue Date',
    },
    birthDate: {
        type: 'date',
        label: 'Birth Date',
    },
};

const oldIdentificationCardFormInfo = {
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    firstName: {
        type: 'text',
        label: 'First Name',
    },
    lastName: {
        type: 'text',
        label: 'Last Name',
    },
    issueDate: {
        type: 'date',
        label: 'Issue Date',
    },
    birthDate: {
        type: 'date',
        label: 'Birth Date',
    },
    gender: {
        type: 'select',
        label: 'Gender',
        options: Object.entries(Genders).map(([key, value]) => ({
            value,
            label: camelCaseToText(key),
        })),
    },
};

const identificationCardAttachmentFormInfo = {
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    firstName: {
        type: 'text',
        label: 'First Name',
    },
    lastName: {
        type: 'text',
        label: 'Last Name',
    },
    issueDate: {
        type: 'date',
        label: 'Issue Date',
    },
    maritalStatus: {
        type: 'select',
        label: 'Marital Status',
        options: Object.entries(MaritalStatuses).map(([key, value]) => ({
            value,
            label: camelCaseToText(key),
        })),
    },
    // gender: {
    //     type: 'select',
    //     label: 'Gender',
    //     options: Object.entries(Genders).map(([key, value]) => ({ value, label: camelCaseToText(key) })),
    // },
    'spouse.firstName': {
        type: 'text',
        label: "Spouse's First Name",
    },
    'spouse.lastName': {
        type: 'text',
        label: "Spouse's Last Name",
    },
    'spouse.idNumber': {
        type: 'text',
        label: "Spouse's ID Number",
    },
    children: {
        type: 'dynamic',
        label: 'Children',
        entryStructure: {
            firstName: {
                type: 'text',
                label: "Child's First Name",
            },
            lastName: {
                type: 'text',
                label: "Child's Last Name",
            },
            idNumber: {
                type: 'text',
                label: "Child's ID Number",
            },
            birthDate: {
                type: 'date',
                label: "Child's Birth Date",
            },
            gender: {
                type: 'select',
                label: "Child's Gender",
                options: Object.entries(Genders).map(([key, value]) => ({
                    value,
                    label: camelCaseToText(key),
                })),
            },
        },
        initialEntries: [],
    },
};

const form106FormInfo = {
    employerTaxId: {
        type: 'number',
        label: 'Employer Tax ID',
    },
    year: {
        type: 'number',
        label: 'Year',
    },
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    firstName: {
        type: 'text',
        label: 'First Name',
    },
    lastName: {
        type: 'text',
        label: 'Last Name',
    },
    employerName: {
        type: 'text',
        label: 'Employer Name',
    },
    salaryAndPayments158172: {
        type: 'number',
        label: 'Salary and Payments (158-172)',
    },
    insuredIncome: {
        type: 'number',
        label: 'Insured Income (244-245)',
    },
    salaryWomenLaborLaw244245: {
        type: 'number',
        label: 'Salary Women Labor Law (244-245)',
    },
    basisForStudyFund: {
        type: 'number',
        label: 'Basis for Study Fund',
    },
    salaryWomenLaborLaw218219: {
        type: 'number',
        label: 'Salary Women Labor Law (218-219)',
    },
    amountOfEmployerPaymentToProvidentFund: {
        type: 'number',
        label: 'Employer Retirement Contribution',
    },
    workerContributionsToAnnuity: {
        type: 'number',
        label: 'Worker Contributions to Annuity',
    },
    totalTaxDeduction042: {
        type: 'number',
        label: 'Total Tax Deduction (042)',
    },
    amountDeductedFromSalaryToPensionFundSelfEmployed180135: {
        type: 'number',
        label: 'Amount Deducted from Salary to Pension Fund Self Employed (180-135)',
    },
    amountDeductedFromSalaryToPensionFundSelfEmployed269268: {
        type: 'number',
        label: 'Amount Deducted from Salary to Pension Fund Self Employed (269-268)',
    },
    compensationTotal258272: {
        type: 'number',
        label: 'Compensation Total (258-272)',
    },
    contributionsToApprovedInstitutions: {
        type: 'number',
        label: 'Contributions to Approved Institutions',
    },
    exemptPaymentsIncludingExemptAllowanceAndComp: {
        type: 'number',
        label: 'Exempt Payments Including Exempt Allowance and Comp',
    },
    incomeExemptFromTaxSection9: {
        type: 'number',
        label: 'Income Exempt from Tax (Section 9)',
    },
    salaryWagesInIndustrialPlant: {
        type: 'number',
        label: 'Salary Wages in Industrial Plant',
    },
    proceedsFromSellingSecuritiesToWorkerWorkIncome: {
        type: 'number',
        label: 'Proceeds from Selling Securities to Worker Work Income',
    },
    proceedsFromSellingSecuritiesToWorkersAfter2003: {
        type: 'number',
        label: 'Proceeds from Selling Securities to Workers After 2003',
    },
    profitFromSellingSecuritiesBefore2003: {
        type: 'number',
        label: 'Profit from Selling Securities Before 2003',
    },
    profitFromSellingSecuritiesToWorkersAfter2003: {
        type: 'number',
        label: 'Profit from Selling Securities to Workers After 2003',
    },
    specialSalarySoldier197198: {
        type: 'number',
        label: 'Special Salary Soldier (197-198)',
    },
    temporarySalaryReduction012011: {
        type: 'number',
        label: 'Temporary Salary Reduction (011-012)',
    },
    amountDeductedFromSalaryOrLifeInsurance: {
        type: 'number',
        label: 'Amount Deducted from Salary or Life Insurance',
    },
};

const academicDegreeApprovalFormInfo = {
    certificateType: {
        type: 'select',
        label: 'Certificate Type',
        options: Object.values(CertificateTypes).map((value) => ({
            value,
            label: camelCaseToText(value),
        })),
    },
    entitlementDate: {
        type: 'date',
        label: 'Entitlement Date',
    },
    isValid: {
        type: 'checkbox',
        label: 'Is Valid',
    },
};

const form867FormInfo = {
    year: {
        type: 'number',
        label: 'Year',
    },
    deductableLoses: {
        type: 'number',
        label: 'Deductable Loses',
    },
    dividendTax25: { type: 'number', label: 'Dividend Tax 25%' },
    dividendTax20: { type: 'number', label: 'Dividend Tax 20%' },
    dividendTax15: { type: 'number', label: 'Dividend Tax 15%' },
    dividendTax10: { type: 'number', label: 'Dividend Tax 10%' },
    interestTax25: { type: 'number', label: 'Interest Tax 25%' },
    interestTax20: { type: 'number', label: 'Interest Tax 20%' },
    interestTax15: { type: 'number', label: 'Interest Tax 15%' },
    interestTax10: { type: 'number', label: 'Interest Tax 10%' },
    capitalGainTax25: { type: 'number', label: 'Capital Gain Tax 25%' },
    capitalGainTax20: { type: 'number', label: 'Capital Gain Tax 20%' },
    capitalGainTax15: { type: 'number', label: 'Capital Gain Tax 15%' },
    capitalGainTax10: { type: 'number', label: 'Capital Gain Tax 10%' },
    capitalGainsAbroad: { type: 'number', label: 'Capital Gains Abroad' },
    taxPaidAbroad: { type: 'number', label: 'Tax Paid Abroad' },
};

const annualLifeInsuranceReportFormInfo = {
    year: {
        type: 'number',
        label: 'Year',
    },
    lifeInsurancePremium: {
        type: 'number',
        label: 'Life Insurance Premium',
    },
    workDisabilityPremium: {
        type: 'number',
        label: 'Work Disability Premium',
    },
    name: {
        type: 'text',
        label: 'Name',
    },
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    isValid: {
        type: 'checkbox',
        label: 'Is Valid',
    },
};

const nationalInsuranceBenefitFormInfo = {
    benefitType: {
        type: 'select',
        label: 'Benefit Type',
        options: Object.values(BenefitTypes).map((value) => ({
            value,
            label: camelCaseToText(value),
        })),
    },
    year: {
        type: 'number',
        label: 'Year',
    },
    taxDeductionAmount: {
        type: 'number',
        label: 'Tax Deduction Amount',
    },
    benefitAmount: {
        type: 'number',
        label: 'Benefit Amount',
    },
    isValid: {
        type: 'checkbox',
        label: 'Is Valid',
    },
};

const newOrReturningImmigrantFormInfo = {
    date: {
        type: 'date',
        label: 'Date',
    },
    immigrationStatus: {
        type: 'select',
        label: 'Immigration Status',
        options: Object.values(ImmigrationTypes).map((value) => ({
            value,
            label: camelCaseToText(value),
        })),
    },
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    isValid: {
        type: 'checkbox',
        label: 'Is Valid',
    },
};

const militaryDischargeFormInfo = {
    serviceType: {
        type: 'select',
        label: 'Service Type',
        options: Object.values(ServiceTypes).map((value) => ({
            value,
            label: camelCaseToText(value),
        })),
    },
    name: {
        type: 'text',
        label: 'Name',
    },
    idNumber: {
        type: 'text',
        label: 'ID Number',
    },
    isValid: {
        type: 'checkbox',
        label: 'Is Valid',
    },
    period: {
        type: 'dynamic',
        label: 'Period',
        entryStructure: {
            from: {
                type: 'date',
                label: 'From',
            },
            to: {
                type: 'date',
                label: 'To',
            },
        },
        initialEntries: [],
    },
};

export type ValidFileClassificationDataForLabeling =
    | donationReceiptData
    | form106Data
    | newIdentificationCardFrontData
    | oldIdentificationCardData
    | identificationCardAttachmentData
    | academicDegreeApprovalData
    | form867Data
    | annualLifeInsuranceReportData
    | nationalInsuranceBenefitData
    | newOrReturningImmigrantData
    | militaryDischargeData
    | null;

export {
    donationReceiptFormInfo,
    newIdentificationCardFrontFormInfo,
    oldIdentificationCardFormInfo,
    identificationCardAttachmentFormInfo,
    form106FormInfo,
    academicDegreeApprovalFormInfo,
    form867FormInfo,
    annualLifeInsuranceReportFormInfo,
    nationalInsuranceBenefitFormInfo,
    newOrReturningImmigrantFormInfo,
    militaryDischargeFormInfo,
};
