import React, { useContext, useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { UserProfileContext } from '../../../context/userProfileContext';
import Details from './Details';
import ProfileFiles from '../ProfileFiles';
import TaxReturnCompleteForm from './tax-return-complete-form';
import { TaxReturn } from '../../../interfaces/taxReturn';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useQueryStringSync from '../../../hooks/useQueryStringSync';

const TabsData = [{ name: 'Details' }, { name: 'Files' }, { name: 'Forms' }];

const MainData = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const selectedFileId = searchParams.get('fileId');
    const selectedTab = searchParams.get('activeTab');
    const [activeTab, setActiveTab] = useState(selectedFileId ? 'Files' : selectedTab || 'Details');

    const { profileContextData } = useContext(UserProfileContext);
    const { currentRound, client } = profileContextData;

    const [spouseTaxReturn, setSpouseTaxReturn] = useState('');

    useEffect(() => {
        // if this tax return is actually the spouse's tax return
        if (currentRound && (currentRound as TaxReturn).spouse?.client === client._id) {
            setSpouseTaxReturn((currentRound as TaxReturn).client);
        }
    }, [currentRound]);

    const getTabComponent = (tabName: string) => {
        if (!client) return;
        switch (tabName) {
            case 'Details':
                return <Details />;

            case 'Files':
                return <ProfileFiles />;

            case 'Forms':
                return <TaxReturnCompleteForm taxReturn={currentRound as TaxReturn} />;

            default:
                break;
        }
    };

    useQueryStringSync({ activeTab });

    const goToSpouse = () => {
        const url = `/clients/profile?clientId=${spouseTaxReturn}&module=taxReturn&activeTab=Details`;
        console.log(url);
        window.location.href = url;
    };

    return (
        <div className="main-data-wrapper">
            {spouseTaxReturn ? (
                <button className="btn btn-primary" onClick={goToSpouse}>
                    Spouse tax return
                </button>
            ) : (
                <Tabs defaultActiveKey="Details" transition={false} activeKey={activeTab} onSelect={(k: any) => setActiveTab(k)}>
                    {client &&
                        TabsData.map((tab: any, index: number) => {
                            return (
                                <Tab key={index} eventKey={tab.name} title={tab.name}>
                                    {activeTab === tab.name ? getTabComponent(tab.name) : <></>}
                                </Tab>
                            );
                        })}
                </Tabs>
            )}
        </div>
    );
};

export default MainData;
