import { useState, useEffect } from 'react';
import { getTaxData } from '../../../../../utils/api/taxReturn';
import { TaxReturn } from '../../../../../interfaces/taxReturn';
import globalErrorHandler from '../../../../../utils/globalErrorHandler';

const useFetchTaxData = (taxReturn: TaxReturn) => {
    const taxDataId = taxReturn?.taxData as string;
    const [taxData, setTaxData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getTaxData(taxDataId);
                setTaxData(response.taxData);
            } catch (error: any) {
                globalErrorHandler(error);
            }
        };

        if (taxDataId) {
            fetchData();
        }
    }, [taxDataId]);

    return { taxData, setTaxData };
};

export default useFetchTaxData;
