import { useContext, useEffect, useState } from 'react';
import InsuranceProductsMenuHeader from './InsuranceProductsMenuHeader';
import CreateNewInsuranceProductModal from './insuranceCreationAndEdit/CreateNewInsuranceProductModal';
import { InsurancePolicy } from '../../../interfaces/InsurancePolicy';
import { InsuranceAppendix } from '../../../interfaces/InsuranceAppendix';
import { policyColumns } from './insuranceProductColumns';
import { getInsurancePolicies } from '../../../utils/api/insurancePolicy';
import { getInsuranceAppendices } from '../../../utils/api/insuranceAppendix';
import { UserProfileContext } from '../../../context/userProfileContext';
import { createNewAppendix, createNewPolicy } from './insuranceCreationAndEdit/appendixAndPolicyCreationFunctions';
import { ProductsContext } from './insuranceProductsContext';
import AppendixCreationModal from './insuranceCreationAndEdit/AppendixCreationAndEditingModal';
import { initialInsuranceAppendixState } from './insuranceCreationAndEdit/initialPolicyAndAppendixState';
import { getClientFamilyMembers } from '../../../utils/getClientFamilyMembers';
import Table from '../../../components/Table';
import PolicyRowTableExpansion from './PolicyRowTableExpansion';
import { getCompanies } from '../../../utils/api/companies';
import { Company } from '../../../interfaces/Company';
import { useEnumsContext } from '../../../context/EnumsContext';
import globalErrorHandler from '../../../utils/globalErrorHandler';
import { Insurance } from '../../../interfaces/Insurance';
import { camelCaseToText } from '../../../utils/camelCaseToText';

const InsuranceProducts = () => {
    const [sortBy, setSortBy] = useState<string>('');
    const { profileContextData } = useContext(UserProfileContext);
    const { client, currentRound } = profileContextData;
    const insurance: Insurance = profileContextData.currentRound as Insurance;

    const [showNewProductCreationModal, setShowNewProductCreationModal] = useState(false);
    const [showNewAppendixCreationModal, setShowNewAppendixCreationModal] = useState(false);

    const [insurancePolicies, setInsurancePolicies] = useState<InsurancePolicy[]>([]);
    const [insuranceAppendices, setInsuranceAppendices] = useState<InsuranceAppendix[]>([]);

    const [filterByPolicyNumbers, setFilterByPolicyNumbers] = useState<string[]>([]);
    const [filteredInsurancePolicies, setFilteredInsurancePolicies] = useState<InsurancePolicy[]>([]);

    const [initiallyExpandedRows, setInitiallyExpandedRows] = useState<string[]>([]);

    const [consts, setConsts] = useState<any>({});
    const enums = useEnumsContext();

    useEffect(() => {
        const assignConsts = async () => {
            if (enums && enums.RecommendationReasons && enums.InsuranceRecommendation) {
                try {
                    const { companies } = await getCompanies({ type: 'insurance' });
                    const allNames = companies.map((company: Company) => company.name);

                    const newConsts = {
                        companyNames: allNames,
                        RecommendationReasons: enums.RecommendationReasons,
                        InsuranceRecommendation: enums.InsuranceRecommendation,
                        familyMembers: getClientFamilyMembers(client, true),
                    };

                    setConsts(newConsts);
                } catch (error) {
                    globalErrorHandler(error);
                }
            }
        };

        assignConsts();
    }, [enums]);

    // Data creation
    const fullProductCreationHandler = async (
        newInsurancePolicy: InsurancePolicy,
        newInsuranceAppendices: InsuranceAppendix[],
        originalPolicyId?: string,
    ) => {
        try {
            const newPolicy = await createNewPolicy(newInsurancePolicy, originalPolicyId);
            setInsurancePolicies((prevPolicies) => {
                const updatedPolicies = [...prevPolicies, { ...newPolicy, policyItReplaces: originalPolicyId }].map((policy: InsurancePolicy) =>
                    policy._id! === originalPolicyId
                        ? {
                              ...policy,
                              replacementPolicies: [...(policy.replacementPolicies || []), newPolicy._id!],
                          }
                        : policy,
                );
                return updatedPolicies;
            });
            const appendicesWithPolicies = await Promise.all(
                newInsuranceAppendices.map(async (appendix) => {
                    try {
                        const appendixWithPolicyId = {
                            ...appendix,
                            policy: newPolicy._id,
                            policyId: newPolicy._id,
                        };
                        const newAppendix = await createNewAppendix(appendixWithPolicyId);
                        return newAppendix;
                    } catch (error) {
                        globalErrorHandler(error);
                    }
                }),
            );
            setInsuranceAppendices([...insuranceAppendices, ...appendicesWithPolicies]);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const appendixCreationHandler = async (newInsuranceAppendix: InsuranceAppendix) => {
        try {
            const newAppendix = await createNewAppendix(newInsuranceAppendix);
            setInsuranceAppendices([...insuranceAppendices, newAppendix]);
            return newAppendix;
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const getClientsPolicies = async () => {
        try {
            const params = { insuranceId: currentRound?._id };
            const response = await getInsurancePolicies(params);
            const policies = response.policies;
            policies.forEach((policy: InsurancePolicy) => {
                policy.clientId = policy.client ?? client._id;
            });
            setInsurancePolicies(updatePoliciesWithReplacementInfo(policies));
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    // Data fetching
    useEffect(() => {
        // Get insurance policies
        getClientsPolicies();
    }, []);

    useEffect(() => {
        // Get insruance appendices
        const params = { clientId: client._id };
        const getClientsAppendices = async () => {
            try {
                const response = await getInsuranceAppendices(params);
                const appendices = response.appendices;
                appendices.forEach((appendix: InsuranceAppendix) => {
                    appendix.clientId = appendix.client ?? client._id;
                });
                setInsuranceAppendices(appendices);
            } catch (error) {
                globalErrorHandler(error);
            }
        };
        getClientsAppendices();
    }, []);

    // Data filtering
    useEffect(() => {
        const filteredPolicies = insurancePolicies.filter(
            (policy) => filterByPolicyNumbers.includes(policy._id!) || filterByPolicyNumbers.includes(policy.policyItReplaces!),
        );
        const sortedFilteredPolicies = sortPoliciesByReplacementOrder(filteredPolicies);
        setFilteredInsurancePolicies(sortedFilteredPolicies);
        // debugger;
    }, [filterByPolicyNumbers, insurancePolicies]);

    // Data sorting
    const handleSortChanged = (newSortBy: string) => {
        if (newSortBy === sortBy) {
            if (newSortBy[0] === '-') {
                newSortBy = newSortBy.slice(1);
            } else {
                newSortBy = `-${newSortBy}`;
            }
        }
        const direction = newSortBy[0] === '-' ? 'desc' : 'asc';
        const sortedFilteredPolicies = [...filteredInsurancePolicies].sort((a: any, b: any) => {
            const field = newSortBy.slice(newSortBy[0] === '-' ? 1 : 0);
            let comparison = 0;

            if (field === 'policyNumber') {
                const aValue = parseInt(a[field], 10);
                const bValue = parseInt(b[field], 10);
                comparison = aValue - bValue;
            }

            return direction === 'asc' ? comparison : -comparison;
        });
        setSortBy(newSortBy);
        setFilteredInsurancePolicies(sortedFilteredPolicies);
    };

    // Table styling
    const rowStyle = (row: any, rowIndex: number, isExpanded?: boolean) => {
        const policy = row.original;

        const styles: any = {
            height: '70px',
        };

        if (policy.policyItReplaces) {
            styles.backgroundColor = 'rgb(198, 236, 227, 0.65)';
        }

        return styles;
    };

    // utils
    const findAllAppendicesOfPolicy = (policyId: string) => {
        return insuranceAppendices.filter((appendix) => appendix.policy === policyId);
    };

    const ExpandedRowHandler = ({ row }: any) => {
        const [relevantAppendices, setRelevantAppendices] = useState<InsuranceAppendix[]>([]);
        useEffect(() => {
            const appendices = findAllAppendicesOfPolicy(row.original._id!);
            setRelevantAppendices(appendices);
        }, [insuranceAppendices]);

        if (row.original?._id) {
            return <PolicyRowTableExpansion policy={row.original} appendices={relevantAppendices} setAppendices={setInsuranceAppendices} />;
        }
    };

    useEffect(() => {
        setFilterByPolicyNumbers(insurancePolicies.map((policy) => policy._id!));
    }, [insurancePolicies]);

    const rowNumbering = (row: any) => {
        const policy = row.original;
        const index = (insurancePolicies.findIndex((p) => p._id === policy._id) + 1).toString();
        if (policy.policyItReplaces) {
            return '*';
        }
        return index.toString();
    };

    const showCreateClientAggentAppointment = insurancePolicies.some((policy) => policy.isAgentAppointed);
    const showCreateSpouseAggentAppointment = insurancePolicies.some((policy) =>
        policy.insuredList?.some((insured) => insured.idNumber === client.spouse?.idNumber && policy.isAgentAppointed),
    );

    return (
        <ProductsContext.Provider
            value={{
                insurancePolicies,
                setInsurancePolicies: (policies: InsurancePolicy[]) => setInsurancePolicies(updatePoliciesWithReplacementInfo(policies)),
                insuranceAppendices,
                setInsuranceAppendices,
                createInsuranceProduct: fullProductCreationHandler,
                client,
                consts,
                initiallyExpandedRows,
                setInitiallyExpandedRows,
            }}
        >
            <div className="overflow-x-hidden">
                <InsuranceProductsMenuHeader
                    setShowNewProductCreationModal={setShowNewProductCreationModal}
                    clientId={client._id}
                    clientFullName={client.firstName + ' ' + client.lastName}
                    getClientsPolicies={getClientsPolicies}
                    showCreateClientAggentAppointment={showCreateClientAggentAppointment}
                    showCreateSpouseAggentAppointment={showCreateSpouseAggentAppointment}
                />

                <CreateNewInsuranceProductModal
                    show={showNewProductCreationModal}
                    onHide={() => setShowNewProductCreationModal(false)}
                    productCreationHandler={fullProductCreationHandler}
                />
                <AppendixCreationModal
                    show={showNewAppendixCreationModal}
                    onHide={() => setShowNewAppendixCreationModal(false)}
                    onSubmit={appendixCreationHandler}
                    currentAppendixState={initialInsuranceAppendixState}
                />
                <div className='badges'>
                    {" "}
                    {insurance.insurancePreferredTypes?.map((insurancePreferredType) => <>
                        <span className={`badge bg-success float-Start`}>{camelCaseToText(insurancePreferredType)}</span>{" "}</>
                    )}
                </div>
                

                {insurancePolicies.length > 0 && (
                    <Table
                        columns={policyColumns}
                        data={filteredInsurancePolicies}
                        isExpandable={true}
                        ExpandedRow={ExpandedRowHandler}
                        rowNumbering={rowNumbering}
                        isSortable={true}
                        pagination={false}
                        onSortChanged={handleSortChanged}
                        tableClass="table-nowrap products-table"
                        rowStyle={rowStyle}
                        expandArrowDirection="right"
                        initiallyExpandedRows={initiallyExpandedRows}
                    />
                )}
            </div>
        </ProductsContext.Provider>
    );
};

export default InsuranceProducts;

const updatePoliciesWithReplacementInfo = (policies: InsurancePolicy[]): InsurancePolicy[] => {
    const policiesWithReplacementInfo = policies.map((currentPolicy) => {
        const policyItReplaces = policies.find(
            (otherPolicy) => otherPolicy.replacementPolicies && otherPolicy.replacementPolicies.includes(currentPolicy._id!),
        );

        if (policyItReplaces) {
            currentPolicy.policyItReplaces = policyItReplaces._id!;
        }

        return currentPolicy;
    });

    return policiesWithReplacementInfo;
};

const sortPoliciesByReplacementOrder = (policies: any) => {
    const policyMap = new Map();
    const result: any = [];

    policies.forEach((policy: any) => {
        policyMap.set(policy._id, policy);
    });

    const addPolicyAndReplacements = (policy: any) => {
        result.push(policy);

        policy.replacementPolicies.forEach((replacementId: any) => {
            const replacementPolicy = policyMap.get(replacementId);
            if (replacementPolicy) {
                addPolicyAndReplacements(replacementPolicy);
            }
        });
    };

    policies.forEach((policy: any) => {
        if (!policy.policyItReplaces) {
            addPolicyAndReplacements(policy);
        }
    });

    return result;
};
