import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

interface LoadingButtonProps extends React.ComponentProps<typeof Button> {
    loading?: boolean;
    loadingText?: string;
    loadedText?: string;
}

const LoadingButton = ({ children, loading, loadingText = 'Loading...', loadedText, ...props }: LoadingButtonProps) => {
    return (
        <Button {...props} disabled={loading}>
            {loading ? (
                <>
                    {loadingText} <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="ms-1" />
                </>
            ) : (
                loadedText || children
            )}
        </Button>
    );
};

export default LoadingButton;
