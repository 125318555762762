import React from 'react';
import { User } from '../interfaces/User';
import { APICore } from './api/apiCore';

interface UserOptionsForSelectProps {
    users: User[];
    me?: boolean;
    all?: boolean;
}

const userOptionsForSelect = ({ users, me = false, all = false }: UserOptionsForSelectProps) => {
    const api = new APICore();

    let options = [];

    if (me) {
        const loggedInUserId = api.getLoggedInUser().user._id;
        options.push({
            label: 'Me',
            value: loggedInUserId,
        });
        users = users.filter((user) => user._id !== loggedInUserId);
    }

    if (all) {
        options.push({
            label: 'All',
            value: '',
        });
    }

    const alphabeticallySortedUsers = users
        .map((user) => ({
            label: user.fullName || '',
            value: user._id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    options = [...options, ...alphabeticallySortedUsers];

    return options;
};

export default userOptionsForSelect;
