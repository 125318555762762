import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput, VerticalForm } from '../../components';
import { camelCaseToText } from '../../utils/camelCaseToText';
import { InvestmentPolicyType, ProductTransfer, TransferStatuses } from '../../interfaces/ProductTransfer';
import {
    archiveSigningDocument,
    editProductTransfer,
    getEditProductTransferData,
    getProductTransfers,
    getSigningUrl,
} from '../../utils/api/productTransfers';
import { Company } from '../../interfaces/Company';
import { getDiscountForm, getHanmaka } from '../../utils/api/products';
import { PensionProduct, PensionProductTypes } from '../../interfaces/PensionProduct';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';
import { Link } from 'react-router-dom';
import './product.scss';
import { InvestmentPolicies } from '../../interfaces/Fund';
import { ApiError } from '../../utils/api/ApiError';
import { SignedDocument } from '../../interfaces/SignedDocument';
import { APICore } from '../../utils/api/apiCore';
import DualSigningButton from '../../components/dualSigningButton/DualSigningButton';
import Loader from '../../components/Loader';
import useInterval from '../../utils/useInterval';
import { structuralClone } from '../../helpers/misc';
import { banks } from '../../utils/banks';
import ConfirmModal from '../../components/ConfirmModal';
import CubesModal from '../profile/cubes/CubesModal';
import EmployerAutocomplete from '../../components/EmployerAutocomplete';
import { Employer } from '../../interfaces/Employer';
import { EmploymentStatuses } from '../../interfaces/Client';
import { useTranslation } from 'react-i18next';
import globalErrorHandler from '../../utils/globalErrorHandler';
import { Investment } from '../../interfaces/Investment';
import '../../utils';

interface EditProductTransferProps {
    show: boolean;
    onHide?: () => void;
    onSave?: (productTransfer: ProductTransfer) => void;
    productTransfer: ProductTransfer;
    product: PensionProduct;
}

interface EditDataResult {
    product: PensionProduct;
    productTypes: string[];
    companies: Company[];
    kycLink?: string;
}

const EditProductTransfer = ({ show, onHide, onSave, product, productTransfer }: EditProductTransferProps) => {
    /*
    form validation schema
    */
    const { t } = useTranslation('product');
    const [data, setData] = useState<EditDataResult>();
    const [newProductTransfer, setNewProductTransfer] = useState<ProductTransfer | undefined>();
    const [investmentPolicies, setInvestmentPolicies] = useState<any>([]);
    const [signedDocument, setSignedDocument] = useState<SignedDocument>();
    const [isLoading, setIsLoading] = useState(false);
    const [isPension, setIsPension] = useState(false);
    const [showEmployerAutocomplete, setShowEmployerAutocomplete] = useState(false);    
    const [showConfirmIrregularCommissions, setShowConfirmIrregularCommissions] = useState(false);
    const [formEnabled, setFormEnabled] = useState(false);
    const [showCubes, setShowCubes] = useState(false);
    const [showAddEmployer, setShowAddEmployer] = useState(false);
    const [newEmployerName, setNewEmployerName] = useState('');
    const [tempStateForNumberInput, setTempStateForNumberInput] = useState<string[]>(new Array(10).fill(''));

    const setTempStateForNumberInputOnSpecificIndex = (index: number, value: string) => {
        setTempStateForNumberInput((p) => [...p.slice(0, index), value, ...p.slice(index + 1)]);
    };

    // update the signing link
    useInterval(
        async () => {
            try {
                const data = await getEditProductTransferData(productTransfer._id);
                setSignedDocument(data.signedDocument);
            } catch (err) {
                globalErrorHandler(err);
            }
        },
        9 * 60 * 1000,
    );

    const handleInvestmentPolicies = (newProductTransfer: any) => {
        const investmentPolicies = [];
        newProductTransfer.investmentPolicies.forEach((investmentPolicy: any) => {
            if (investmentPolicy.percentages.compensation) {
                investmentPolicies.push({
                    percentage: investmentPolicy.percentages.compensation,
                    policy: investmentPolicy.policy,
                    type: InvestmentPolicyType.Compensation,
                });
            }

            if (investmentPolicy.percentages.severance) {
                investmentPolicies.push({
                    percentage: investmentPolicy.percentages.severance,
                    policy: investmentPolicy.policy,
                    type: InvestmentPolicyType.Severance,
                });
            }
        });

        if (investmentPolicies.length === 0) {
            investmentPolicies.push({
                percentage: 1,
                policy: newProductTransfer.investmentPolicies[0].policy,
                type: InvestmentPolicyType.Compensation,
            });
        }

        newProductTransfer.newInvestmentPolicies = investmentPolicies;

        return newProductTransfer;
    };

    const addInvestmentPolicy = () => {
        if (!newProductTransfer) return;
        const tmp = { ...newProductTransfer };
        const newPolicy = { ...tmp.newInvestmentPolicies[0] };
        tmp.newInvestmentPolicies.push(newPolicy);

        // handleInvestmentPolicies(newProductTransfer);
        setNewProductTransfer(tmp);
    };

    const getData = async () => {
        const data = await getEditProductTransferData(productTransfer._id);
        setData(data);
        setInvestmentPolicies(data.investmentPolicies);

        const newProductTransfer = { ...data.product.productTransfer };
        newProductTransfer.companyId = typeof productTransfer.company === 'string' ? productTransfer.company : productTransfer.company._id;

        handleInvestmentPolicies(newProductTransfer);

        setNewProductTransfer(newProductTransfer);
        setSignedDocument(data.signedDocument);
        setIsPension(newProductTransfer.productType === PensionProductTypes.comprehensivePensionFund || newProductTransfer.productType === PensionProductTypes.complementaryPensionFund);        
    };

    useEffect(() => {
        if (show) {
            getData();
        }
    }, [show]);

    useEffect(() => {
        if (newProductTransfer)
            setFormEnabled(newProductTransfer.status === TransferStatuses.Initial || newProductTransfer.status === TransferStatuses.StartTransfer);
    }, [newProductTransfer]);

    const resetProduct = () => {
        onSaveProductTransfer(productTransfer, true);
    };

    const onSubmit = () => {
        if (!arePoliciesNormalized()) {
            alert('Investment policies percentages must sum up to 100%');
            return;
        }
        if (newProductTransfer) onSaveProductTransfer(newProductTransfer, false);
    };

    const downloadDiscountDoc = async () => {
        if (!product) return;

        const result = await getDiscountForm(product._id);
        const blob = await result.blob();
        const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', `discount-${product.productType}-${product._id}.docx`);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    };

    const generateDocs = async (preview: boolean) => {
        if (!newProductTransfer) return;

        const result = await getSigningUrl(newProductTransfer._id, preview);
        if (preview) return result;

        return result.signedDocument;
    };

    const onError = (err: any) => {
        console.log(err);
        if (err.status === 400) alert(err.message);
        else if (err.status === 402) alert('Missing employer details');
        else if (err.status === 409) alert(err.response.data.message);
        else if (err.status === 405) {
            if (window.confirm('Fill KYC form first')) {
                openKYC();
            }
        } else globalErrorHandler(err);
    };

    const insuranceRiskOptions = () => {
        if (newProductTransfer?.productType === PensionProductTypes.comprehensivePensionFund) {
            return initialInsurancePercentage.risk.filter((option) => option !== 0);
        } else {
            return initialInsurancePercentage.risk;
        }
    };

    const insuranceDisabilityOptions = () => {
        if (newProductTransfer?.productType === PensionProductTypes.comprehensivePensionFund) {
            return initialInsurancePercentage.disability.filter((option) => option !== 0);
        } else {
            return initialInsurancePercentage.disability;
        }
    };

    const investmentOnlyProducts = [PensionProductTypes.investmentFund, PensionProductTypes.savingsPolicy];
    const insuranceIncludedProducts = [PensionProductTypes.comprehensivePensionFund, PensionProductTypes.complementaryPensionFund];
    const depositCommissionProducts = [PensionProductTypes.comprehensivePensionFund, PensionProductTypes.complementaryPensionFund];
    const cubesProducts = [
        PensionProductTypes.comprehensivePensionFund, 
        PensionProductTypes.complementaryPensionFund,
        PensionProductTypes.studyFund,
        PensionProductTypes.providentFund
    ];

    const onChange = (e: any, isCheckbox?: boolean) => {
        let name = e.target.name;
        let value = isCheckbox ? e.target.checked : e.target.value;

        let tempProductTransfer = structuralClone(newProductTransfer);

        if (!tempProductTransfer) return;

        if (name === 'companyId') {
            const company = data?.companies.find((c) => c._id === value);
            tempProductTransfer.company = company;
        }

        if (name === 'productType') {
            if (investmentOnlyProducts.includes(value)) {
                tempProductTransfer.employmentStatus = EmploymentStatuses.get('selfEmployed');
                
            }
        }

        if (name.includes('.')) {
            const list = name.split('.');
            const parent: string = list[0];
            const child: string = list[1];
            const grandChild: string = list[2];

            if (parent === 'template') {
                const index: number = Number(list[1]);

                if (!isNaN(index)) {
                    tempProductTransfer.templates[index].isUsed = e.target.checked;
                }
            } else {
                if (!tempProductTransfer[parent]) tempProductTransfer[parent] = {};

                if (list.length === 2) tempProductTransfer[parent][child] = value;
                else if (list.length === 3) {
                    if (!tempProductTransfer[parent][child]) tempProductTransfer[parent][child] = {};

                    tempProductTransfer[parent][child][grandChild] = value;
                }
            }
        } else if (e.target.type === 'checkbox') {
            tempProductTransfer[name] = e.target.checked;
        } else {
            tempProductTransfer[name] = value;
        }

        setNewProductTransfer(tempProductTransfer);
        setIsPension(
            tempProductTransfer.productType === PensionProductTypes.comprehensivePensionFund || tempProductTransfer.productType === PensionProductTypes.complementaryPensionFund,
        );
    };

    const selectOtherOption = (event: any, item: any) => {
        event.preventDefault();

        if (!formEnabled) return;

        if (!newProductTransfer) return;

        const productTransfer: ProductTransfer = { ...newProductTransfer };
        productTransfer.companyId = item.company._id;
        productTransfer.commissions = { ...item.commissions };
        productTransfer.company = item.company;
        setNewProductTransfer(productTransfer);
    };

    const openKYC = () => {
        window.open(data?.kycLink, '_blank');
    };

    const archiveDoc = async () => {
        

        if (!window.confirm('Are you sure you want to archive the signatures?')) return;

        try {
            await archiveSigningDocument(productTransfer._id);
            await getData();
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    const onSaveProductTransfer = async (
        productTransfer: ProductTransfer,
        reset: boolean,
        keepEditProductTransferShown?: boolean,
        confirmIrregularaliries?: boolean,
    ) => {
        const handleInvestmentPolices = () => {
            const list = [];

            for (const investmentPolicy of productTransfer.newInvestmentPolicies) {
                let item: any = list.find((item) => item.policy === investmentPolicy.policy);
                if (!item) {
                    item = {
                        policy: investmentPolicy.policy,
                        percentages: {
                            compensation: 0,
                            severance: 0,
                        },
                    };
                    list.push(item);
                }
                if (investmentPolicy.type === InvestmentPolicyType.Compensation) item.percentages.compensation += investmentPolicy.percentage;
                else item.percentages.severance += investmentPolicy.percentage;
            }
            return list;
        };

        setIsLoading(true);

        try {
            let params;
            if (!reset) {
                params = {
                    confirmIrregularaliries,
                    companyId: typeof productTransfer.company === 'string' ? productTransfer.company : (productTransfer.company as Company)._id,
                    employmentStatus: productTransfer.employmentStatus,
                    monthlyDepositAmount: productTransfer.monthlyDepositAmount ? productTransfer.monthlyDepositAmount : undefined,
                    commissions: productTransfer.commissions,
                    insurance: productTransfer.insurance,
                    targetProductType: productTransfer.productType,
                    status: productTransfer.status,
                    employerDepositPercentage: productTransfer.employerDepositPercentage ? productTransfer.employerDepositPercentage : undefined,
                    employeeDepositPercentage: productTransfer.employeeDepositPercentage ? productTransfer.employeeDepositPercentage : undefined,
                    severancePercentage: productTransfer.severancePercentage ? productTransfer.severancePercentage : undefined,
                    salary: productTransfer.salary ? productTransfer.salary : undefined,
                    isActive: productTransfer.isActive,
                    extendedDisabilityInsurance: productTransfer.extendedDisabilityInsurance,
                    templates: productTransfer.templates,
                    bankAccount: productTransfer.bankAccount,
                    employerId: productTransfer.employer?._id,
                    hasEmployerInForms: productTransfer.hasEmployerInForms,
                    investmentPolicies: handleInvestmentPolices(),
                    occupation: productTransfer.occupation,
                    profession: productTransfer.profession,
                };
            }

            const result = await editProductTransfer(productTransfer._id, params);
            if (onSave) onSave(result.productTransfer);

            setShowConfirmIrregularCommissions(false);
        } catch (err: any) {
            if (err.status === 402 || err.status === 406) alert('Investment policy not found for the selected company');
            if (err.status === 410) {
                setShowConfirmIrregularCommissions(true);
            } else {
                globalErrorHandler(err);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const getConfirmModalBody = (productTransfer: ProductTransfer) => {
        const text = `${t('the chosen managment fee')} (${productTransfer.commissions.deposit}/${productTransfer.commissions.savings}) ${t('do not exists for the current product in the system')} ${productTransfer.productType}`;
        return text;
    };

    const onStatusChange = async (e: any) => {
        if (!newProductTransfer) return;

        const tempProductTransfer = { ...newProductTransfer };
        tempProductTransfer.status = e.target.value;
        setNewProductTransfer(tempProductTransfer);

        try {
            const result = await editProductTransfer(productTransfer._id, {
                status: e.target.value,
            });
        } catch (err: any) {
            globalErrorHandler(err);
        }
    };

    const handleNewEmployer = (name: string) => {
        setNewEmployerName(name);
        setShowEmployerAutocomplete(false);
        setShowAddEmployer(true);
    };

    const onExistingEmployerSelected = async (employer?: Employer) => {
        setShowEmployerAutocomplete(false);
        if (!employer || !newProductTransfer) return;

        const productTransfer: ProductTransfer = { ...newProductTransfer };
        productTransfer.employer = employer;

        setNewProductTransfer(productTransfer);

        const result = await editProductTransfer(productTransfer._id, {
            employerId: employer._id,
        });
    };

    const onInvestmentPolicyChange = (index: number, policy: string) => {
        if (newProductTransfer) {
            const tmp = { ...newProductTransfer };
            tmp.newInvestmentPolicies[index].policy = policy;
            setNewProductTransfer(tmp);
        }
    };

    const isOptionForInvestmentPolicyDisabled = (policy: string) => {
        if (!newProductTransfer || !data) return true;

        const severanceAllowedTypes = [PensionProductTypes.comprehensivePensionFund, PensionProductTypes.complementaryPensionFund, PensionProductTypes.providentFund];
        const isSeverancePolicy = policy === InvestmentPolicyType.Severance;
        const isCompensationPolicy = policy === InvestmentPolicyType.Compensation;

        if (isSeverancePolicy) {
            return !severanceAllowedTypes.includes(newProductTransfer.productType);
        }

        if (isCompensationPolicy) {
            return false;
        }

        return false;
    };

    const onInvestmentPolicyTypeChange = (index: number, newType: string) => {
        if (newProductTransfer) {
            const updatedProductTransfer = { ...newProductTransfer };
            updatedProductTransfer.newInvestmentPolicies[index].type = newType as InvestmentPolicyType;
            setNewProductTransfer(updatedProductTransfer);
        }
    };

    const displayPercentage = (index: number, value: number) => {
        if (tempStateForNumberInput[index]) {
            return `${tempStateForNumberInput[index]}%`;
        }
        const percentage = value * 100;
        const displayValue = percentage % 1 !== 0 ? percentage.toFixed(2) : Math.round(percentage);
        return `${displayValue}%`;
    };

    const onInvestmentPolicyPercentageChange = (index: number, newPercentage: string) => {
        const sanitizedInput = newPercentage.replace('%', '').trim();

        const newPercentageNumber = Number(sanitizedInput);
        if (isNaN(newPercentageNumber)) {
            setTempStateForNumberInputOnSpecificIndex(index, '');
            return;
        }

        if (newProductTransfer) {
            const updatedProductTransfer = { ...newProductTransfer };
            updatedProductTransfer.newInvestmentPolicies[index].percentage = newPercentageNumber / 100;
            setNewProductTransfer(updatedProductTransfer);
            setTempStateForNumberInputOnSpecificIndex(index, '');
        }
    };


    const arePoliciesNormalized = () => {
        if (!newProductTransfer || !newProductTransfer.newInvestmentPolicies) {
            return true;
        }

        const sumsByType = newProductTransfer.newInvestmentPolicies.reduce((acc: { [key: string]: number }, policy) => {
            const { type, percentage } = policy;
            const lowerCaseType = type.toLowerCase();
            acc[lowerCaseType] = (acc[lowerCaseType] || 0) + percentage;
            return acc;
        }, {});

        return Object.values(sumsByType).every((sum) => Math.round(sum * 100) === 100);
    };

    const relevantInvesmentPolicies = () => {
        if (investmentPolicies?.length) {
            return investmentPolicies.map((policy: any) => ({
                label: policy.name,
                value: policy.policy,
            }));
        } else {
            let policies = Object.entries(InvestmentPolicies).map((item: string[]) => ({
                label: item[0],
                value: item[1],
            }));
            if (newProductTransfer?.productType && [PensionProductTypes.studyFund, PensionProductTypes.investmentFund].includes(newProductTransfer.productType)) {
                policies = policies.filter(
                    (policy) =>
                        policy.value !== InvestmentPolicies.age50 &&
                        policy.value !== InvestmentPolicies.age60 &&
                        policy.value !== InvestmentPolicies.age50to60,
                );
            }
            return policies;
        }
    };

    return (
        <>
            <CubesModal show={showCubes} onHide={() => setShowCubes(false)} product={product} />
            <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">Edit Product Transfer</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <div>
                        {isLoading && <Loader />}

                        <div>
                            <DualSigningButton
                                onArchive={productTransfer.status !== TransferStatuses.Initial ? archiveDoc : undefined}
                                text="Generate signing links"
                                previewMessage={newProductTransfer?.status === TransferStatuses.Initial ? 'Product status is initial' : undefined}
                                signedDocument={signedDocument}
                                isEnabled={formEnabled && productTransfer.status === TransferStatuses.StartTransfer}
                                generateSigningLink={generateDocs}
                                onError={onError}
                            />
                        </div>

                        <div>
                            {data && data.kycLink ? (
                                <span className="left download link" onClick={openKYC}>
                                    {t('know the client')}
                                </span>
                            ) : null}
                        </div>
                    </div>

                    {newProductTransfer && data ? (
                        <>
                            <div className="form">
                                {formEnabled ? (
                                    <FormInput
                                        label="Transfer status"
                                        type="select"
                                        name="status"
                                        value={newProductTransfer.status}
                                        onChange={onStatusChange}
                                        placeholder="Status"
                                        containerClass={'mb-3'}
                                        options={[
                                            { label: 'Initial', value: TransferStatuses.Initial },
                                            {
                                                label: 'Start transfer',
                                                value: TransferStatuses.StartTransfer,
                                            },
                                        ]}
                                    />
                                ) : (
                                    <div>
                                        <div>Transfer status:</div>
                                        <div>
                                            <b>{camelCaseToText(newProductTransfer.status)}</b>
                                        </div>
                                        <br />
                                    </div>
                                )}
                            </div>

                            <div>
                                <FormInput
                                    label="Product is active"
                                    type="checkbox"
                                    name="isActive"
                                    checked={newProductTransfer.isActive}
                                    onChange={onChange}
                                    disabled={!formEnabled}
                                    placeholder="activation status"
                                    containerClass={'mb-3 inlineBlock'}
                                />

                                {cubesProducts.includes(productTransfer.productType) && newProductTransfer.isActive && newProductTransfer.status !== TransferStatuses.Initial &&
                                    <Button
                                        className="right pointer"                                        
                                        onClick={() => setShowCubes(true)}>
                                        <i className="mdi mdi-table-edit"/>{t('cubes form')}
                                    </Button>
                                }
                            </div>

                            

                            <FormInput
                                label="Product type"
                                type="select"
                                name="productType"
                                value={newProductTransfer.productType}
                                onChange={onChange}
                                placeholder="Product type"
                                disabled={!formEnabled}
                                containerClass={'mb-3'}
                                options={
                                    data
                                        ? data.productTypes.map((productType: string) => ({
                                              label: camelCaseToText(productType),
                                              value: productType,
                                          }))
                                        : []
                                }
                            />

                            <FormInput
                                label="Company"
                                type="select"
                                name="companyId"
                                value={newProductTransfer.companyId}
                                onChange={onChange}
                                placeholder="Company"
                                disabled={!formEnabled}
                                containerClass={'mb-3'}
                                options={data.companies.map((company: Company) => ({
                                    label: company.name,
                                    value: company._id,
                                }))}
                            />

                            <div>
                                {newProductTransfer.newInvestmentPolicies.map((investmentPolicy: any, i: number) => (
                                    <div key={i}>
                                        <label>
                                            Investment policy -
                                            <select
                                                style={{
                                                    border: 'none',
                                                    marginLeft: '5px',
                                                }}
                                                value={investmentPolicy.type}
                                                disabled={!formEnabled}
                                                onChange={(e) => onInvestmentPolicyTypeChange(i, e.target.value)}
                                            >
                                                {Object.values(InvestmentPolicyType).map((type: string) => (
                                                    <option disabled={isOptionForInvestmentPolicyDisabled(type)} key={type} value={type}>
                                                        {camelCaseToText(type)}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                        <input
                                            style={{
                                                width: `${Math.max(50, displayPercentage(i, investmentPolicy.percentage).length * 10)}px`,
                                                border: 'none',
                                                marginLeft: '15px',
                                                fontWeight: '600',
                                            }}
                                            type="text"
                                            value={displayPercentage(i, investmentPolicy.percentage)}
                                            disabled={!formEnabled}
                                            onChange={(e) => setTempStateForNumberInputOnSpecificIndex(i, e.target.value.replace('%', '').trim())}
                                            onBlur={(e) => onInvestmentPolicyPercentageChange(i, e.target.value)}
                                        />
                                        <FormInput
                                            type="select"
                                            value={investmentPolicy.policy}
                                            name="investmentPolicies.policy"
                                            onChange={(e: any) => onInvestmentPolicyChange(i, e.target.value)}
                                            placeholder="Investment policy"
                                            disabled={!formEnabled}
                                            containerClass={'mb-3'}
                                            options={relevantInvesmentPolicies()}
                                        />
                                    </div>
                                ))}
                            </div>

                            {newProductTransfer.investmentPolicies.length < 4 && (
                                <Button className="mb-3" onClick={addInvestmentPolicy}>
                                    Add Investment Policy
                                </Button>
                            )}

                            <div>
                                {newProductTransfer.insurance?.disabilityPercentage !== undefined ? (
                                    <FormInput
                                        label="Disability insurance (%)"
                                        type="select"
                                        containerClass={!isPension ? 'hidden' : 'half'}
                                        name="insurance.disabilityPercentage"
                                        onChange={onChange}
                                        value={newProductTransfer.insurance.disabilityPercentage}
                                        disabled={!formEnabled}
                                        options={insuranceDisabilityOptions().map((val: number | string) => ({
                                            label: typeof val === 'string' ? val : val + '%',
                                            value: val.toString(),
                                        }))}
                                    />
                                ) : (
                                    <></>
                                )}

                                {newProductTransfer.insurance?.riskPercentage !== undefined ? (
                                    <FormInput
                                        label="Risk insurance (%)"
                                        type="select"
                                        containerClass={!isPension ? 'hidden' : 'half'}
                                        name="insurance.riskPercentage"
                                        value={newProductTransfer.insurance.riskPercentage}
                                        onChange={onChange}
                                        disabled={!formEnabled}
                                        options={insuranceRiskOptions().map((val: number | string) => ({
                                            label: typeof val === 'string' ? val : val + '%',
                                            value: val.toString(),
                                        }))}
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>

                            {newProductTransfer.insurance?.riskPercentage !== undefined && insuranceIncludedProducts.includes(productTransfer.productType) && (
                                <div>
                                    <FormInput
                                        label="Give up spouse insurance"
                                        type="checkbox"
                                        name="insurance.giveUpSpouseInsurance"
                                        checked={newProductTransfer.insurance.giveUpSpouseInsurance}
                                        onChange={(e: any) => onChange(e, true)}
                                        disabled={!formEnabled}
                                        placeholder="activation status"
                                        containerClass={'half'}
                                    />

                                    <FormInput
                                        label="Give up spouse and child insurance"
                                        type="checkbox"
                                        name="insurance.giveUpSpouseAndChildInsurance"
                                        checked={newProductTransfer.insurance.giveUpSpouseAndChildInsurance}
                                        onChange={(e: any) => onChange(e, true)}
                                        disabled={!formEnabled}
                                        placeholder="activation status"
                                        containerClass={'half'}
                                    />
                                </div>
                            )}

                            <div>
                                {!investmentOnlyProducts.includes(newProductTransfer.productType) && <FormInput
                                    label="Employment Status"
                                    type="select"
                                    name="employmentStatus"
                                    value={newProductTransfer.employmentStatus}
                                    onChange={onChange}
                                    placeholder="Employment Status"
                                    disabled={!formEnabled}
                                    containerClass={'mb-3'}
                                    options={EmploymentStatuses.map((item: string) => ({
                                        label: camelCaseToText(item),
                                        value: item,
                                    }))}
                                />}                                

                                {investmentOnlyProducts.includes(newProductTransfer.productType) && 
                                    <>
                                        <FormInput
                                                label="Occupation"
                                                type="string"
                                                value={newProductTransfer.occupation}
                                                onChange={onChange}
                                                name="occupation"
                                                containerClass={'half'}
                                            />

                                        <FormInput
                                            label="Profession"
                                            type="string"
                                            value={newProductTransfer.profession}
                                            onChange={onChange}
                                            name="profession"
                                            containerClass={'half'}
                                        />
                                    </>
                                }
                                {newProductTransfer.employmentStatus === EmploymentStatuses.get('selfEmployed') && newProductTransfer.isActive &&
                                    <>                                    
                                        <FormInput
                                            label="Bank Number"
                                            type="select"
                                            value={newProductTransfer.bankAccount?.bankNumber}
                                            onChange={onChange}
                                            options={banks}
                                            name="bankAccount.bankNumber"
                                            containerClass={'half'}
                                        />

                                        <FormInput
                                            label="Branch"
                                            type="number"
                                            value={newProductTransfer.bankAccount?.branch}
                                            onChange={onChange}
                                            name="bankAccount.branch"
                                            containerClass={'half'}
                                        />
                                        <FormInput
                                            label="Account number"
                                            type="number"
                                            value={newProductTransfer.bankAccount?.accountNumber}
                                            onChange={onChange}
                                            name="bankAccount.accountNumber"
                                            containerClass={'half'}
                                        />

                                        <FormInput
                                            label="Bank city"
                                            type="string"
                                            value={newProductTransfer.bankAccount?.address?.city}
                                            onChange={onChange}
                                            name="bankAccount.address.city"
                                            containerClass={'half'}
                                        />
                                        <FormInput
                                            label="Bank street"
                                            type="string"
                                            value={newProductTransfer.bankAccount?.address?.street}
                                            onChange={onChange}
                                            name="bankAccount.address.street"
                                            containerClass={'half'}
                                        />
                                        <FormInput
                                            label="Bank house number"
                                            type="number"
                                            value={newProductTransfer.bankAccount?.address?.houseNumber}
                                            onChange={onChange}
                                            name="bankAccount.address.houseNumber"
                                            containerClass={'half'}
                                        />
                                    </>}
                            </div>

                            {newProductTransfer.commissions.decreasingCommission && (
                                <span className="badge font-10 bg-success p-1">Decreasing Commissions</span>
                            )}
                            {newProductTransfer.commissions.lifelongCommission && (
                                <span className="badge font-10 bg-info p-1">Life-long Commissions</span>
                            )}
                            {newProductTransfer.commissions.isEmployerDeal && <span className="badge font-10 bg-success p-1">Employer Deal</span>}

                            <div>
                                {newProductTransfer.commissions?.savings !== undefined ? (
                                    <FormInput
                                        label="Savings commission (optional)"
                                        type="number"
                                        name="commissions.savings"
                                        value={newProductTransfer.commissions.savings}
                                        onChange={onChange}
                                        placeholder="Enter savings commission"
                                        disabled={!formEnabled}
                                        min="0"
                                        containerClass={'half'}
                                    />
                                ) : (
                                    <></>
                                )}

                                {newProductTransfer.commissions?.deposit !== undefined && depositCommissionProducts.includes(productTransfer.productType) &&
                                    <FormInput
                                        label="Deposit commission (optional)"
                                        type="number"
                                        min="0"
                                        name="commissions.deposit"
                                        value={newProductTransfer.commissions.deposit}
                                        onChange={onChange}
                                        placeholder="Enter deposit commission"
                                        disabled={!formEnabled}
                                        containerClass={'half'}
                                    />
                                }
                            </div>

                            {newProductTransfer.isActive && newProductTransfer.employmentStatus === 'selfEmployed' && (
                                <FormInput
                                    label="Monthly Deposit Amount"
                                    type="number"
                                    name="monthlyDepositAmount"
                                    value={newProductTransfer.monthlyDepositAmount}
                                    placeholder="Enter amount"
                                    onChange={onChange}
                                    min="0"
                                    containerClass={'half'}
                                />
                            )}

                            {newProductTransfer.isActive && newProductTransfer.employmentStatus !== 'selfEmployed' && (
                                <>
                                    <FormInput
                                        label="Salary"
                                        type="number"
                                        name="salary"
                                        value={newProductTransfer.salary}
                                        placeholder="Salary"
                                        onChange={onChange}
                                        min="0"
                                        containerClass={'half'}
                                    />

                                    <FormInput
                                        label="Employer deposit (%)"
                                        type="number"
                                        name="employerDepositPercentage"
                                        value={newProductTransfer.employerDepositPercentage}
                                        placeholder="Enter percentage"
                                        onChange={onChange}
                                        min="0"
                                        containerClass={'half'}
                                    />

                                    <FormInput
                                        label="Employee deposit (%)"
                                        type="number"
                                        name="employeeDepositPercentage"
                                        value={newProductTransfer.employeeDepositPercentage}
                                        placeholder="Enter percentage"
                                        onChange={onChange}
                                        min="0"
                                        containerClass={'half'}
                                    />

                                    {newProductTransfer.productType !== PensionProductTypes.studyFund && (
                                        <FormInput
                                            label="Severance (%)"
                                            type="number"
                                            name="severancePercentage"
                                            value={newProductTransfer.severancePercentage}
                                            placeholder="Enter percentage"
                                            onChange={onChange}
                                            min="0"
                                            containerClass={'half'}
                                        />
                                    )}
                                </>
                            )}

                            <div>
                                {newProductTransfer.employmentStatus !== 'selfEmployed' ? (
                                    <FormInput
                                        label="Employer in forms"
                                        type="checkbox"
                                        name="hasEmployerInForms"
                                        checked={newProductTransfer.hasEmployerInForms}
                                        onChange={onChange}
                                        disabled={!formEnabled}
                                        placeholder="activation status"
                                        containerClass={'half'}
                                    />
                                ) : (
                                    <></>
                                )}

                                {newProductTransfer.hasEmployerInForms && newProductTransfer.employmentStatus !== EmploymentStatuses.get('selfEmployed') && 
                                    <>
                                        <div className="mb-3">
                                            <strong>Employer:</strong>
                                            <span className="ms-2">
                                                {newProductTransfer?.employer?.name}
                                                {!!newProductTransfer?.employer && (
                                                    <span className="edit" onClick={() => setShowEmployerAutocomplete(!showEmployerAutocomplete)}>
                                                        <i className="mdi mdi-square-edit-outline" /> {showEmployerAutocomplete ? 'Close' : 'Edit'}
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        {(showEmployerAutocomplete || !newProductTransfer?.employer) && (
                                            <EmployerAutocomplete
                                                value={newProductTransfer?.employer?.name}
                                                handleFreeSearch={handleNewEmployer}
                                                onAutocompleteSelect={onExistingEmployerSelected}
                                            />
                                        )}
                                    </>}
                            </div>
                            <br />

                            <FormInput
                                label={t('extended disability')}
                                type="checkbox"
                                containerClass={!isPension ? 'hidden' : 'half'}
                                name="extendedDisabilityInsurance"
                                checked={newProductTransfer.extendedDisabilityInsurance}
                                onChange={onChange}
                                disabled={!formEnabled}
                                placeholder="activation status"
                            />

                            <Form.Group>
                                <b>Forms</b><br/>
                                {newProductTransfer.templates.map((template, index) => (
                                    <Form.Check
                                        key={index}
                                        type="checkbox"
                                        label={camelCaseToText(template.name)}
                                        name={'template.' + index}
                                        disabled={!formEnabled}
                                        checked={template.isUsed}
                                        onChange={onChange}
                                    />
                                ))}
                            </Form.Group>

                            <br />

                            <div className="more-options">
                                {newProductTransfer.moreOptions.length ? (
                                    <div>
                                        <div>Other companies/commissions:</div>
                                        <ul>
                                            {newProductTransfer.moreOptions.map((item, index) => (
                                                <li key={index}>
                                                    <Link to={'#'} onClick={(e) => selectOtherOption(e, item)}>
                                                        {item.company.name}: {item.commissions.deposit}% / {item.commissions.savings}%
                                                    </Link>
                                                    {item.commissions.decreasingCommission && (
                                                        <span className="badge font-10 bg-success p-1">Decreasing</span>
                                                    )}
                                                    {item.commissions.lifelongCommission && (
                                                        <span className="badge font-10 bg-info p-1">Life-long</span>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ) : (
                                    <div>No Other companies/commissions</div>
                                )}
                            </div>

                            <div className="buttons">
                                {formEnabled ? (
                                    <>
                                        <Button variant="success" onClick={onSubmit} className="right waves-effect waves-light me-1">
                                            Save
                                        </Button>

                                        <Button className="un-success right waves-effect waves-light me-1" onClick={resetProduct}>
                                            Reset product
                                        </Button>
                                    </>
                                ) : (
                                    <Button variant="success" onClick={onHide} className="right waves-effect waves-light me-1">
                                        Close
                                    </Button>
                                )}

                                <span className="left download" onClick={downloadDiscountDoc}>
                                    <span>{t('manufacturer discount form')}</span>
                                    <i className="mdi mdi-download"></i>
                                </span>
                            </div>
                        </>
                    ) : null}
                </Modal.Body>
            </Modal>

            {newProductTransfer ? (
                <ConfirmModal
                    show={showConfirmIrregularCommissions}
                    noHide={() => setShowConfirmIrregularCommissions(false)}
                    confirmText={'keep anyway'}
                    cancelText={'cancel'}
                    // show={true}
                    title={(newProductTransfer.company as Company).name}
                    body={getConfirmModalBody(newProductTransfer)}
                    handleConfirm={() => onSaveProductTransfer(newProductTransfer, false, false, true)}
                />
            ) : null}
        </>
    );
};

export default EditProductTransfer;

const initialInsurancePercentage = {
    risk: ['Unchanged', 0, 40, 60, 100],
    disability: ['Unchanged', 0, 37.5, 75],
};
