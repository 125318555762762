import { useState, ChangeEvent, useEffect } from 'react';
import { InsuranceAppendix } from '../../../interfaces/InsuranceAppendix';
import { formatDate } from '../../../utils/formatDate';
import { FamilyMember } from '../../../interfaces/Client';
import { getClientFamilyMembers } from '../../../utils/getClientFamilyMembers';
import { deleteInsurancePolicy, editInsurancePolicy } from '../../../utils/api/insurancePolicy';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InsuranceAppendixRecommendation } from '../../../interfaces/InsuranceAppendix';
import { deleteInsuranceAppendix } from '../../../utils/api/insuranceAppendix';
import CreateOrEditAppendixModal from './insuranceCreationAndEdit/AppendixCreationAndEditingModal';
import { createNewAppendix, editExistingAppendix } from './insuranceCreationAndEdit/appendixAndPolicyCreationFunctions';
import { useProductsContext } from './insuranceProductsContext';
import { useTranslation } from 'react-i18next';
import { camelCaseToText } from '../../../utils/camelCaseToText';
import Swal from 'sweetalert2';
import EditableInput from '../../../components/EditableInput';
import { InsurancePolicy, InsurancePolicyStatuses } from '../../../interfaces/InsurancePolicy';
import { FormInput } from '../../../components';
import EditInsurancePolicyModal from './insuranceCreationAndEdit/EditInsurancePolicyModal';
import CreateNewInsuranceProductModal from './insuranceCreationAndEdit/CreateNewInsuranceProductModal';
import { initialInsuranceAppendixState } from './insuranceCreationAndEdit/initialPolicyAndAppendixState';
import { set } from 'date-fns';
import { setIn } from 'immutable';
import globalErrorHandler from '../../../utils/globalErrorHandler';

const noDataFound = () => {
    return <>---</>;
};

export const PolicyHolderColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { client } = useProductsContext();
    const familyMembers = getClientFamilyMembers(client, true);
    const [selectedHolder, setSelectedHolder] = useState<string>(policy.policyHolder?.idNumber || 'None');
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedIdNumber = event.target.value;
        let policyHolder = { name: '', idNumber: '' };
        if (selectedIdNumber !== 'None') {
            const selectedMember = familyMembers.find((member) => member.idNumber === selectedIdNumber);
            if (selectedMember) {
                policyHolder = {
                    name: `${selectedMember.firstName ?? ''} ${selectedMember.lastName ?? ''}`,
                    idNumber: selectedMember.idNumber ?? '',
                };
            }
        }
        editInsurancePolicy(policy._id!, { policyHolder })
            .then(() => {
                const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                    insurancePolicy._id === policy._id ? { ...insurancePolicy, policyHolder } : insurancePolicy,
                );
                setInsurancePolicies(newInsurancePolicies);
            })
            .catch((error) => {
                globalErrorHandler(error);
            });
    };

    useEffect(() => {
        setSelectedHolder(policy.policyHolder?.idNumber || 'None');
    }, [policy.policyHolder?.idNumber]);

    return (
        <select onChange={handleSelectChange} onClick={(e) => e.stopPropagation()} value={selectedHolder}>
            <option value="None">None</option>
            {familyMembers.map((member: FamilyMember) => (
                <option key={member.idNumber} value={member.idNumber}>
                    {member.firstName} {member.lastName}
                </option>
            ))}
        </select>
    );
};

export const PolicyInsuredListColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const insuredList = policy.insuredList;

    if (!insuredList) {
        return noDataFound();
    }

    if (insuredList.length > 2) {
        return (
            <div className="d-flex flex-column">
                {insuredList.map((person) => (
                    <span key={person.idNumber} className="me-2">
                        {person.name ?? person.idNumber}
                    </span>
                ))}
            </div>
        );
    }

    return (
        <div className="d-flex flex-row">
            {insuredList.map((person, index) => (
                <span key={person.idNumber} className="me-2">
                    {person.name ?? person.idNumber}
                    {index < insuredList.length - 1 && <span className="mx-1">|</span>}
                </span>
            ))}
        </div>
    );
};

export const PolicyNumberColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();

    const policyNumber = policy.policyNumber ? policy.policyNumber.toString() : 'N/A';

    if (!policy.isNewPolicy) {
        return <>{policyNumber}</>;
    }

    const validation = (value: string) => {
        return value.length > 0 && value.length <= 40;
    };

    const handlePolicyNumberChange = (value: string) => {
        editInsurancePolicy(policy._id!, { policyNumber: value })
            .then(() => {
                const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                    insurancePolicy._id === policy._id ? { ...insurancePolicy, policyNumber: value } : insurancePolicy,
                );
                setInsurancePolicies(newInsurancePolicies);
            })
            .catch((error) => {
                globalErrorHandler(error);
            });
    };

    return <EditableInput value={policyNumber} onChange={handlePolicyNumberChange} validate={validation} />;
};

const PolicyRecommendationColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { consts, insuranceAppendices, setInsuranceAppendices } = useProductsContext();
    const { t } = useTranslation('insurance');
    const [recommendation, setRecommendation] = useState<string>(policy.recommendation || '');
    const [recommendationReason, setRecommendationReason] = useState<string>(policy.recommendationReason || '');

    if (policy.isNewPolicy) {
        return <>New</>;
    }

    const recommendationOptions = consts.InsuranceRecommendation;
    const recommendationReasonsOptions = consts.RecommendationReasons;

    if (!recommendationOptions || !recommendationReasonsOptions) {
        return noDataFound();
    }

    const handleRecommendationChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const newRecommendation = event.target.value;
        try {
            await editInsurancePolicy(policy._id!, {
                recommendation: newRecommendation,
            });
            setRecommendation(newRecommendation);
            const appendicesToEdit = insuranceAppendices.filter((appendix) => appendix.policy === policy._id);
            const editedAppendices = appendicesToEdit.map((appendix) => ({
                ...appendix,
                recommendation: InsuranceAppendixRecommendation[newRecommendation as keyof typeof InsuranceAppendixRecommendation],
            }));
            const editedAppendicesPromises = editedAppendices.map((editedAppendix) => editExistingAppendix(editedAppendix));
            const editedAppendicesResults = await Promise.all(editedAppendicesPromises);
            setInsuranceAppendices(
                insuranceAppendices.map((insuranceAppendix) =>
                    editedAppendicesResults.some((editedAppendix) => editedAppendix._id === insuranceAppendix._id)
                        ? editedAppendicesResults.find((editedAppendix) => editedAppendix._id === insuranceAppendix._id)
                        : insuranceAppendix,
                ),
            );
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const handleRecommendationReasonChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const newRecommendationReason = event.target.value;
        try {
            await editInsurancePolicy(policy._id!, {
                recommendationReason: newRecommendationReason,
            });
            setRecommendationReason(newRecommendationReason);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <div className="d-flex gap-2">
            <select value={recommendation} onChange={handleRecommendationChange}>
                <option value="">---</option>
                {Object.values(recommendationOptions).map((option) => (
                    <option key={option} value={option}>
                        {t(camelCaseToText(option))}
                    </option>
                ))}
            </select>

            <select
                value={recommendationReason}
                onChange={handleRecommendationReasonChange}
                disabled={recommendation !== 'change'}
                style={{ maxWidth: '120px' }}
            >
                <option value="">{t('Reason')}</option>
                {Object.values(recommendationReasonsOptions).map((option) => (
                    <option key={option} value={option}>
                        {t(camelCaseToText(option))}
                    </option>
                ))}
            </select>
        </div>
    );
};

export const PolicyCompanyNameColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.company) {
        return noDataFound();
    }

    return <>{policy.company}</>;
};

export const PolicyMainBranchColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { t } = useTranslation('insurance');
    if (!policy.branch) {
        return noDataFound();
    }
    return <>{t(camelCaseToText(policy.branch))}</>;
};

export const PolicyPlanTypeColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.planType) {
        return noDataFound();
    }
    return <>{camelCaseToText(policy.planType)}</>;
};

export const PolicyStatusColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const [status, setStatus] = useState<InsurancePolicyStatuses>(policy.status);
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();

    if (!policy.status) {
        return noDataFound();
    }
    const handleChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const newStatus = event.target.value as InsurancePolicyStatuses;
        try {
            await editInsurancePolicy(policy._id!, { status: newStatus });
            setStatus(newStatus);
            const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                insurancePolicy._id === policy._id ? { ...insurancePolicy, status: newStatus } : insurancePolicy,
            );
            setInsurancePolicies(newInsurancePolicies);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <>
            <select value={status} onClick={(e: any) => e.stopPropagation()} onChange={handleChange} style={{ maxWidth: '120px' }}>
                {Object.values(InsurancePolicyStatuses).map((value) => (
                    <option key={value} value={value}>
                        {camelCaseToText(value)}
                    </option>
                ))}
            </select>
        </>
    );
};

export const AppendixTypeColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    if (!appendix.type) {
        return noDataFound();
    }
    return <>{appendix.type}</>;
};

export const PolicyIsAgentAppointedColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();

    if (policy.isAgentAppointed === undefined) {
        return noDataFound();
    }

    if (policy.isNewPolicy) {
        return <></>;
    }

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newIsAgentAppointed = event.target.checked;
        try {
            await editInsurancePolicy(policy._id!, {
                isAgentAppointed: newIsAgentAppointed,
            });
            const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                insurancePolicy._id === policy._id ? { ...insurancePolicy, isAgentAppointed: newIsAgentAppointed } : insurancePolicy,
            );
            setInsurancePolicies(newInsurancePolicies);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <FormInput
            type="checkbox"
            name="isAgentAppointed"
            checked={policy.isAgentAppointed}
            onChange={handleChange}
            onClick={(e: any) => e.stopPropagation()}
            className="d-flex align-items-center"
        />
    );
};

export const AppendixRecommendationColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    const [recommendation, setRecommendation] = useState<string>(appendix.recommendation || 'new');

    if (appendix.isNew) {
        return <>New</>;
    }

    const handleRecommendationChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const newRecommendation = event.target.value;
        setRecommendation(newRecommendation);
        editExistingAppendix({
            ...appendix,
            recommendation: newRecommendation as InsuranceAppendixRecommendation,
        });
    };

    return (
        <>
            <select onChange={handleRecommendationChange} value={recommendation} onClick={(e) => e.stopPropagation()}>
                <option value="">---</option>
                {Object.values(InsuranceAppendixRecommendation).map((value) => (
                    <option key={value} value={value}>
                        {camelCaseToText(value)}
                    </option>
                ))}
            </select>
        </>
    );
};

export const AppendixPremiumColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    if (!appendix.premium && appendix.premium !== 0) {
        return noDataFound();
    }
    const premiumDiscountData = appendix.premiumDiscountData;
    const isPremiumDiscountDataAvailable = premiumDiscountData && typeof premiumDiscountData === 'object';
    const premiumDiscountDataTooltip = isPremiumDiscountDataAvailable ? (
        <Tooltip id="premiumDiscountData-tooltip">
            Before Discount: <span className="text-success">{premiumDiscountData.premiumBeforeDiscount || 'N/A'}</span>
            <br />
            Discount Term: <span className="text-success">{premiumDiscountData.discountTerm || 'N/A'}</span>
            <br />
            Discount Percent: <span className="text-success">{premiumDiscountData.currentDiscountPercent || 'N/A'}</span>
            <br />
            Discount Explanation: <span className="text-success">{premiumDiscountData.discountExplanation || 'N/A'}</span>
            <br />
            {/* Discount Issue Date: <span className='text-success'>{formatDate(appendix.issueDate, true) || 'N/A'}</span> */}
            <br />
            After Discount: <span className="text-success">{premiumDiscountData.premiumAfterDiscount || 'N/A'}</span>
        </Tooltip>
    ) : (
        <Tooltip id="premiumDiscountData-tooltip">No Discount Data Available</Tooltip>
    );

    return (
        <OverlayTrigger placement="top" overlay={premiumDiscountDataTooltip}>
            <span className="cursor-pointer">{Math.round(appendix.premium)}</span>
        </OverlayTrigger>
    );
};

export const PolicyPremiumColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.totalPremium && policy.totalPremium !== 0) {
        return noDataFound();
    }
    return <>{Math.round(policy.totalPremium)}</>;
};

export const PolicyPremiumDiscountPercentageColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.premiumDiscountPercentage && policy.premiumDiscountPercentage !== 0) {
        return noDataFound();
    }
    return <>{policy.premiumDiscountPercentage}%</>;
};

export const AppendixPremiumTermColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    if (!appendix.premiumTerm) {
        return noDataFound();
    }
    return <>{appendix.premiumTerm}</>;
};

export const PolicyPremiumTermColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.premiumTerm) {
        return noDataFound();
    }
    return <>{camelCaseToText(policy.premiumTerm)}</>;
};

export const AppendixStartDateColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    if (!appendix.insuranceStartDate) {
        return noDataFound();
    }
    return <>{formatDate(new Date(appendix.insuranceStartDate), true)}</>;
};

export const PolicyStartDateColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.insuranceStartDate) {
        return noDataFound();
    }
    return <>{formatDate(new Date(policy.insuranceStartDate), true)}</>;
};

export const PolicyEndDateColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.insuranceEndDate) {
        return noDataFound();
    }
    return <>{formatDate(new Date(policy.insuranceEndDate), true)}</>;
};

export const PolicyIssueDateColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    if (!policy.issueDate) {
        return noDataFound();
    }
    return <>{formatDate(new Date(policy.issueDate), true)}</>;
};

export const PolicyCancellationAppendixCColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();
    const [cancellationAppendixC, setCancellationAppendixC] = useState<boolean>(policy.cancellationAppendixC || false);

    if (policy.isNewPolicy) {
        return <></>;
    }

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newCancellationAppendixC = event.target.checked;
        try {
            await editInsurancePolicy(policy._id!, {
                cancellationAppendixC: newCancellationAppendixC,
            });
            setCancellationAppendixC(newCancellationAppendixC);
            const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                insurancePolicy._id === policy._id
                    ? {
                          ...insurancePolicy,
                          cancellationAppendixC: newCancellationAppendixC,
                      }
                    : insurancePolicy,
            );
            setInsurancePolicies(newInsurancePolicies);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <FormInput
            type="checkbox"
            name="cancellationAppendixC"
            checked={cancellationAppendixC}
            onChange={handleChange}
            onClick={(e: any) => e.stopPropagation()}
            className="d-flex align-items-center"
        />
    );
};

export const PolicyCancellationAppendixAColumn = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();
    const [cancellationAppendixA, setCancellationAppendixA] = useState<boolean>(policy.cancellationAppendixA || false);

    if (policy.isNewPolicy) {
        return <></>;
    }

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newCancellationAppendixA = event.target.checked;
        try {
            await editInsurancePolicy(policy._id!, {
                cancellationAppendixA: newCancellationAppendixA,
            });
            setCancellationAppendixA(newCancellationAppendixA);
            const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                insurancePolicy._id === policy._id
                    ? {
                          ...insurancePolicy,
                          cancellationAppendixA: newCancellationAppendixA,
                      }
                    : insurancePolicy,
            );
            setInsurancePolicies(newInsurancePolicies);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <FormInput
            type="checkbox"
            name="cancellationAppendixA"
            checked={cancellationAppendixA}
            onChange={handleChange}
            onClick={(e: any) => e.stopPropagation()}
            className="d-flex align-items-center"
        />
    );
};

export const PolicyNoCancellationRequired = ({ row: { original: policy } }: { row: { original: InsurancePolicy } }) => {
    const { insurancePolicies, setInsurancePolicies } = useProductsContext();
    const [noCancellationRequired, setNoCancellationRequired] = useState<boolean>(policy.noCancellationRequired || false);

    if (policy.isNewPolicy) {
        return <></>;
    }

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newNoCancellationRequired = event.target.checked;
        try {
            await editInsurancePolicy(policy._id!, {
                noCancellationRequired: newNoCancellationRequired,
            });
            setNoCancellationRequired(newNoCancellationRequired);
            const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                insurancePolicy._id === policy._id
                    ? {
                          ...insurancePolicy,
                          noCancellationRequired: newNoCancellationRequired,
                      }
                    : insurancePolicy,
            );
            setInsurancePolicies(newInsurancePolicies);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    return (
        <FormInput
            type="checkbox"
            name="noCancellationRequired"
            checked={noCancellationRequired}
            onChange={handleChange}
            onClick={(e: any) => e.stopPropagation()}
            className="d-flex align-items-center"
        />
    );
};

export const AppendixBranchColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    const { t } = useTranslation('insurance');
    if (!appendix.branch) {
        return noDataFound();
    }
    return <>{t(camelCaseToText(appendix.branch))}</>;
};

export const AppendixBranchSectionColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    const { t } = useTranslation('insurance');
    if (!appendix.branchSection) {
        return noDataFound();
    }
    return <>{t(camelCaseToText(appendix.branchSection))}</>;
};

export const AppendixReplaceAppendixIdColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    if (!appendix.replaceAppendix) {
        return noDataFound();
    }
    return <>{`. . .${appendix.replaceAppendix.slice(-5)}`}</>;
};

export const AppendixIdColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    if (!appendix._id) {
        return noDataFound();
    }
    return <>{`. . .${appendix._id.slice(-5)}`}</>;
};

export const PolicyIdColumn = ({ row: { original: appendix } }: { row: { original: InsurancePolicy } }) => {
    if (!appendix._id) {
        return noDataFound();
    }
    return <>{`. . .${appendix._id.slice(-5)}`}</>;
};

export const ChangeInPremiumColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    const { insuranceAppendices } = useProductsContext();
    if (!appendix.replaceAppendix) {
        return noDataFound();
    }
    const currentPremium = appendix.premium;
    const replacedAppendix = insuranceAppendices.find((insuranceAppendix) => insuranceAppendix._id === appendix.replaceAppendix);
    if (!replacedAppendix) {
        return noDataFound();
    }
    const replacedPremium = replacedAppendix.premium;
    const changeInPremium = currentPremium - replacedPremium;
    const sign = changeInPremium > 0 ? '+' : '';
    const changeInPremiumPercentage = (currentPremium / replacedPremium) * 100;
    const percentageTooltip = <Tooltip id="percentage-tooltip">{changeInPremiumPercentage.toFixed(2)}%</Tooltip>;
    return (
        <OverlayTrigger placement="top" overlay={percentageTooltip}>
            <span className="cursor-pointer">
                {sign}
                {changeInPremium.toFixed(2)}
            </span>
        </OverlayTrigger>
    );
};

export const AppendixInsuredPersonColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    const insuredPerson = appendix.insuredPerson;
    if (!insuredPerson) {
        return noDataFound();
    }

    return insuredPerson.name || insuredPerson.idNumber;
};

export const AppendixActionsColumn = ({ row: { original: appendix } }: { row: { original: InsuranceAppendix } }) => {
    const { insuranceAppendices, setInsuranceAppendices, insurancePolicies, setInsurancePolicies } = useProductsContext();
    const [showEditAppendixModal, setShowEditAppendixModal] = useState(false);

    const handleEditAppendix = async (modifiedStateOfAppendix: InsuranceAppendix) => {
        const oldPremium = insuranceAppendices.find((appendix) => appendix._id === modifiedStateOfAppendix._id)?.premium;
        try {
            const editedInsuranceAppendix = await editExistingAppendix(modifiedStateOfAppendix);
            setInsuranceAppendices(
                insuranceAppendices.map((insuranceAppendix) =>
                    insuranceAppendix._id === editedInsuranceAppendix._id ? editedInsuranceAppendix : insuranceAppendix,
                ),
            );
            setInsurancePolicies(
                insurancePolicies.map((insurancePolicy) => {
                    if (insurancePolicy._id === editedInsuranceAppendix.policy) {
                        insurancePolicy.totalPremium =
                            (insurancePolicy.totalPremium ?? 0) - (oldPremium ?? 0) + parseFloat(editedInsuranceAppendix.premium ?? 0);
                    }
                    return insurancePolicy;
                }),
            );
            return editedInsuranceAppendix;
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const deleteAppendix = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        const confirmation = await Swal.fire({
            title: 'Are you sure you want to delete this appendix?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (confirmation.isConfirmed) {
            try {
                await deleteInsuranceAppendix(appendix._id);
                setInsuranceAppendices(insuranceAppendices.filter((insuranceAppendix) => insuranceAppendix._id !== appendix._id));
                setInsurancePolicies(
                    insurancePolicies.map((insurancePolicy) => {
                        if (insurancePolicy._id === appendix.policy) {
                            insurancePolicy.totalPremium = (insurancePolicy.totalPremium ?? 0) - (appendix.premium ?? 0);
                        }
                        return insurancePolicy;
                    }),
                );
            } catch (error) {
                globalErrorHandler(error);
            }
        }
    };

    return (
        <>
            {appendix.isNew && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="">Edit appendix</Tooltip>}>
                    <i className="mdi mdi-square-edit-outline cursor-pointer fs-3" onClick={() => setShowEditAppendixModal(true)}></i>
                </OverlayTrigger>
            )}
            {appendix.isNew && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="">Delete appendix</Tooltip>}>
                    <i className="mdi mdi-delete cursor-pointer fs-3" onClick={deleteAppendix}></i>
                </OverlayTrigger>
            )}
            {showEditAppendixModal && (
                <CreateOrEditAppendixModal
                    show={showEditAppendixModal}
                    onHide={() => setShowEditAppendixModal(false)}
                    currentAppendixState={appendix}
                    onSubmit={handleEditAppendix}
                    isAppendixEditingPhase={true}
                />
            )}
        </>
    );
};

export const PolicyActionsColumn = ({ row }: { row: any }) => {
    const {
        insurancePolicies,
        setInsurancePolicies,
        createInsuranceProduct,
        insuranceAppendices,
        setInsuranceAppendices,
        initiallyExpandedRows,
        setInitiallyExpandedRows,
    } = useProductsContext();

    const policy = row.original as InsurancePolicy;
    const [showEditPolicyModal, setShowEditPolicyModal] = useState(false);
    const [showCreatePolicyModal, setShowCreatePolicyModal] = useState(false);
    const [showCreateAppendixModal, setShowCreateAppendixModal] = useState(false);
    const [updateExpandedRows, setUpdateExpandedRows] = useState(false);

    const handleEditPolicy = async (modifiedStateOfPolicy: InsurancePolicy) => {
        try {
            const response = await editInsurancePolicy(policy._id!, modifiedStateOfPolicy);
            const editedInsurancePolicy = response.policy;
            const newInsurancePolicies = insurancePolicies.map((insurancePolicy) =>
                insurancePolicy._id === editedInsurancePolicy._id ? editedInsurancePolicy : insurancePolicy,
            );
            setInsurancePolicies(newInsurancePolicies);
            return editedInsurancePolicy;
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const handleCreatePolicy = async (newInsurancePolicy: InsurancePolicy, newInsuranceAppendices: InsuranceAppendix[]) => {
        try {
            await createInsuranceProduct(newInsurancePolicy, newInsuranceAppendices, policy._id);
            setShowCreatePolicyModal(false);
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const handleCreateAppendix = async (newInsuranceAppendix: InsuranceAppendix) => {
        try {
            newInsuranceAppendix.isNew = true;
            const createdAppendix = await createNewAppendix(newInsuranceAppendix);
            createdAppendix.policyId = policy._id!;
            setInsuranceAppendices([...insuranceAppendices, createdAppendix]);
            setInsurancePolicies(
                insurancePolicies.map((insurancePolicy) => {
                    if (insurancePolicy._id === policy._id) {
                        insurancePolicy.totalPremium = (insurancePolicy.totalPremium ?? 0) + (createdAppendix.premium ?? 0);
                    }
                    return insurancePolicy;
                }),
            );
            setShowCreateAppendixModal(false);
            setInitiallyExpandedRows([...initiallyExpandedRows, row.id]);
            return createdAppendix;
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    useEffect(() => {
        if (updateExpandedRows) {
            setInitiallyExpandedRows((prev) => [...prev, row.id]);
            setUpdateExpandedRows(false);
        }
    }, [insuranceAppendices, updateExpandedRows, row.id]);

    const deletePolicy = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const confirmation = await Swal.fire({
            title: 'Are you sure you want to delete this policy?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });
        if (confirmation.isConfirmed) {
            try {
                await deleteInsurancePolicy(policy._id!);
                setInsurancePolicies(insurancePolicies.filter((insurancePolicy) => insurancePolicy._id !== policy._id));
            } catch (error) {
                globalErrorHandler(error);
            }
        }
    };

    return (
        <>
            {policy.isNewPolicy && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="">Edit policy</Tooltip>}>
                    <i className="mdi mdi-square-edit-outline cursor-pointer fs-3" onClick={() => setShowEditPolicyModal(true)}></i>
                </OverlayTrigger>
            )}
            {policy.isNewPolicy && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="">Delete policy</Tooltip>}>
                    <i className="mdi mdi-delete cursor-pointer fs-3" onClick={deletePolicy}></i>
                </OverlayTrigger>
            )}
            {!policy.isNewPolicy && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="">Create replacement policy</Tooltip>}>
                    <i className="mdi mdi-sync cursor-pointer fs-3" onClick={() => setShowCreatePolicyModal(true)}></i>
                </OverlayTrigger>
            )}
            {policy.recommendation !== 'cancel' && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="">Create appendix</Tooltip>}>
                    <i className="mdi mdi-plus-circle-outline cursor-pointer fs-3" onClick={() => setShowCreateAppendixModal(true)}></i>
                </OverlayTrigger>
            )}

            {showEditPolicyModal && (
                <EditInsurancePolicyModal
                    show={showEditPolicyModal}
                    onHide={() => setShowEditPolicyModal(false)}
                    currentPolicyState={policy}
                    policyUpdateHandler={handleEditPolicy}
                />
            )}
            {showCreatePolicyModal && (
                <CreateNewInsuranceProductModal
                    show={showCreatePolicyModal}
                    onHide={() => setShowCreatePolicyModal(false)}
                    productCreationHandler={handleCreatePolicy}
                />
            )}
            {showCreateAppendixModal && (
                <CreateOrEditAppendixModal
                    show={showCreateAppendixModal}
                    onHide={() => setShowCreateAppendixModal(false)}
                    onSubmit={handleCreateAppendix}
                    currentAppendixState={{
                        ...initialInsuranceAppendixState,
                        policyId: policy._id!,
                        policy: policy._id!,
                    }}
                    isAppendixEditingPhase={false}
                />
            )}
        </>
    );
};

export const policyColumns = [
    {
        Header: 'Main Branch',
        accessor: 'branch',
        classes: 'table-client',
        Cell: PolicyMainBranchColumn,
        sort: false,
    },
    {
        Header: 'Company',
        accessor: 'company',
        classes: 'table-client',
        Cell: PolicyCompanyNameColumn,
        sort: false,
    },
    {
        Header: 'Policy Number',
        accessor: 'policyNumber',
        classes: 'table-client',
        Cell: PolicyNumberColumn,
        sort: false,
    },
    {
        Header: 'Recommendation',
        accessor: 'recommendation',
        classes: 'table-client',
        Cell: PolicyRecommendationColumn,
        sort: false,
    },
    {
        Header: 'Agent Appointed',
        accessor: 'isAgentAppointed',
        classes: 'table-client',
        Cell: PolicyIsAgentAppointedColumn,
        sort: false,
    },
    {
        Header: 'Premium',
        accessor: 'totalPremium',
        classes: 'table-client',
        Cell: PolicyPremiumColumn,
        sort: false,
    },
    {
        Header: 'Status',
        accessor: 'status',
        classes: 'table-client',
        Cell: PolicyStatusColumn,
        sort: false,
    },
    {
        Header: 'Actions',
        accessor: '_id',
        classes: 'table-client',
        Cell: PolicyActionsColumn,
        sort: false,
    },
];

export const appendixColumns = [
    {
        Header: 'Recommendation',
        accessor: 'recommendation',
        classes: 'table-client',
        Cell: AppendixRecommendationColumn,
        sort: false,
    },
    {
        Header: 'Secondary Branch',
        accessor: 'secondaryBranch',
        classes: 'table-client',
        Cell: AppendixBranchColumn,
        sort: false,
    },
    {
        Header: 'Branch Section',
        accessor: 'branchSection',
        classes: 'table-client',
        Cell: AppendixBranchSectionColumn,
        sort: false,
    },
    {
        Header: 'Insured Person',
        accessor: 'insuredPerson',
        classes: 'table-client',
        Cell: AppendixInsuredPersonColumn,
        sort: false,
    },
    {
        Header: 'Premium',
        accessor: 'premium',
        classes: 'table-client',
        Cell: AppendixPremiumColumn,
        sort: false,
    },
    {
        Header: 'Actions',
        accessor: '_id',
        classes: 'table-client',
        Cell: AppendixActionsColumn,
        sort: false,
    },
];
