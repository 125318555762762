import { Form } from 'react-bootstrap';
import BorderWithText from '../../../../../components/BorderWithText';
import { PersonTaxData } from '../../../../../interfaces/TaxData';
import { Option } from '../../../../../interfaces/Option';
import { camelCaseToText } from '../../../../../utils/camelCaseToText';
import { parseDateValue } from '../../../../../utils/parseDateValue';

interface ImmigrantStatusBoxProps {
    t: (s: string) => string;
    prefix: string;
    data: PersonTaxData;
    onChange: (e: any) => void;
    immigrationTypesOptions: Option;
}
const ImmigrantStatusBox = ({ t, prefix, data, onChange, immigrationTypesOptions }: ImmigrantStatusBoxProps) => {
    return (
        <BorderWithText label={t('Immigrant Status')}>
            <Form.Group controlId={`${prefix}NewImmigrant`} className="d-flex justify-content-between gap-2">
                <div>
                    <Form.Label>{t('New Immigrant Status')}</Form.Label>
                    <Form.Select name={`${prefix}.newImmigrant.immigrationType`} value={data.newImmigrant.immigrationType} onChange={onChange}>
                        <option disabled={true} value="">
                            {t('Select Immigration Type')}
                        </option>
                        {immigrationTypesOptions &&
                            Object.entries(immigrationTypesOptions).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {t(camelCaseToText(value))}
                                </option>
                            ))}
                    </Form.Select>
                </div>
                <div>
                    <Form.Label>{t('Date')}</Form.Label>
                    <Form.Control
                        type="date"
                        name={`${prefix}.newImmigrant.date`}
                        value={parseDateValue(data.newImmigrant.date)}
                        onChange={onChange}
                    />
                </div>
            </Form.Group>
        </BorderWithText>
    );
};

export default ImmigrantStatusBox;
