import { createContext, useContext } from 'react';
import { InsurancePolicy } from '../../../interfaces/InsurancePolicy';
import { InsuranceAppendix } from '../../../interfaces/InsuranceAppendix';
import { Client } from '../../../interfaces/Client';

interface ProductsContextData {
    insurancePolicies: InsurancePolicy[];
    setInsurancePolicies: (insurancePolicies: InsurancePolicy[]) => void;
    insuranceAppendices: InsuranceAppendix[];
    setInsuranceAppendices: React.Dispatch<React.SetStateAction<InsuranceAppendix[]>>;
    createInsuranceProduct: (
        newInsurancePolicy: InsurancePolicy,
        newInsuranceAppendices: InsuranceAppendix[],
        originalPolicyId?: string,
    ) => Promise<void>;
    client: Client;
    consts: {
        InsuranceRecommendation: string[];
        RecommendationReasons: string[];
        companyNames: string[];
        familyMembers: any[];
    };
    initiallyExpandedRows: string[];
    setInitiallyExpandedRows: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ProductsContext = createContext<ProductsContextData | undefined>(undefined);

export function useProductsContext() {
    const context = useContext(ProductsContext);
    if (!context) {
        throw new Error('useProductsContext must be used within a ProductsContext.Provider');
    }
    return context;
}
