import { useState } from 'react';
import { Client } from '../interfaces/Client';
import Modules from '../utils/modules';
import { Button } from 'react-bootstrap';
import OffCanvasWrapper from './offCanvas/OffCanvasWrapper';
import ChatArea from './chat-area/ChatArea';
import Tasks from '../pages/profile/Tasks';
import useComments from '../hooks/useComments';
import Comments from './Comments';

interface DrawersProps {
    client?: Client;
    selectedModule?: string;
    openTasksCount: number;
}

const Drawers = ({ client, selectedModule, openTasksCount }: DrawersProps) => {
    const [showChat, setShowChat] = useState(false);
    const [showTasks, setShowTasks] = useState(false);
    const [showComments, setShowComments] = useState(false);

    const commentProps = useComments({ client });

    return (
        <div className="chat-wrapper">
            {client && selectedModule !== Modules.onboarding && (
                <>
                    <Button variant="success" onClick={() => setShowChat(true)} className="off-canvas-trigger chat-button">
                        <div className="fe-chevron-up"></div>
                        Chat
                    </Button>
                    <OffCanvasWrapper name={'chat'} placement={'end'} show={showChat} setShow={setShowChat}>
                        <ChatArea
                            client={client}
                            sendMessageEnabled={true}
                            showJotformLinks={true}
                            chatTitle={client?.firstName + ' ' + client?.lastName}
                            polling={true}
                            enableSocket={true}
                        />
                    </OffCanvasWrapper>
                </>
            )}
            <Button variant="success" onClick={() => setShowTasks(true)} className="off-canvas-trigger tasks-button">
                <div className="fe-chevron-up"></div>
                Tasks {openTasksCount ? <div className="task-count">{openTasksCount}</div> : null}
            </Button>
            <OffCanvasWrapper name={'Tasks'} placement={'end'} show={showTasks} setShow={setShowTasks}>
                {client ? <Tasks /> : null}
            </OffCanvasWrapper>
            <Button variant="success" onClick={() => setShowComments(true)} className="off-canvas-trigger comments-button">
                <div className="fe-chevron-up"></div>
                Comments {!!commentProps.comments.length && <div className="comment-count">{commentProps.comments.length}</div>}
            </Button>
            <OffCanvasWrapper name={'comments'} placement={'end'} show={showComments} setShow={setShowComments}>
                <Comments {...commentProps} />
            </OffCanvasWrapper>
        </div>
    );
};

export default Drawers;
