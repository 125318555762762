import React, { useEffect, useState } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger, Alert } from 'react-bootstrap';
import { Deal } from '../../../interfaces/Employer';
import { Company } from '../../../interfaces/Company';
import { getEmployerDeals } from '../../../utils/api/employerDeals';
import { getCompanies } from '../../../utils/api/companies';
import './Deals.scss';
import Loader from '../../../components/Loader';
import DealItem from './DealItem';
import globalErrorHandler from '../../../utils/globalErrorHandler';

interface EditDealsProps {
    show: boolean;
    onHide: () => void;
    employerId: string;
    employerName: string;
}

const EditDeals = ({ show, onHide, employerId, employerName }: EditDealsProps) => {
    const [deals, setDeals] = useState<Deal[] | []>([]);
    const [companies, setCompanies] = useState<Company[] | []>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [preventAddNew, setPreventAddNew] = useState<boolean>(false);

    useEffect(() => {
        getDeals(employerId);
        getCompaniesList();
    }, [employerId]);

    useEffect(() => {
        if (deals.find((deal) => deal._id === 'NEW')) {
            setPreventAddNew(true);
        } else {
            setPreventAddNew(false);
        }
    }, [deals]);

    const getDeals = async (employerId: string) => {
        setLoading(true);

        try {
            const response = await getEmployerDeals({ employerId });
            setDeals(response);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            globalErrorHandler(err);
        }
    };

    const getCompaniesList = async () => {
        setLoading(true);

        try {
            const response = await getCompanies({ isDeal: 'true' });
            setCompanies(response.companies);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            globalErrorHandler(err);
        }
    };

    const addEmptyDeal = () => {
        if (preventAddNew) {
            return;
        }
        const newDeal = {
            company: '',
            productType: '',
            commissions: {
                deposit: undefined,
                savings: undefined,
            },
            validity: undefined,
            _id: 'NEW',
        };
        setDeals([...deals, newDeal]);
    };

    const addDealLocally = (newDeal: Deal, dealId: 'string') => {
        if (!dealId) {
            return;
        }
        setDeals(
            deals.map((deal) => {
                if (deal._id === 'NEW') {
                    return { ...newDeal, _id: dealId };
                }
                return deal;
            }),
        );
    };

    const deleteDealLocally = (dealId: string) => {
        setDeals(deals.filter((deal) => deal._id !== dealId));
    };

    return (
        <>
            <Modal className={'deals-modal'} show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">{'Manage Employer Deals'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <h2 className="text-center mt-2 mb-4"> {employerName} </h2>

                    {loading ? (
                        <Loader />
                    ) : deals && deals.length < 1 ? (
                        // no deals found:
                        <Alert variant="secondary">
                            <div>No deals found, you can create one:</div>
                            <Button variant="success" onClick={addEmptyDeal}>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="">Add New Deal</Tooltip>}>
                                    <>
                                        Create an empty Deal <i className="mdi  mdi-plus-circle-outline"></i>
                                    </>
                                </OverlayTrigger>
                            </Button>
                        </Alert>
                    ) : (
                        deals.map((deal: Deal, index: number) => {
                            return (
                                <DealItem
                                    addDealLocally={addDealLocally}
                                    employerId={employerId}
                                    deleteDealLocally={deleteDealLocally}
                                    companies={companies}
                                    deal={deal}
                                    key={index}
                                />
                            );
                        })
                    )}

                    <div className="text-end">
                        {deals.length > 0 && !preventAddNew && (
                            <Button variant="success" onClick={addEmptyDeal}>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="">Add New Deal</Tooltip>}>
                                    <>
                                        Add new empty Deal <i className="mdi  mdi-plus-circle-outline"></i>
                                    </>
                                </OverlayTrigger>
                            </Button>
                        )}

                        <Button variant="secondary" onClick={onHide}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="">Close</Tooltip>}>
                                <>
                                    Close <i className="mdi mdi-window-close"></i>
                                </>
                            </OverlayTrigger>
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EditDeals;
