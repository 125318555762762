import { useState } from 'react';
import { InsurancePolicy } from '../../../../interfaces/InsurancePolicy';
import { Form, Modal } from 'react-bootstrap';
import PolicyInputFields from './PolicyInputFields';
import LaddaButton, { EXPAND_LEFT } from 'react-ladda-button';

import { useProductsContext } from '../insuranceProductsContext';
import globalErrorHandler from '../../../../utils/globalErrorHandler';

interface EditInsurancePolicyProps {
    show: boolean;
    onHide: () => void;
    currentPolicyState: InsurancePolicy;
    policyUpdateHandler: (updatedPolicy: InsurancePolicy) => void;
}

const EditInsurancePolicyModal = ({ show, onHide, currentPolicyState, policyUpdateHandler }: EditInsurancePolicyProps) => {
    const [updatedPolicy, setUpdatedPolicy] = useState<InsurancePolicy>(currentPolicyState);
    const { consts } = useProductsContext();
    const companyNames = consts.companyNames;

    const [isSaving, setIsSaving] = useState(false);

    const handleFormSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSaving(true);

        try {
            await policyUpdateHandler(updatedPolicy);
            setIsSaving(false);
            onHide();
        } catch (error) {
            setIsSaving(false);
            globalErrorHandler(error);
        }
    };

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered onClick={(e: any) => e.stopPropagation()}>
            <Modal.Header className="bg-primary" closeButton>
                <Modal.Title className="text-white">Edit Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleFormSubmit}>
                    <fieldset>
                        <legend className="fw-bold">Policy</legend>
                        <PolicyInputFields newInsurancePolicy={updatedPolicy} setNewInsurancePolicy={setUpdatedPolicy} companies={companyNames} />
                    </fieldset>
                    <LaddaButton
                        type="submit"
                        loading={isSaving}
                        data-style={EXPAND_LEFT}
                        className={`right btn btn-primary btn-lg w-${isSaving ? '50' : '25'} mt-4`}
                    >
                        {isSaving ? 'Saving...' : 'Save'}
                    </LaddaButton>
                </Form>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    );
};

export default EditInsurancePolicyModal;
