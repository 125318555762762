import { APICore, API_URL, CLIENT_SEARCH_URL, DASHBOARD_URL } from './apiCore';

const api = new APICore();

const createPension = (clientId: string, ignorePOA?: boolean) => {
    return api.create(`${API_URL}/pension`, { clientId, ignorePOA });
};

const deletePension = (pensionId: string) => {
    return api.delete(`${API_URL}/pension/${pensionId}`);
};

const editPension = (pensionId: string, body: any) => {
    const relativeUrl = `/pension/${pensionId}`;
    return api.update(`${API_URL}${relativeUrl}`, body);
};

const getPensionStatuses = async () => {
    const relativeUrl = '/search/enums';
    const result = await api.get(`${CLIENT_SEARCH_URL}${relativeUrl}`);
    const { PensionStatuses } = result;
    return {
        statuses: Object.keys(PensionStatuses),
    };
};

const isPOAValid = async (clientId: string) => {
    return api.get(`${API_URL}/signatures/is-poa-valid?clientId=${clientId}`);
};

const sendSavingsWhatsapp = async () => {
    return api.create(`${API_URL}/pension/send-savings-whatsapp`, {});
};
export { deletePension, createPension, editPension, getPensionStatuses, isPOAValid, sendSavingsWhatsapp };
