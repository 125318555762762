import React, { useEffect, useState } from 'react';
import { Deal } from '../../../interfaces/Employer';
import { Company } from '../../../interfaces/Company';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Loader from '../../../components/Loader';
import InputWrapper from '../../../components/inputWrapper/InputWrapper';
import { structuralClone } from '../../../helpers/misc';
import { uploadStatusType } from '../../../interfaces/uploadStatusType';
import { addEmployerDeal, deleteEmployerDeal, editEmployerDeal } from '../../../utils/api/employerDeals';
import { dealStatus as dealStatusOptions, initialStatus, validateDealObject } from './dealStatus';
import globalErrorHandler from '../../../utils/globalErrorHandler';

interface dealItemProps {
    companies: Company[] | [];
    deal: Deal;
    employerId: string;
    deleteDealLocally: (dealId: string) => void;
    addDealLocally: (newDeal: Deal, dealId: 'string') => void;
}

const DealItem = ({ companies, deal, employerId, deleteDealLocally, addDealLocally }: dealItemProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [dealStatus, setDealStatus] = useState<uploadStatusType | null>();
    const [isNewDeal, setIsNewDeal] = useState<boolean | undefined>();
    const [currentDeal, setCurrentDeal] = useState<Deal | any>();
    const [fieldsStatus, setFieldsStatus] = useState(initialStatus);

    useEffect(() => {
        setDealStatus(null);
        setIsNewDeal(deal._id === 'NEW');
        setCurrentDeal(structuralClone(deal));
        setLoading(false);
    }, [deal]);

    const getFieldValue = (fieldName: string) => {
        return fieldName === 'deposit' || fieldName === 'savings' ? currentDeal.commissions[fieldName] : currentDeal[fieldName];
    };

    const validateForm = () => {
        setFieldsStatus(initialStatus);
        let isValid = true;

        Object.keys(fieldsStatus).forEach((fieldName: any) => {
            const value = getFieldValue(fieldName);

            // Validate all fields:
            (validateDealObject as any)[fieldName].forEach((validation: any) => {
                if (!validation.callback(value)) {
                    setFieldsStatus((prev: any) => ({
                        ...prev,
                        [fieldName]: validation.message,
                    }));
                    isValid = false;
                }
            });
        });

        return isValid;
    };

    const saveCurrentDeal = async () => {
        if (!validateForm()) {
            return;
        }
        setLoading(true);
        let result: any = null;

        try {
            if (isNewDeal) {
                result = await addEmployerDeal({ ...currentDeal, employerId });
            } else {
                result = await editEmployerDeal(currentDeal._id, currentDeal);
            }

            if (result) {
                if (isNewDeal) {
                    setDealStatus({ message: dealStatusOptions.saved, class: 'success' });
                    setTimeout(() => {
                        addDealLocally(currentDeal, result.employerDeal._id);
                    }, 3000);
                } else {
                    setDealStatus({
                        message: dealStatusOptions.updated,
                        class: 'success',
                    });
                    setTimeout(() => {
                        setDealStatus(null);
                    }, 3000);
                }
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            globalErrorHandler(err);
            setDealStatus({ message: dealStatusOptions.problem, class: 'danger' });
        }
    };

    const deleteCurrentDeal = async () => {
        if (!window.confirm(`Are you sure you want to delete the this deal?`)) {
            return;
        }

        setLoading(true);
        let result = null;

        try {
            if (!isNewDeal) {
                result = await deleteEmployerDeal(currentDeal._id);
            }

            if (result || isNewDeal) {
                setDealStatus({
                    message: dealStatusOptions.deleted,
                    class: 'success',
                    icon: 'mdi-checkbox-marked-circle',
                });
                setTimeout(() => {
                    deleteDealLocally(currentDeal._id);
                }, 3000);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            globalErrorHandler(err);
            setDealStatus({
                message: dealStatusOptions.problem,
                class: 'danger',
                icon: 'mdi-alert',
            });
        }
    };

    const onChange = (event: any) => {
        const name = event.target.name;
        const value = event.target.value;

        const tempDeal = structuralClone(currentDeal);

        if (name === 'deposit' || name === 'savings') {
            tempDeal.commissions[name] = Number(value);
        } else if (name === 'validity') {
            tempDeal.validity = Number(value);
        } else {
            tempDeal[name] = value;
        }
        setCurrentDeal(tempDeal);
    };

    return (
        <form className={'deal-form'}>
            {currentDeal && (
                <>
                    {loading && <Loader />}

                    <div className="input-wrapper">
                        <InputWrapper
                            status={{ text: fieldsStatus.company }}
                            label="Insurance/pension company"
                            wrapperClass={`input-field select-field`}
                        >
                            <select
                                required
                                value={currentDeal.company || 'select'}
                                onChange={onChange}
                                className={`form-select ${isNewDeal ? 'new-item' : ''}`}
                                name="company"
                            >
                                <option value={'select'} disabled={true}>
                                    select company &#9776;
                                </option>
                                {companies.map((company) => (
                                    <option key={company._id} value={company._id}>
                                        {company.name}
                                    </option>
                                ))}
                            </select>
                        </InputWrapper>

                        <InputWrapper
                            status={{ text: fieldsStatus.productType }}
                            label="Product type"
                            wrapperClass="input-field select-field product-type"
                        >
                            <select
                                required
                                value={currentDeal.productType || 'select'}
                                onChange={onChange}
                                className="form-select"
                                name="productType"
                            >
                                <option value={'select'} disabled={true}>
                                    select product type &#9776;
                                </option>
                                {productTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </InputWrapper>

                        <InputWrapper
                            status={{ text: fieldsStatus.savings }}
                            label="Savings commission (%)"
                            wrapperClass={`input-field number-field`}
                        >
                            <input
                                min={0}
                                max={2}
                                required
                                type="number"
                                className="form-control"
                                name="savings"
                                onChange={onChange}
                                value={currentDeal.commissions.savings || ''}
                                placeholder="savings"
                            />
                        </InputWrapper>

                        <InputWrapper status={{ text: fieldsStatus.deposit }} label="Deposit commission (%)" wrapperClass="input-field number-field">
                            <input
                                min={0}
                                max={2}
                                required
                                type="number"
                                className="form-control"
                                name="deposit"
                                onChange={onChange}
                                value={currentDeal.commissions.deposit || ''}
                                placeholder="deposit"
                            />
                        </InputWrapper>

                        <InputWrapper status={{ text: fieldsStatus.validity }} label="Validity (years)" wrapperClass="input-field number-field">
                            <input
                                required
                                type="number"
                                className="form-control"
                                name="validity"
                                onChange={onChange}
                                value={currentDeal.validity || ''}
                                placeholder="validity"
                            />
                        </InputWrapper>

                        <Button onClick={deleteCurrentDeal} variant="danger" className={isNewDeal ? 'delete-new' : ''}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="">Delete This Deal</Tooltip>}>
                                <i className="mdi mdi-delete"></i>
                            </OverlayTrigger>
                        </Button>

                        <Button variant="info" className={isNewDeal ? 'save-new' : ''} onClick={saveCurrentDeal}>
                            <OverlayTrigger placement="top" overlay={<Tooltip id="">Save Changes</Tooltip>}>
                                <i className="mdi mdi-content-save"></i>
                            </OverlayTrigger>
                        </Button>
                    </div>
                    <div className="deal-status-wrapper">
                        {!dealStatus && isNewDeal && <div className="warning"> &rarr; {dealStatusOptions.created}</div>}
                        {dealStatus && <div className={`${dealStatus.class ? dealStatus.class : ''}`}> &rarr; {dealStatus.message}</div>}
                    </div>
                </>
            )}
        </form>
    );
};

export default DealItem;

const productTypes = ['comprehensivePensionFund', 'complementaryPensionFund', 'studyFund', 'providentFund', 'investmentFund', 'directorsInsurance'];
