import React, { useEffect, useState } from 'react';
import { Modal, Alert, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import globalErrorHandler from '../../utils/globalErrorHandler';

interface UploadLinkModalProps {
    show: boolean;
    onHide: () => void;
    title: string;
    getLink: () => any;
}

const UploadLinkModal = ({ show, onHide, title, getLink }: UploadLinkModalProps) => {
    const [link, setLink] = useState('');
    const [isCopied, setIsCopied] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleLink = async () => {
        setIsLoading(true);
        try {
            const { link } = await getLink();
            setLink(link);
            setIsCopied(false);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            globalErrorHandler(err);
        }
    };

    useEffect(() => {
        if (show) handleLink();
    }, [getLink, show]);

    const onCopyLink = () => {
        navigator.clipboard.writeText(link);
        setIsCopied(!isCopied);
    };

    const getTextLink = () => {
        if (link.includes('link.allbetter')) return link;

        const index = link.indexOf('upload');
        return link.substr(0, index + 18) + '...';
    };
    return (
        <>
            {isLoading && <Loader />}
            <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header className="bg-light" onHide={onHide} closeButton>
                    <Modal.Title className="m-0">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <Alert variant="info" className="my-2">
                        <span className="upload-link pointer">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="">{isCopied ? 'Copied to clipboard' : 'Copy to clipboard'}</Tooltip>}
                            >
                                <i className="mdi mdi-content-copy" onClick={() => onCopyLink()}></i>
                            </OverlayTrigger>
                            <Link to={`${link}`} target="_blank">
                                {getTextLink()}
                            </Link>
                        </span>
                    </Alert>
                    {/*<span>Copy to clipboard by clicking the link above</span>*/}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default UploadLinkModal;
